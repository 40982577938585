import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import useWindowDimensions from '../Hooks/useWindowDimensions';

const MasonryLayout = (props) => {
  const { width, height } = useWindowDimensions();
  const columnWrapper = {};
  const result = [];
  const [columns, setColumns] = useState(2);

  // sets the number of columns depending on window width
  useEffect(() => {
    if ((width >= 768 && width <= 904) || width <= 599) {
      setColumns(1);
    } else {
      setColumns(2);
    }
  }, [width]);

  // sorting will be rearranging the props array => create function according to sort by
  // create the number of columns and save into the object
  for (let i = 0; i < columns; i++) {
    columnWrapper[`column${i}`] = [];
  }

  // loop over data length and sort into column according modulo order => evens and odds to display correct order
  for (let i = 0; i < props?.children?.length; i++) {
    const columnIndex = i % columns;

    // push the corresponding children prop into the column array
    columnWrapper[`column${columnIndex}`].push(
      <div key={i}>{props.children[i]}</div>
    );
  }
  // push JSX elements into result array to be rendered
  for (let i = 0; i < props?.columns; i++) {
    result.push(
      <div className="column-holder" key={i}>
        {columnWrapper[`column${i}`]}
      </div>
    );
  }

  return <div className="masonry-container">{result}</div>;
};
// define and set required proptypes
MasonryLayout.propTypes = {
  columns: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
};
// set default props 
MasonryLayout.defaultProps = {
  columns: 2,
};

export default MasonryLayout;
