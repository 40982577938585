/*
? Hook that returns height and width dimensions of window 
? for use of styles or events depending on device window height OR width.
 */

import { useState, useEffect } from 'react';

// Get width and height dimensions from window
const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

// setState to function calling width and height
const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

// useEffect to update width and height dimensions responsively 
  useEffect(() => {
    // Update window dimensions
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    // Add event listener when resizing window to update styles
    window.addEventListener('resize', handleResize);

    // Cleanup event listener after resizing window 
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
