import * as d3_old from 'd3-old';
import cn from 'classnames';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toBeChecked } from '@testing-library/jest-dom/dist/matchers';

const Sigil = ({ rating }) => {
  const dispatch = useDispatch();
  const check = useSelector((state) => state?.clientFactors);
  const defaultChance = useSelector((state) => state?.factors);
  let total = [];

  // useEffect(() => {}, [width]);

  let width = 265;
  let height = 265;
  const gradeCalc = () => {
    if (Object.keys(check).length) {
      Object.entries(check).forEach((el) =>
        total.push(el[1].total * (el[1].weight / 100))
      );
    }

    // else {
    //   Object.entries(defaultChance).forEach((el) =>
    //     total.push(el[1].total * (100 / 100))
    //   );
    // }

    let initialValue = 0;
    let newTotal = total.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      initialValue
    );
    return Math.round(newTotal);
  };

  let grade = gradeCalc();
  const sections = [{ section: 1, color: 'rgba(44, 159, 44, .2)' }];
  let twoPi = 2 * Math.PI;
  let progress = 0;

  const svg = d3_old
    .select('.my-sigil')
    .attr('viewBox', '0 0 265 265')
    .attr('width', width)
    .attr('height', height)
    // .attr('preserveAspectRatio', 'xMinYMin meet')
    .append('g')
    .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

  const g = svg
    .selectAll('g')
    .data(sections)
    .enter()
    .append('g')
    .attr('text-anchor', 'middle')
    .attr('class', 'g-element');

  const circle = g
    .append('circle')
    .attr('r', width <= 120 ? 32.31 : 70)
    .style('fill', '#ffffff')
    .style('stroke', '#d9d9d9');

  g.append('text')
    .style('fill', '#0e0a0a')
    .style('font-family', 'DM Sans')
    .style('font-size', width <= 120 ? '40px' : '72px')
    .attr('class', 'inside')
    .attr('line-height', width <= 120 ? '40px' : '72px')
    .attr('text-anchor', 'middle')
    .attr('y', width <= 120 ? '.2rem' : '.4rem')

    // .attr('x', -50 / 2)
    .style('font-weight', 700)
    .text(
      grade > 75
        ? 'A'
        : grade >= 51 && grade <= 75
        ? 'B'
        : grade >= 26 && grade <= 50
        ? 'C'
        : grade <= 25
        ? 'D'
        : ''
    );
  const arc = d3_old.svg
    .arc()
    .startAngle(0)
    .padAngle(0.05)
    .outerRadius(width / 2)
    .innerRadius(width / 2 / 1.5);

  const pie = d3_old.layout.pie().value((d) => d.section);

  const arcs = svg
    .attr('class', 'arc')
    .selectAll('.arc')
    .data(pie(sections))
    .enter()
    .append('g')
    .attr('width', width <= 120 ? 120 : 260)
    .append('path')
    .attr('d', arc)
    .style('stroke', '#d9d9d9')
    .style('fill', '#ffffff');

  //CHANGE
  let meter = svg.append('g').attr('class', 'funds-allocated-meter');

  meter
    .append('path')
    .attr('class', 'background')
    .attr('d', arc.endAngle(twoPi));

  let foreground = meter.append('path').attr(
    'class',
    cn({
      isA: grade > 75,
      isB: grade >= 51 && grade <= 75,
      isC: grade >= 26 && grade <= 50,
      isD: grade <= 25,
    })
  );

  let calcPercentage = d3_old.interpolate(progress, grade / 100);

  d3_old.transition()
    .duration(0)
    .tween('progress', () => {
      return (t) => {
        progress = calcPercentage(t);
        foreground.attr('d', arc.endAngle(twoPi * progress));
      };
    });

  const defs = circle.append('svg:defs');

  const inset_shadow = defs.append('svg:filter').attr('id', 'inset-shadow');

  inset_shadow.append('svg:feOffset').attr({
    dx: 0,
    dy: 0,
  });

  inset_shadow.append('svg:feGaussianBlur').attr({
    stdDeviation: 4,
    result: 'offset-blur',
  });

  /* Invert drop shadow to make an inset shadow */
  inset_shadow.append('svg:feComposite').attr({
    operator: 'out',
    in: 'SourceGraphic',
    in2: 'offset-blur',
    result: 'inverse',
  });

  /* Cut color inside shadow */
  inset_shadow.append('svg:feFlood').attr({
    'flood-color': '#000000a9',
    // "flood-opacity": 0.3,
    result: 'color',
  });

  inset_shadow.append('svg:feComposite').attr({
    operator: 'in',
    in: 'color',
    in2: 'inverse',
    result: 'shadow',
  });

  // appends filter to the svg elements
  inset_shadow.append('svg:feComposite').attr({
    operator: 'over',
    in: 'shadow',
    in2: 'SourceGraphic',
  });

  return <svg className="my-sigil"></svg>;
};

export default Sigil;
