/*
? Hook that gets and sets the value of tokens 
? for use of maintaining app state given correct auth credentials 
 */

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { refreshToken, logout } from '../../store/features/session';
import { useNavigate } from 'react-router-dom';

// Get token from localStorage
const GetToken = () => {
  let persistToken = JSON.parse(localStorage.getItem('_uA'));
  return { persistToken };
};

// Initiate state to token value
const useToken = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [localToken, setLocalToken] = useState(GetToken());
  // useEffect that uses an event listener for value of token
  useEffect(() => {
    // Update token
    const handleToken = () => {
      setLocalToken(GetToken());
    };
    // Add event listener for local storage and call setLocalToken function
    window.addEventListener('storage', handleToken);
    // Cleanup event listener
    return () => window.removeEventListener('storage', handleToken);
  }, [GetToken]);
  return localToken;
};

export default useToken;

// dispatch(refreshToken(localToken?.persistToken?.refresh)).then((res) => {
//   if (res.data.status_code >= 400) {
//     localStorage.removeItem('_uA');
//     setLocalToken(null);
//     dispatch(logout({ token: null }));
//     navigate('/login');
//   }
// else {
