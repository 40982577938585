// Chance of Success Modal (Single Case)
const modalCoS = () => {
  return (
    <div className="CoS-modal">
      <div className="modal-title-element">
        AFX Chance of Success Prediction Evaluation
      </div>
      <div className="md-intro-par">
        To evaluate the predictive performance of AFX chance of success scores,
        we compute the percentage of out-of-sample cases that succeed on the
        merits before a tribunal or a court by the AFX Chance of Success Factor
        Rating. To perform this evaluation, we restrict to cases that were not
        used to fit the chance of success outcome prediction models i.e.
        “out-of-sample cases”. For example, for arbitral award enforcement,
        out-of-sample cases that receive a rating of “A” win 96.6% of the time,
        whereas out-of-sample cases that receive a rating of “D” only win 11.4%
        of the time.{' '}
      </div>
      <div className="modal-body-element">
        <div className="data-wrapper">
          {/* <table className="data-table">
            <thead className="data-table-head">
              <tr className="cos-table-title">
                <td className="td-logo-conatiner">
                  <div className="table-logo-container">
                    <img
                      src={require(`../../assets/logo-black.png`)}
                      className="logoMark"
                      alt="ArbiLex Logo"
                    />
                  </div>
                </td>
                <td colSpan="4" className="table-title">
                  Case Type
                </td>
              </tr>
              <tr className="table-header-container">
                <td className="td-upper-title td-1-title">
                  AFX Chance of Success Factor Rating
                </td>
                <td className="td-upper-title">Avg.</td>
                <td className="td-upper-title">
                  Investment Treaty Arbitration
                </td>
                <td className="td-upper-title ">Arbitral Award Enforcement</td>
                <td className="td-upper-title ">Patent</td>
              </tr>
            </thead>
            <tbody className="data-table-body">
              <tr>
                <td className="td-lat-title">A</td>
                <td className="td-lat-numbers">91.4%</td>
                <td className="td-lat-numbers">89.6%</td>
                <td className="td-lat-numbers">96.6%</td>
                <td className="td-lat-numbers">87.9%</td>
              </tr>
              <tr>
                <td className="td-lat-title">B</td>
                <td className="td-lat-numbers">62.3%</td>
                <td className="td-lat-numbers">61.5%</td>
                <td className="td-lat-numbers">62.1%</td>
                <td className="td-lat-numbers">63.3%</td>
              </tr>
              <tr>
                <td className="td-lat-title">C</td>
                <td className="td-lat-numbers">37.9%</td>
                <td className="td-lat-numbers">35.7%</td>
                <td className="td-lat-numbers">40.0%</td>
                <td className="td-lat-numbers">38.0%</td>
              </tr>
              <tr>
                <td className="td-lat-title">D</td>
                <td className="td-lat-numbers">13.4%</td>
                <td className="td-lat-numbers">22.1%</td>
                <td className="td-lat-numbers">11.4%</td>
                <td className="td-lat-numbers">6.9%</td>
              </tr>
            </tbody>
          </table> */}
          <div className="cos-table-container">
            <div className="cos-left-col">
              <div className="td-logo-conatiner">
                <div className="table-logo-container">
                  <img
                    src={require(`../../assets/logo-black.png`)}
                    className="logoMark"
                    alt="ArbiLex Logo"
                  />
                </div>
              </div>
              <div className="td-upper-title">
                AFX Chance of Success <br />
                Factor Rating
              </div>
              <div className="td-lat-title">A</div>
              <div className="td-lat-title">B</div>
              <div className="td-lat-title">C</div>
              <div className="td-lat-title">D</div>
            </div>
            <div className="cos-right-col">
              <div className="cos-right-header">
                <div className="table-title">Case Type</div>
              </div>
              <div className="cos-right-titles">
                <div className="td-upper-title">Avg.</div>
                <div className="td-upper-title">
                  Investment Treaty Arbitration
                </div>
                <div className="td-upper-title">Arbitral Award Enforcement</div>
                <div className="td-upper-title">Patent</div>
              </div>
              <div className="cos-row">
                <td className="td-lat-numbers">91.4%</td>
                <td className="td-lat-numbers">89.6%</td>
                <td className="td-lat-numbers">96.6%</td>
                <td className="td-lat-numbers">87.9%</td>
              </div>
              <div className="cos-row">
                <td className="td-lat-numbers">62.3%</td>
                <td className="td-lat-numbers">61.5%</td>
                <td className="td-lat-numbers">62.1%</td>
                <td className="td-lat-numbers">63.3%</td>
              </div>
              <div className="cos-row">
                <td className="td-lat-numbers">37.9%</td>
                <td className="td-lat-numbers">35.7%</td>
                <td className="td-lat-numbers">40.0%</td>
                <td className="td-lat-numbers">38.0%</td>
              </div>
              <div className="cos-row">
                <td className="td-lat-numbers">13.4%</td>
                <td className="td-lat-numbers">22.1%</td>
                <td className="td-lat-numbers">11.4%</td>
                <td className="td-lat-numbers">6.9%</td>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-data-footer">
          Results are based on the investment treaty arbitration, arbitral award
          enforcement, and patent chance of success outcome models trained on
          August 23rd, 2022, August 18th, 2022, and October 27th, 2022,
          respectively.
        </div>
      </div>

      {/* <div className="cos-table-container">
        <div className="cos-left-col">
          <div className="td-logo-conatiner">
            <div className="table-logo-container">
              <img
                src={require(`../../assets/logo-black.png`)}
                className="logoMark"
                alt="ArbiLex Logo"
              />
            </div>
          </div>
          <div className="td-upper-title">
            AFX Chance of Success <br />
            Factor Rating
          </div>
          <div className="td-lat-title">A</div>
          <div className="td-lat-title">B</div>
          <div className="td-lat-title">C</div>
          <div className="td-lat-title">D</div>
        </div>
        <div className="cos-right-col">
          <div className="cos-right-header">
            <div className="table-title">Case Type</div>
          </div>
          <div className="cos-right-titles">
            <div className="td-upper-title">Avg.</div>
            <div className="td-upper-title">Investment Treaty Arbitration</div>
            <div className="td-upper-title">Arbitral Award Enforcement</div>
            <div className="td-upper-title">Patent</div>
          </div>
          <div className="cos-row">
            <td className="td-lat-numbers">91.4%</td>
            <td className="td-lat-numbers">89.6%</td>
            <td className="td-lat-numbers">96.6%</td>
            <td className="td-lat-numbers">87.9%</td>
          </div>
          <div className="cos-row">
            <td className="td-lat-numbers">62.3%</td>
            <td className="td-lat-numbers">61.5%</td>
            <td className="td-lat-numbers">62.1%</td>
            <td className="td-lat-numbers">63.3%</td>
          </div>
          <div className="cos-row">
            <td className="td-lat-numbers">37.9%</td>
            <td className="td-lat-numbers">35.7%</td>
            <td className="td-lat-numbers">40.0%</td>
            <td className="td-lat-numbers">38.0%</td>
          </div>
          <div className="cos-row">
            <td className="td-lat-numbers">13.4%</td>
            <td className="td-lat-numbers">22.1%</td>
            <td className="td-lat-numbers">11.4%</td>
            <td className="td-lat-numbers">6.9%</td>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default modalCoS;
