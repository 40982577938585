import Sigil from './Sigil';
// import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RatingCards from './RatingCards';
import FactorsConsidered from './FactorsConsidered';
import useWindowDimensions from '../Hooks/useWindowDimensions';

const AFXRating = ({ user, card, membership }) => {
  const renderFactors = useSelector((state) => state.factors);
  const { width, height } = useWindowDimensions();
  const detailedInformation = card?.detailed_information

  return (
    <>
      <div className="rating-left">
        <div className="rating-flex-container">
          <div className="sigil-holder">
            <div className="composite-score-container">
              <section>
                <div className="legend-number">100</div>
                <div className="comp-grade-container">
                  <div className="comp-a"></div>
                  <p>A</p>
                </div>
                {/* <div className="comp-range">76 - 100</div> */}
              </section>
              <section>
                <div className="legend-number">75</div>
                <div className="comp-grade-container">
                  <div className="comp-b"></div>
                  <p>B</p>
                </div>
                {/* <div className="comp-range">51 - 75</div> */}
              </section>
              <section>
                <div className="legend-number">50</div>
                <div className="comp-grade-container">
                  <div className="comp-c"></div>
                  <p>C</p>
                </div>
                {/* <div className="comp-range"> 26 - 50</div> */}
              </section>
              <section>
                <div className="legend-number">25</div>
                <div className="comp-grade-container">
                  <div className="comp-d"></div> <p>D</p>
                </div>
                <div className="legend-number-0">0</div>
                {/* <div className="comp-range">0 - 25</div> */}
              </section>
            </div>
            <Sigil rating={{ detailedInformation }} />
          </div>
          <FactorsConsidered
            rating={{ detailedInformation }}
            card = {{ card }}
            membership={membership}
          />
        </div>
      </div>
      {width > 599 ? (
        <div className="rating-right">
          <RatingCards rating={{ card }} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AFXRating;