import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleCardThunk } from '../../store/features/card';
import NotFound from '../404';
import { persistUser } from '../../store/features/session';
import AFXRating from './AFXRating';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _Modal from '../Modal';
import modalAUP from '../Modal/modalAUP';
import modalToU from '../Modal/modalToU';
import modalPrivacy from '../Modal/modalPrivacy';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import Footer from '../Cards/Footer';
import { Tooltip } from 'react-tooltip'

import React, {
  useEffect,
  useReducer,
  useState,
  Fragment,
  useLayoutEffect,
} from 'react';
import {
  addClientInterest,
  interest,
  putClientInterest,
} from '../../store/features/session';
// import CaseProgress from "./CaseProgress";

const SingleCase = () => {
  const [openDocs, setOpenDocs] = useState(false);
  const navigate = useNavigate();
  let persistToken = JSON.parse(localStorage.getItem('_uA'));
  const dispatch = useDispatch();
  let { id } = useParams();
  let user = useSelector((state) => state.session?.user);
  let membership = user?.subscription;
  let ints = useSelector((state) => state.session?.interest);
  const [isIncluded, setIsIncluded] = useState(ints?.includes(Number(id)));
  const { width, height } = useWindowDimensions();
  const [message, setMessage] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');

  const notify = () =>
    toast(
      'Thank you for reaching out. Someone will contact you at the email you have provided shortly.',
      {
        hideProgressBar: true,
        theme: 'dark',
        className: 'single-case-toast',
      }
    );

  const removeNotify = () => {
    toast('User interest removed', {
      hideProgressBar: true,
      theme: 'dark',
      className: 'single-case-toast',
    });
  };

  useEffect(() => {}, [width]);

  useEffect(() => {
    dispatch(persistUser(persistToken?.access));
    dispatch(getSingleCardThunk({ token: persistToken?.access, id }))
      .then((res) => {
        if (res.status === 401) {
          navigate('/login');
          return;
        } else if (res.status >= 400) {
          navigate('/404-not-found');
        }
        return;
      })
      .catch((err) => console.error(err));
  }, []);

  let card = useSelector((state) => state?.card);
  card = card[id];
  let text = card?.text;

  const checkStatus = (color) => {
    if (color === 'Purple') return 'Purple';
    if (color === 'Gray') return 'Gray';
    if (color === 'Green') return 'Green';
    if (color === 'exclusive') return 'exclusive';
  };

  const moreDocs = (e) => {
    e.stopPropagation();
    e.preventDefault();
    openDocs ? setOpenDocs(false) : setOpenDocs(true);
  };
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, []);

  async function handleInteretClick(e) {
    e.preventDefault();

    const res = await fetch(
      `https://d1i2jq400lm6rf.cloudfront.net/user/toggle-interest?card=${id}`,
      // `http://127.0.0.1:8000/user/toggle-interest?card=${id}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${persistToken?.access}`,
        },
        body: JSON.stringify({
          // contact_name : contactName,
          contact_email: contactEmail,
          text: message,
        }),
      }
    );

    if (res.ok) {
      setContactEmail('');
      setMessage('');
      notify();
      const data = await res.json();
      return data;
    }
    return;
  }

  useEffect(() => {
    hasInterest(id);
  }, [ints]);

  async function download(e, id) {
    e.stopPropagation();
    e.preventDefault();
    const res = await fetch(
      `https://d1i2jq400lm6rf.cloudfront.net/card/document?doc_id=${id}`,
      {
        method: 'GET',
        origin: 'https://legaltech-web-staging.herokuapp.com/',
        headers: {
          Authorization: `Bearer ${persistToken?.access}`,
        },
      }
    );
    if (res.ok) {
      const data = await res.json();
      // decode base64 string, remove space for IE compatibility
      const binary = window.atob(data.data.buffer.replace(/\s/g, ''));
      const len = binary.length;
      const buffer = new ArrayBuffer(len);
      let view = new Uint8Array(buffer);
      for (let i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
      }

      // create the blob object with content-type "application/pdf"               
      let blob = new Blob([view], { type: data.data["content-type"] });
      let docURL = URL.createObjectURL(blob);
      window.open(docURL, '_blank_');
    }
    return;
  }

  async function addInterest(e, id) {
    e.stopPropagation();
    e.preventDefault();
    hasInterest(id) ? removeNotify() : notify();

    if (ints?.includes(id)) {
      dispatch(putClientInterest(persistToken?.access, id));
    } else {
      dispatch(addClientInterest(persistToken?.access, id));
    }
    return;
  }

  const hasInterest = (id) => {
    if (ints?.includes(Number(id))) {
      setIsIncluded(true);
      return true;
    }
    if (!ints?.includes(Number(id))) {
      setIsIncluded(false);
      return false;
    }
    return;
  };

   // Keys=number of month; Value=abbrevieated month
   const monthsAbrev = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

  // Converter for date format string
  const dateConverter = (date) => {
    let newDate = date.split('-');
    newDate = `${monthsAbrev[newDate[1]]} ${Number(newDate[2])}, ${Number(
      newDate[0]
    )}`;
    return newDate;
  };
  
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const dateFormat = (date) => {
    let split = date.split('-');
    return `${split[1]}/${split[2]}/${split[0]}`;
  };
  if (!card) {
    return <div className="empty-holder-div"></div>;
  }
  if (card) {
    // text = JSON.parse(text);
    let DATE = new Date(card?.updated_on.split('T')[0]);
    let newDate = `${months[DATE.getMonth()].slice(
      0,
      3
    )} ${DATE.getDate()}, ${DATE.getFullYear()}`;

    let cardSplitDate = card?.date?.split('-');
    // let docDateSplit = card?.documen

    return (
      <>
        <div className="single-case-body-container">
          <ToastContainer
            className="case-toast"
            progressClassName="case-toast-progress"
          />
          <div className="basic-case-information">
            <div className="single-link-container">
              <Link className="back-listing-link" to="/cases">
                <svg className="back-arrow" />
                <div>Back to listing view</div>
              </Link>
              {card?.text?.rating_updated === true ? (
                <p>Last updated on {dateConverter(card?.text?.rating_timeline[0]?.date)}</p>
              ) : (
                <p>Last updated on {card?.text?.last_updated || newDate}</p>
              )} 
            </div>
            <div className="case-tags">
              <div
                className={cn('tag-funding-status', {
                  status_funded: checkStatus(card?.status) === 'Purple',
                  status_unknown: checkStatus(card?.status) === 'Gray',
                  status_accepting:
                    checkStatus(card?.status) === 'Green' &&
                    card?.listing_type != 'exclusive',
                  status_exclusive:
                    checkStatus(card?.listing_type) === 'exclusive',
                })}
              >
                {card?.status === 'Gray' && <>Funding Status: Unknown</>}
                {card?.status === 'Purple' && <>Funded</>}
                {card?.listing_type === 'exclusive' &&
                  card?.status === 'Green' && <>AFX Exclusive</>}
                {card?.listing_type != 'exclusive' &&
                  card?.status === 'Green' && <>Accepting Proposals</>}
              </div>
              {card?.status === 'Green' &&
                card?.listing_type === 'exclusive' && (
                  <div
                    className={cn('tag-funding-status', {
                      is_accepting_tag: card?.status === 'Green',
                    })}
                  >
                    Accepting Proposals
                  </div>
                )}
              {card?.tags &&
                card?.tags?.split(',').map((tag, i) => {
                  if ((tag.toLowerCase().includes('uncertainty'))) {
                    return (
                      <div
                        className={cn('tag-funding-status secondary_tag uncertainty_tag', {
                          higher_uncertainty_tag: tag === 'Higher Uncertainty',
                          medium_uncertainty_tag:tag === 'Medium Uncertainty',
                          lower_uncertainty_tag: tag === 'Lower Uncertainty',
                        })}
                        key = {i}
                      >
                        {tag}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={i}
                        className={cn('tag-funding-status', {
                          is_offering_tag: true,
                        })}
                      >
                        {tag}
                      </div>
                    );
                  }})}

              {card?.text?.concluded_status && (
                <div className='tag-funding-status concluded_status'>
                  {card?.text?.concluded_status}
                </div>
              )}
            </div>
            {card?.text?.rating_updated === true && (
                <div className="rating-updated-tag-container">
                  <a 
                    data-tooltip-id="rating-updated-tooltip"
                    data-tooltip-delay-hide={300}
                    data-tooltip-place='bottom-start'
                  >
                    <div className="rating-updated-tag">
                      <img
                        src={require(`../../assets/information-icon.png`)}
                        className="rating-updated-info-icon"
                        alt="Icon for Rating Updated"
                      />
                      Rating Updated
                    </div>
                  </a>
                  <Tooltip id="rating-updated-tooltip" className="rating-updated-tooltip-popup-container" classNameArrow="rating-updated-tooltip-popup-arrow">
                    <div>
                      <div>The AFX Rating for {card?.title} was updated on {dateConverter(card?.text?.rating_timeline[0]?.date)}.</div>
                      {/* <div>Here are the rating update causes.</div>
                      <ul>
                        <li>Some</li>
                        <li>Interesting</li>
                        <li>Stuff</li>
                      </ul> */}
                    </div>
                  </Tooltip>
                </div>
              )}
            <h1 className="case-title">{card?.title}</h1>
            {/* {card.status === "AFX Exclusive" && (
          <>
            <CaseProgress completed={card.completed} />
          </>
        )} */}
            <section className="section-breaker">Case Info</section>
            {/* ******************** CASE INFO *********************/}
            {card?.text?.concluded_status && (
              <section className="case-base-info">
                <div className="tag-container">
                  <div className="tag-header">
                    <div className="tag-header-title">Case Status</div>
                    <span>
                      {card?.text?.concluded_status}
                    </span>
                  </div>
                </div>
                {/* PRINT ICON*/}
                {/* <div className="case-title-svg-container">
                <svg
                  className="case-title-svg share-svg"
                  data-toggle="tooltip"
                  data-placement="right"
                />
              </div> */}
              </section>
            )}
            <section className="case-base-info">
              <div className="tag-container">
                <div className="tag-header">
                  <div className="tag-header-title">Filing Date</div>
                  <span>
                    {`${cardSplitDate?.[1]}/${cardSplitDate?.[2]}/${cardSplitDate?.[0]}`}
                  </span>
                </div>
                <span className="tag-header-divider" />
                <div className="tag-header">
                  <div className="tag-header-title">Claim Size</div>
                  {card?.tags?.includes('Claim Size Unknown') ? (
                    <span>Unknown</span>
                  ) : (
                    <span>
                      ${card?.number} {card?.unit}
                    </span>
                  )}
                </div>
                <span className="tag-header-divider" />
                <div className="tag-header">
                  <div className="tag-header-title">Case Type</div>
                  <span>{card?.case_type}</span>
                </div>
                <span className="tag-header-divider" />
                <div className="tag-header">
                  <div className="tag-header-title">Venue</div>
                  <span>{card?.venue}</span>
                </div>
              </div>
              {/* PRINT ICON*/}
              {/* <div className="case-title-svg-container">
              <svg
                className="case-title-svg share-svg"
                data-toggle="tooltip"
                data-placement="right"
              />
            </div> */}
            </section>
            <section className="case-basic-info">
              <div className="basic-info-container">
                <div className="basic-info-title">
                  {/* ******************** JUDGE & JUDGES *********************/}

                  {text?.judge && text?.judge?.length === 1 && (
                    <>
                      <h4>Judge</h4>
                      <p>{text?.judge[0]}</p>
                    </>
                  )}
                  {text?.judge && text?.judge?.length > 1 && (
                    <>
                      <h4>Judges</h4>

                      <p>{text?.judge[0]}</p>
                    </>
                  )}
                  {/* ******************** TRIBUNAL *********************/}

                  {text?.tribunal?.length > 0 && (
                    <>
                      <h4>Tribunal</h4>
                      {text?.tribunal?.map((el, i) => {
                        if (i !== text?.tribunal?.length - 1) {
                          return <p key={i}>{el},</p>;
                        } else {
                          return <p key={i}> {el} </p>;
                        }
                      })}
                    </>
                  )}
                </div>
                {/*  */}
                <div className="counsel-holder">
                  {/* ******************** LEFT SIDE *********************/}
                  <div className="basic-info-title">
                    {/* CLAIMANT */}
                    {/* The conditional in the following sections check if it's the last el or not to add ',' */}
                    {text?.counsel_for_the_claimant && (
                      <>
                        <h4>Counsel for the Claimant</h4>
                        {text?.counsel_for_the_claimant?.map((el, i) => {
                          if (
                            i !==
                            text?.counsel_for_the_claimant?.length - 1
                          ) {
                            return (
                              <p key={i}>
                                {el?.attorney_name}
                                {el?.law_firm_name
                                  ? ` - ${el?.law_firm_name}`
                                  : null}
                                {!el?.attorney_name && !el?.law_firm_name
                                  ? `${el}`
                                  : null 
                                }
                                ,
                              </p>
                            );
                          } else {
                            return (
                              <p key={i}>
                                {' '}
                                {el?.attorney_name}
                                {el?.law_firm_name
                                  ? ` - ${el?.law_firm_name}`
                                  : null}
                                {!el?.attorney_name && !el?.law_firm_name
                                  ? `${el}`
                                  : null 
                                }
                              </p>
                            );
                          }
                        })}
                        <p>
                          {!text?.counsel_for_the_claimant?.length >= 1 && (
                            <>Unknown</>
                          )}
                        </p>
                      </>
                    )}
                    {/* APPELLANT */}
                    {text?.counsel_for_the_appellant && (
                      <>
                        <h4>Counsel for the Appellant</h4>
                        {text?.counsel_for_the_appellant?.map((el, i) => {
                          if (
                            i !==
                            text?.counsel_for_the_appellant?.length - 1
                          ) {
                            return (
                              <p key={i}>
                                {el?.attorney_name}
                                {el?.law_firm_name
                                  ? ` - ${el?.law_firm_name}`
                                  : null}
                                ,
                              </p>
                            );
                          } else {
                            return (
                              <p key={i}>
                                {el?.attorney_name}
                                {el?.law_firm_name
                                  ? ` - ${el?.law_firm_name}`
                                  : null}
                              </p>
                            );
                          }
                        })}
                        <p>
                          {!text?.counsel_for_the_appellant?.length >= 1 && (
                            <>Unknown</>
                          )}
                        </p>
                      </>
                    )}
                    {/* PLAINTIFF */}
                    {text?.counsel_for_the_plaintiff && (
                      <>
                        <h4>Counsel for the Plaintiff</h4>
                        {text?.counsel_for_the_plaintiff?.map((el, i) => {
                          if (
                            i !==
                            text?.counsel_for_the_plaintiff?.length - 1
                          ) {
                            return (
                              <p key={i}>
                                {el?.attorney_name}
                                {el?.law_firm_name
                                  ? ` - ${el?.law_firm_name}`
                                  : null}
                                ,
                              </p>
                            );
                          } else {
                            return (
                              <p key={i}>
                                {el?.attorney_name}
                                {el?.law_firm_name
                                  ? ` - ${el?.law_firm_name}`
                                  : null}
                              </p>
                            );
                          }
                        })}
                        <p>
                          {!text?.counsel_for_the_plaintiff?.length >= 1 && (
                            <>Unknown</>
                          )}
                        </p>
                      </>
                    )}
                  </div>
                  {/* ******************** RIGHT SIDE *********************/}
                  <div className="basic-info-title">
                    {/* RESPONDENT */}
                    {text?.counsel_for_the_respondent && (
                      <>
                        <h4>Counsel for the Respondent</h4>
                        {text?.counsel_for_the_respondent?.map((el, i) => {
                          if (
                            i !==
                            text?.counsel_for_the_respondent?.length - 1
                          ) {
                            return (
                              <p key={i}>
                                {el?.attorney_name}
                                {el?.law_firm_name
                                  ? ` - ${el?.law_firm_name}`
                                  : null}
                                {!el?.attorney_name && !el?.law_firm_name
                                  ? `${el}`
                                  : null 
                                }
                                ,
                              </p>
                            );
                          } else {
                            return (
                              <p key={i}>
                                {el?.attorney_name}
                                {el?.law_firm_name
                                  ? ` - ${el?.law_firm_name}`
                                  : null}
                                {!el?.attorney_name && !el?.law_firm_name
                                  ? `${el}`
                                  : null 
                                }
                              </p>
                            );
                          }
                        })}
                        <p>
                          {!text?.counsel_for_the_respondent?.length >= 1 && (
                            <>Unknown</>
                          )}
                        </p>
                      </>
                    )}
                    {/* APPELLEE */}
                    {text?.counsel_for_the_appellee && (
                      <>
                        <h4>Counsel for the Appellee</h4>
                        {text?.counsel_for_the_claimant?.map((el, i) => {
                          if (
                            i !==
                            text?.counsel_for_the_claimant?.length - 1
                          ) {
                            return (
                              <p key={i}>
                                {el?.attorney_name}
                                {el?.law_firm_name
                                  ? ` - ${el?.law_firm_name}`
                                  : null}
                                ,
                              </p>
                            );
                          } else {
                            return (
                              <p key={i}>
                                {' '}
                                {el?.attorney_name}
                                {el?.law_firm_name
                                  ? ` - ${el?.law_firm_name}`
                                  : null}
                              </p>
                            );
                          }
                        })}
                        <p>
                          {!text?.counsel_for_the_claimant?.length >= 1 && (
                            <>Unknown</>
                          )}
                        </p>
                      </>
                    )}
                    {/* DEFENDANT */}
                    {text?.counsel_for_the_defendant && (
                      <>
                        <h4>Counsel for the Defendant</h4>
                        {text?.counsel_for_the_defendant?.map((el, i) => {
                          if (
                            i !==
                            text?.counsel_for_the_defendant?.length - 1
                          ) {
                            return (
                              <p key={i}>
                                {el?.attorney_name}
                                {el?.law_firm_name
                                  ? ` - ${el?.law_firm_name}`
                                  : null}
                                ,
                              </p>
                            );
                          } else {
                            return (
                              <p key={i}>
                                {' '}
                                {el?.attorney_name}{' '}
                                {el?.law_firm_name
                                  ? ` - ${el?.law_firm_name}`
                                  : null}
                              </p>
                            );
                          }
                        })}
                        <p>
                          {!text?.counsel_for_the_defendant?.length >= 1 && (
                            <>Unknown</>
                          )}
                        </p>
                      </>
                    )}
                  </div>
                </div>
                <div className="basic-info-title source">
                  <h4>Source</h4>
                  <a
                    href={card?.text?.source_url}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="case-source-link"
                  >
                    {card?.text?.source_url}
                    <svg className="case-source-svg" />
                  </a>
                </div>
                {/* {HEREERER} */}
              </div>
            </section>
          </div>
          {/* ******************** RATING SECTION *********************/}
          {/* <_Modal children={modalCoS}
            inline={{ el: <p>Example Text</p> }} 
          /> */}
          {!(JSON.stringify(card?.detailed_information) === "{}") && (
            <section className="rating-section-container">
              <div className="section-breaker">AFX Rating</div>
              <div className="rating-section-content">
                <AFXRating
                  user={user}
                  card={card}
                  membership={membership}
                />
              </div>
            </section>
          )}
          {/* ******************** DOCUMENTS SECTION *********************/}
          <div className="document-section-holder">
            <section className="section-breaker doc-breaker">Documents</section>
            <div className={cn('documents-container', {})}>
              {card?.document?.map((doc, i) => (
                <button
                  key={i}
                  className={cn('document-button', {
                    full_doc: card?.document?.length === 1,
                  })}
                  onClick={(e) => download(e, doc?.id)}
                >
                  <svg className="document-icon" />
                  <div className="doc-info-container">
                    <div className="document-date">{dateFormat(doc?.date)}</div>
                    <div className="document-name">{doc?.file_name}</div>
                  </div>
                </button>
              ))}
              {!card.document ? (
                <button className="document-button no-documents">
                  <svg className="document-icon" />
                  <div className="document-date"></div>
                  <div className="document-name">
                    Documents available on request
                  </div>
                </button>
              ) : (
                ''
              )}
            </div>
          </div>

          {/* New Express Interest */}
          <div className="document-section-holder">
            <section className="section-breaker doc-breaker">
              Express Interest
            </section>
            <div className="interest-subsection">
              <div className="interest-left">
                <div className="interest-subsection-title">CONTACT US</div>
                <div className="interest-text">
                  The ArbiLex team is happy to provide additional information
                  for this case. Please provide a brief message of what
                  information you are looking for and we will be in touch soon. {' '}
                </div>
              </div>
              <div className="interest-right">
                <input
                  className="email-box"
                  placeholder="Email to be contacted at"
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                />
                <label htmlFor="message">Message*</label>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  cols="33"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <button
                  className="interest-button"
                  onClick={(e) => handleInteretClick(e)}
                >
                  Send Request
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* ******************** CALL TO ACTION SECTION *********************/}
        {/* <section className="cta-container">
          <div className="cta-emphasis-text">
            {isIncluded && 'Thank you for expressing interest in this case.'}
            {!isIncluded && 'Express Interest in this case'}
          </div>
          <div className="cta-sub-text">
            {isIncluded &&
              'We will reach out to the claimholder on your behalf.'}
            {!isIncluded &&
              'for additional analysis, documents, and deal opportunities.'}
          </div>
          {isIncluded && (
            <button
              onClick={(e) => addInterest(e, card?.id)}
              className={cn('cta-card-btn', {
                remove_int: isIncluded,
              })}
            >
              Retract Interest
            </button>
          )}
          {!isIncluded && (
            <button
              onClick={(e) => addInterest(e, card?.id)}
              className="cta-card-btn"
            >
              Express Interest
            </button>
          )}
          <button onClick={notify}>Notify!</button>
          <ToastContainer
            className="case-toast"
            progressClassName="case-toast-progress"
          />
        </section> */}
        {/* <BenchmarkAnalytics data={card} /> */}
        {/* ******************** FOOTER SECTION *********************/}
        <Footer />
      </>
    );
  }
};

export default SingleCase;
