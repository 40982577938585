const DOWNLOAD = 'cards/DOWNLOAD';
//******ACTIONS******//

export const download = (doc) => ({
  type: DOWNLOAD,
  doc,
});

//******THUNKS******//

export const downloadDOC = (token, id) => async (dispatch) => {
  const res = await fetch(
    `https://d1i2jq400lm6rf.cloudfront.net/card/document?doc_id=${id}`,
    // `http://3.237.199.50:8000/card/document?doc_id=${id}`,
    {
      method: 'GET',
      origin: 'http://localhost:3000/',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (res.ok) {
    const data = await res.json();
    dispatch(download(data));
  }
};

//******REDUCER******//

const initialState = {};

const downloadDocReducer = (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case DOWNLOAD:
      newState[0] = action?.doc?.url;
      return newState;
    default:
      return state;
  }
};
export default downloadDocReducer;
