const GET_CARDS = 'cards/GET_CARDS';
const CARD_LENGTH = 'cards/CARD_LENGTH';
const NEXT_PAGE = 'cards/NEXT_PAGE';
const PREV_PAGE = 'cards/PREV_PAGE';

//******ACTIONS******//

export const getCards = (card, url) => ({
  type: GET_CARDS,
  card,
  url,
});

export const nextPage = (cards, url) => ({
  type: NEXT_PAGE,
  cards,
  url,
});

export const prevPage = (cards, url) => ({
  type: PREV_PAGE,
  cards,
  url,
});

export const noCards = (cardLength) => ({
  type: CARD_LENGTH,
  cardLength,
});

//******THUNKS******//

export const getCardsThunk =
  ({ token, filters, ...rest }) =>
  async (dispatch) => {
    let queryString = '';

    const query = (filter) => {
      if (queryString.length) {
        queryString += `,${filter}`;
      } else {
        queryString += `${filter}`;
      }
    };

    //Party
    if (filters?.party?.length >= 0) {
      if (filters?.party.includes('&')) {
        let encoded = filters.party.replace('&', '%and');

        query(`"party":"${encoded}"`);
      } else {
        query(`"party":"${filters?.party}"`);
      }
    }

    // Status
    if (filters?.status?.length > 0) {
      let arr = [];
      filters.status.forEach((el) => {
        arr.push(`"${el}"`);
      });
      query(`"status":[${arr}]`);
    }
    // Listing Type
    if (filters?.listing_type?.length > 0) {
      let arr = [];
      filters.listing_type.forEach((el) => {
        arr.push(`"${el}"`);
      });
      query(`"listing_type":[${arr}]`);
    }

    // rating
    if (filters?.rating?.length > 0) {
      let arr = [];
      filters.rating.forEach((el) => {
        arr.push(`"${el}"`);
      });
      query(`"rating":[${arr}]`);
    }

    // Venue
    if (filters?.venue?.length > 0) {
      query(`"venue":"${filters?.venue}"`);
    }
    // Case Type
    if (filters?.case_type?.length > 0) {
      let arr = [];
      filters.case_type.forEach((el) => {
        arr.push(`"${el}"`);
      });
      query(`"case_type":[${arr}]`);
    }
    // Start Date
    if (filters?.start_date?.length > 0) {
      query(`"start_date":"${filters?.start_date}"`);
    }
    // End Date
    if (filters?.end_date?.length > 0) {
      query(`"end_date":"${filters?.end_date}"`);
    }
    // Min Claim
    if (filters?.min_claim) {
      query(`"min_number":${Number(filters?.min_claim)}`);
    }
    // Max Claim
    if (filters?.max_claim) {
      query(`"max_number":${Number(filters?.max_claim)}`);
    }

    if (filters?.order) {
      const res = await fetch(
        `https://d1i2jq400lm6rf.cloudfront.net/card?order=${filters.order}&sort_by=${filters.sort}&filter={${queryString}}`,
        // LOCAL TEST URLS for debugging:
        // `http://127.0.0.1:8000/card?order=${filters.order}&sort_by=${filters.sort}&filter={${queryString}}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access}`,
          },
        }
      );

      if (res.ok) {
        let data = await res.json();
        dispatch(getCards(data, res.url));
        if (data?.count === 0) {
          dispatch(noCards(false));
        }
        return data;
      } else if (res.status === 401) {
        fetch(
          `https://d1i2jq400lm6rf.cloudfront.net/user/refresh?refresh_token=${token?.refresh}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        ).then(window.location.reload(true));
      }
    } else {
      const res = await fetch(
        `https://d1i2jq400lm6rf.cloudfront.net/card?filter={${queryString}}`,
        // LOCAL TEST URLS for debugging:
        // `http://127.0.0.1:8000/card?filter={${queryString}}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access}`,
          },
        }
      );

      if (res.ok) {
        let data = await res.json();
        dispatch(getCards(data, res.url));
        if (data?.count === 0) {
          dispatch(noCards(false));
        }
        return data;
      } else if (res.status === 401) {
        fetch(
          `https://d1i2jq400lm6rf.cloudfront.net/user/refresh?refresh_token=${token?.refresh}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        ).then(window.location.reload(true));
      }
    }
    return;
  };

export const nextThunk =
  ({ token, url }) =>
  async (dispatch) => {
    const res = await fetch(
      // LOCAL/LIVE TEST URLS for debugging:
      // `https://d1i2jq400lm6rf.cloudfront.net/card?order=${filters.order}&sort_by=${filters.sort}&filter={${queryString}}`,
      // `${'http://127.0.0.1:8000/card?page=2'}`,
      // https://127.0.0.1:8000/card/?filter=%7B%7D&page=4
      // `${'http://127.0.0.1:8000/card/?filter=%7B%7D&page=3'}`,
      `${url}`,
      // `${url.replace('https', 'http')}`,

      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access}`,
        },
      }
    );
    if (res.ok) {
      let data = await res.json();

      dispatch(getCards(data, res.url));
      return data;
    }
  };

export const pageThunk =
  ({ token, url }) =>
  async (dispatch) => {
    const res = await fetch(
      // LOCAL/LIVE TEST URLS for debugging:
      // `https://d1i2jq400lm6rf.cloudfront.net/card?order=${filters.order}&sort_by=${filters.sort}&filter={${queryString}}`,
      // `${'http://127.0.0.1:8000/card?page=2'}`,
      // https://127.0.0.1:8000/card/?filter=%7B%7D&page=4
      // `${'http://127.0.0.1:8000/card/?filter=%7B%7D&page=3'}`,
      `${url}`,
      // `${url.replace('https', 'http')}`,

      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access}`,
        },
      }
    );
    if (res.ok) {
      let data = await res.json();
      dispatch(getCards(data));
      return data;
    }
  };

//******REDUCER******//

const initialState = {
  list: {},
  cardLength: true,
};

const cardsReducer = (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case GET_CARDS:
      newState['list'] = {};
      action?.card?.data?.forEach((card, i) => {
        newState['list'][i] = card;
      });
      newState['next'] = action?.card?.next;
      newState['previous'] = action?.card?.previous;
      newState['count'] = action?.card?.count;
      newState['url'] = action?.url;
      newState['cardLength'] = true;

      return {
        ...newState,
      };
    case NEXT_PAGE:
      newState['list'] = {};
      action?.card?.data?.forEach((card, i) => {
        newState['list'][i] = card;
      });
      newState['next'] = action?.card?.next;
      newState['previous'] = action?.card?.previous;
      newState['count'] = action?.card?.count;
    case CARD_LENGTH:
      newState['list'] = {};
      action?.card?.data?.forEach((card, i) => {
        newState['list'][i] = card;
      });
      newState['cardLength'] = false;
      return {
        ...newState,
      };
    default:
      return state;
  }
};
export default cardsReducer;
