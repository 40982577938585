import * as d3 from 'd3';
import cn from 'classnames';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toBeChecked } from '@testing-library/jest-dom/dist/matchers';

const RatingHistoryChart = ({ chartData }) => {
  const dispatch = useDispatch();
  const check = useSelector((state) => state?.clientFactors);
  const defaultChance = useSelector((state) => state?.factors);
  let total = [];

  let ratingTimelineData = chartData.rating?.card?.text?.rating_timeline.map((item) => {
    return {date : d3.timeParse("%Y-%m-%d")(item.date), value : item.score};
  });
  // console.log(ratingTimelineData);

  let length = (path) => d3.create("svg:path").attr("d", path).node().getTotalLength();

  // set the dimensions and margins of the graph
  const margin = {top: 20, right: 30, bottom: 30, left: 40},
      width = 600,
      height = 400;

  // clear the svg for react duplication reasons
  let svg = d3.select("#rating-timeline-chart")
  svg.selectAll("*").remove()

  // append the svg object to the body of the page
  svg = d3.select("#rating-timeline-chart")
  .append("svg")
    .attr("width", width)
    .attr("height", height)
    .attr("viewBox", [0, 0, width, height])
    .attr("style", "max-width: 100%; height: auto;");
  // .append("g")
  //   .attr("transform",`translate(${margin.left},${margin.top})`);

  // Positional encodings

  // Add X axis --> it is a date format
  const x = d3.scaleTime()
    .domain(d3.extent(ratingTimelineData, d => d.date))
    .range([margin.left, width - margin.right])
    .nice();

  // Add Y axis
  const y = d3.scaleLinear()
    .domain([0, 100])
    .range([height - margin.bottom, margin.top])
    .nice();

  const line = d3.line()
    .x(d => x(d.date))
    .y(d => y(d.value));

  const l = length(line(ratingTimelineData));

  // rendering the svg time
  svg.append("g")
  .attr("transform", `translate(0,${height - margin.bottom})`)
  .call(d3.axisBottom(x).ticks(width / 80).tickFormat(d3.utcFormat("%b %Y")))
  .call(g => g.select(".domain").remove())
  .call(g => g.selectAll(".tick line").clone()
      .attr("y2", -height)
      .attr("stroke-opacity", 0.1))
  .call(g => g.append("text")
      .attr("x", width - 4)
      .attr("y", -4)
      .attr("font-weight", "bold")
      .attr("text-anchor", "end")
      .attr("fill", "currentColor")
      .text("Rating Date"));

  svg.append("g")
  .attr("transform", `translate(${margin.left},0)`)
  .call(d3.axisLeft(y))
  .call(g => g.select(".domain").remove())
  .call(g => g.selectAll(".tick line").clone()
      .attr("x2", width)
      .attr("stroke-opacity", 0.1))
  .call(g => g.select(".tick:last-of-type text").clone()
      .attr("x", 4)
      .attr("text-anchor", "start")
      .attr("font-weight", "bold")
      .text("Chance of Success Score"));

  // Add the line
  // svg.append("path")
  //   .datum(ratingTimelineData)
  //   .attr("fill", "none")
  //   .attr("stroke", "black")
  //   .attr("stroke-width", 1.5)
  //   .attr("d", d3.line()
  //     // .curve(d3.curveBasis) // Just add that to have a curve instead of segments
  //     .x(d => x(d.date))
  //     .y(d => y(d.value))
  //     )

  svg.append("path")
    .datum(ratingTimelineData.reverse())
    .attr("fill", "none")
    .attr("stroke", "black")
    .attr("stroke-width", 2.5)
    .attr("stroke-linejoin", "round")
    .attr("stroke-linecap", "round")
    .attr("stroke-dasharray", `0,${l}`)
    .attr("d", line)
  .transition()
    .duration(4000)
    .ease(d3.easeLinear)
    .attr("stroke-dasharray", `${l},${l}`);

    
  // create a tooltip
  const Tooltip = d3.select("#rating-timeline-chart")
    .append("div")
    .style("opacity", 0)
    .attr("class", "tooltip")
    .style("background-color", "white")
    .style("border", "solid")
    .style("border-width", "2px")
    .style("border-radius", "5px")
    .style("padding", "5px")

    // Three function that change the tooltip when user hover / move / leave a cell
    const mouseover = function(event,d) {
      Tooltip
        .style("opacity", 1)
    }
    const mousemove = function(event,d) {
      Tooltip
        .html("Rating Score: " + d.value + " at $date.label" )
        .style("left", `${event.layerX+10}px`)
        .style("top", `${event.layerY}px`)
    }
    const mouseleave = function(event,d) {
      Tooltip
        .style("opacity", 0)
    }

  // Add the points
  svg
    .append("g")
    .selectAll("dot")
    .data(ratingTimelineData)
    .join("circle")
      .attr("class", "myCircle")
      .attr("cx", d => x(d.date))
      .attr("cy", d => y(d.value))
      .attr("r", 4)
      .attr("stroke", "#1e222e")
      .attr("stroke-width", 2)
      .attr("fill", "white")
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave)

  return <div id="rating-timeline-chart"></div>;
};

export default RatingHistoryChart;
