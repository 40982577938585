import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from './features/session';
import cardsReducer from './features/cards';
import singleCardReducer from './features/card';
import filterReducer from './features/filter';
import factorsReducer from './features/factors';
import clientFactorsReducer from './features/client-factors';
import downloadDocReducer from './features/download';
import partiesReducer from './features/parties';
import countReducer from './features/count-new';
import pvtCaseReducer from './features/pvtcase';

// import countReducer from './features/count';
// import endpointsReducer from './features/endpoints';

const store = configureStore({
  reducer: {
    session: sessionReducer,
    cards: cardsReducer,
    count: countReducer,
    card: singleCardReducer,
    filter: filterReducer,
    factors: factorsReducer,
    clientFactors: clientFactorsReducer,
    doc: downloadDocReducer,
    parties: partiesReducer,
    pvt_case: pvtCaseReducer

    // endpoints: endpointsReducer,
  },
});

export default store;
