// import { Formik, Field, Form } from 'formik';
import { Formik } from 'formik';
import _Modal from '../Modal';
import Footer from '../Cards/Footer';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import { useLayoutEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';

const Support = () => {
  const notify = () =>
    toast(
      'Thank you for reaching out. Someone will contact you at the email you have provided shortly.',
      {
        hideProgressBar: true,
        theme: 'dark',
        className: 'single-case-toast',
      }
    );

  const supportAPI = async (name, email, product, message) => {
    let response = await fetch(
      'https://d1i2jq400lm6rf.cloudfront.net/support/',
      {
        method: 'POST',
        contentType: 'application/json',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: name,
          email: email,
          product: product,
          message: message,
        }),
      }
    );
    if (response.ok) {
      notify();
    }
  };
  const { width, height } = useWindowDimensions();

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, []);

  return (
    <>
      <div className="support-container">
        <div className="support-form-container">
          <ToastContainer
            className="case-toast support-toast"
            progressClassName="case-toast-progress"
          />
          {/* support */}
          <div className="support-left">
            <div className="support-header">
              Contact us with a question or concern
            </div>
            <div className="support-sub-text">Tell us how we can help</div>
            {width > 799 ? (
              <>
                {/* <div className="support-faq">
                  <div className="support-faq-header">FAQ</div>
                  <div className="support-faq-body">
                    <div className="support-faq-qa">
                      <div className="support-faq-q">How do I do this?</div>
                      <div className="support-faq-a">
                        This is the answer to how to do the question above.
                      </div>
                    </div>
                    <div className="support-faq-qa">
                      <div className="support-faq-q">How do I do this?</div>
                      <div className="support-faq-a">
                        This is the answer to how to do the question above.
                      </div>
                    </div>
                    <div className="support-faq-qa">
                      <div className="support-faq-q">How do I do this?</div>
                      <div className="support-faq-a">
                        This is the answer to how to do the question above.
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="support-email">
                  <div className="support-email-header">
                    Need a direct line?
                  </div>
                  <div className="support-email-us">support@arbilex.co</div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="support-right">
            <div className="support-tr"></div>
            <Formik
              initialValues={{
                name: '',
                email: '',
                product: '',
                message: '',
              }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Email required';
                }
                if (!values.name) {
                  errors.name = 'Name required';
                }
                if (!values.product) {
                  errors.product = 'Product required';
                }
                if (!values.message) {
                  errors.message = 'Message required';
                }
                return errors;
              }}
              onSubmit={(values, { resetForm }) => {
                supportAPI(
                  values.name,
                  values.email,
                  values.product,
                  values.message
                );
                resetForm();
                // dispatch(
                //   signUp(values.name, values.email, values.password)
                // )
                // .then((data) => navigate('/', { replace: true }));
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit} className="support-form">
                  <div className="input-container support-input-field">
                    <label>Name</label>
                    <input
                      className="auth-input"
                      type="text"
                      name="name"
                      //   placeholder="Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <p className="formik-error">
                      {touched.name && errors.name}
                    </p>
                  </div>
                  <div className="input-container support-input-field">
                    <label>Email</label>
                    <input
                      className="auth-input"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <p className="formik-error">
                      {touched.email && errors.email}
                    </p>
                  </div>
                  <div className="input-container support-input-field">
                    <label>Product</label>
                    <select
                      className="support-products"
                      value={values.product}
                      onChange={handleChange}
                      name="product"
                    >
                      <option disabled={true} value="">
                        Select topic
                      </option>
                      <option value="AFX">AFX</option>
                      <option value="arbitrator">Arbitrator Analytics</option>
                    </select>
                    <p className="formik-error">
                      {touched.product && errors.product}
                    </p>
                  </div>
                  <div className="input-container support-last-input">
                    <label>Message</label>
                    <textarea
                      rows="9"
                      cols="50"
                      className="auth-input"
                      type="text"
                      name="message"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                    />
                    <p className="formik-error">
                      {touched.message && errors.message}
                    </p>
                  </div>
                  <button type="submit" className="support-submit-button">
                    Send Message
                  </button>
                </form>
              )}
            </Formik>
            <div className="support-bl"></div>
          </div>
          {width < 800 ? (
            <>
              {/* <div className="support-faq">
                <div className="support-faq-header">FAQ</div>
                <div className="support-faq-body">
                  <div className="support-faq-qa">
                    <div className="support-faq-q">How do I do this?</div>
                    <div className="support-faq-a">
                      This is the answer to how to do the question above.
                    </div>
                  </div>
                  <div className="support-faq-qa">
                    <div className="support-faq-q">How do I do this?</div>
                    <div className="support-faq-a">
                      This is the answer to how to do the question above.
                    </div>
                  </div>
                  <div className="support-faq-qa">
                    <div className="support-faq-q">How do I do this?</div>
                    <div className="support-faq-a">
                      This is the answer to how to do the question above.
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="support-email">
                <div className="support-email-header">Need a direct line?</div>
                <div className="support-email-us">support@arbilex.co</div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Footer />

      {/* <div className="support-footer">
        <div className="footer-info-container">
          <div className="support-footer-left">
            <div className="support-footer-arbilex">
              <div className="support-footer-logo"></div>
              <div className="support-footer-name">Arbilex</div>
            </div>
            <div className="support-footer-address">
              <p className="support-email">support@arbilex.co</p>
              <p>125 Western Ave, Allston, MA 02163</p>
            </div>
            <div className="support-footer-socials">
              <a href="https://angel.co/company/arbilex" target="_blank">
                <svg className="footer-AL" />
              </a>
              <a href="https://twitter.com/arbilexai" target="_blank">
                <svg className="footer-twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/arbilex/"
                target="_blank"
              >
                <svg className="footer-LI" />
              </a>
            </div>
          </div>
          <div className="support-footer-right">
            <div className="support-links">
              <h4 className="support-footer-sub">SUPPORT</h4>
              <Link to="/support">
                <p>Contact Us</p>
              </Link>
            </div>
            <div className="resources-links">
              <h4 className="support-footer-sub">RESOURCES</h4>
              <a href="https://www.arbilex.co" target="_blank">
                <p>Arbitrator Analytics</p>
              </a>
              <a href="https://www.arbilex.co" target="_blank">
                <p>Arbilex.co</p>
              </a>
            </div>
          </div>
        </div>
        <div className="support-footer-small-text">
          <div className="support-small-left">
            <p>
              © 2023 All right reserved <span>Arbi, Inc.</span>
            </p>
          </div>
          <div className="support-small-right">
            <_Modal
              children={modalToU}
              inline={{
                el: <p>Terms of Use</p>,
              }}
            />
            <div className="terms-divider"></div>
            <_Modal
              children={modalPrivacy}
              inline={{
                el: <p>Privacy Policy</p>,
              }}
            />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Support;
