import { DropdownButton, NavDropdown } from 'react-bootstrap';
import cn from 'classnames';

const SortBy = ({ setSortBy, setOrderBy }) => {

   const changeOrder = (sort, order) => {
     setSortBy(sort);
     setOrderBy(order);
     return;
   };
  
  return (
    //  Bootstrap Dropdown - Sort by dropdown
    <span className='sort'>
    <NavDropdown 
      title={<span>Sort by<img className='sort-icon' src='./sort-icon.png'/> </span>}
      id="sort-by-dropdown" 
      x-placement="bottom-end"
    >
      <NavDropdown.Item
        eventKey="4.1"
        onClick={() => changeOrder('date', 'desc')}
      >
        Newest First
      </NavDropdown.Item>
      <NavDropdown.Item
        eventKey="4.2"
        onClick={() => changeOrder('date', 'asc')}
      >
        Oldest First
      </NavDropdown.Item>
      <NavDropdown.Item
        eventKey="4.3"
        onClick={() => changeOrder('number', 'desc')}
      >
        Claim size descending
      </NavDropdown.Item>
      <NavDropdown.Item
        eventKey="4.4"
        onClick={() => changeOrder('number', 'asc')}
      >
        Claim size ascending
      </NavDropdown.Item>
    </NavDropdown>
    </span>
  );
};

export default SortBy;
