/* COMPONENTS */
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar/index.js';
import PrivateCase from './components/SingleCase/PrivateCase';
import SingleCase from './components/SingleCase';
import Cards from './components/Cards';
import SortFilter from './components/SortFilter';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Success from './components/ResetPassword/Success.js';
import NotFound from './components/404';
import useToken from './components/Hooks/useToken';
import ProtectedRoute from './ProtectedRoute';
import SignUp from './components/SignUp';
import Footer from './components/Cards/Footer';
import Support from './components/Support/Support.js';
import Dashboard from './components/Dashboard';
import AllRequests from './components/Dashboard/AllRequests';
import ScrollToTop from "./components/Hooks/ScrollToTop";

/* IMPORTS */
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useLayoutEffect, useState, useEffect } from 'react';
import { persistUser } from './store/features/session';
import { Provider, ErrorBoundary } from '@rollbar/react'; // Provider imports 'rollbar'
import 'react-tooltip/dist/react-tooltip.css'


function App() {
  // let persistToken = useToken();
  let persistToken = JSON.parse(localStorage.getItem('_uA'));
  let user = useSelector((state) => state?.session?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    environment: 'production',
  };

  // function TestError() {
  //   const a = null;
  //   return a.hello();
  // }
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    if (persistToken) {
      dispatch(persistUser(persistToken?.access));
    }
  }, []);
  useEffect(() => {}, [persistToken?.access]);

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <div className="App">
          <ScrollToTop />
          {user ? <Navbar user={user} /> : <Navbar />}
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard persistToken={persistToken} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cases"
              index
              element={
                <ProtectedRoute persistToken={persistToken}>
                  <SortFilter persistToken={persistToken} />
                  <Cards persistToken={persistToken} user={user} />
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cases/:id"
              element={
                <ProtectedRoute persistToken={persistToken}>
                  <SingleCase />
                </ProtectedRoute>
              }
            />
            <Route
              path="/privatecases/:id"
              element={
                <ProtectedRoute persistToken={persistToken}>
                  <PrivateCase persistToken={persistToken} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/my-requests"
              element={
                <ProtectedRoute persistToken={persistToken}>
                  <AllRequests persistToken={persistToken} />
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<Login />} exact={true} replace />
            <Route
              path="/reset_password"
              element={<ResetPassword />}
              exact={true}
              replace
            />
            <Route
              path="/change_password"
              element={<ForgotPassword />}
              exact={true}
              replace
            />
            <Route path="/success" element={<Success />} exact={true} />
            <Route path="/support" element={<Support />} exact={true} />
            <Route path="*" exact={true} element={<NotFound />} />
          </Routes>
        </div>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
