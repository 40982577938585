const GET_PVT_CASE = 'pvtcase/GET_SINGLE_PRIVATE_CARD/';

//******ACTIONS******//
export const getPvtCase = (pvtCaseData) => ({
  type: GET_PVT_CASE,
  pvtCaseData,
});

//******THUNKS******//

export const getPVTCaseThunk =
  ({ token, id }) =>
  async (dispatch) => {
    const res = await fetch(
      `https://d1i2jq400lm6rf.cloudfront.net/card?card=${id}&private_card=true`,
      // `http://127.0.0.1:8000/card?card=${id}&private_card=true`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.ok) {
      const data = await res.json();
      dispatch(getPvtCase(data));
      return data;
    } else {
      return res;
    }
  };

//******REDUCER******//

const initialState = {};

const pvtCaseReducer = (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case GET_PVT_CASE:
      newState[action?.pvtCaseData?.data?.id] = action?.pvtCaseData.data;
      return {
        ...newState,
      };
    default:
      return state;
  }
};
export default pvtCaseReducer;
