import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { Redirect, useNavigate } from 'react-router';
import { resetPWThunk } from '../../store/features/endpoints';
import useWindowDimensions from '../Hooks/useWindowDimensions';

const Success = () => {
  const { width, height } = useWindowDimensions();

  useEffect(() => {}, [width]);

  return (
    <div className="auth-background">
      <div className="auth-container">
        {width > 599 ? (
          <>
            <div className="auth-header">Password Reset Success!</div>
            <Link
              to="/login"
              type="submit"
              className="submit-button success-reset-btn"
              id="success-reset-link"
            >
              Sign In
            </Link>
            <hr />
            <div className="mobile-form-mailto">
              Any questions? Reach us at{' '}
              <a href="mailto: support@arbiLex.co">support@arbiLex.co</a>
            </div>
          </>
        ) : (
          <>
            <svg className="arbilex-blue-logo-m" />
            <div className="auth-header">Password Reset Success!</div>
            <Link
              to="/login"
              type="submit"
              className="submit-button success-reset-btn"
              id="success-reset-link"
            >
              Sign In
            </Link>
            <div className="success-divider">
              <div className="mobile-form-mailto">
                Any questions? Reach us at{' '}
                <a href="mailto: support@arbiLex.co">support@arbiLex.co</a>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Success;
