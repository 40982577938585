import { NavDropdown } from 'react-bootstrap';
import cn from 'classnames';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { getCardsThunk } from '../../store/features/cards';
import { filterCardsThunk } from '../../store/features/filter';
import { useDispatch, useSelector } from 'react-redux';
import { getCardsCountThunk } from '../../store/features/count-new';
import { useParams, useSearchParams } from 'react-router-dom';
import FilterModal from './FilterModal';
import { useNavigate } from 'react-router-dom';
import useToken from '../Hooks/useToken';
import Tabs from './Tabs';
import SortBy from './SortBy';
import SearchBar from './Search';

const SortFilter = () => {
  const { persistToken } = useToken();
  const [userQuery, setUserQuery] = useState();
  let params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const modalRef = useRef();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [filterCount, setFilterCount] = useState(0);
  const [sortBy, setSortBy] = useState('date');
  const [orderBy, setOrderBy] = useState('desc');
  const [selectAllTabs, setSelectAllTabs] = useState(false);

  const dispatch = useDispatch();
  const stateFilters = useSelector((state) => state?.filter);
  const PARTY = stateFilters?.party; // party constant
  const count = useSelector((state) => state?.count?.count);
  // *********** DATES VARIABLES ***********
  let todayDate = new Date().toISOString().split('T')[0];
  // make sure firstDate includes earliest case date
  let firstDate = new Date('2018-01-01').toISOString().split('T')[0];

  // Min & Max claim amounts cannot == 0
  const MIN_CLAIM_NUM = 1000000;
  const MAX_CLAIM_NUM = 50000000000;

  // *********** ACTIVE FILTERS USESTATE ***********
  // STATUS
  const [fStatus, setFStatus] = useState(null);
  const [uStatus, setUStatus] = useState(null);
  const [apStatus, setAPStatus] = useState(null);
  // CASE TYPE
  const [awardEnforcement, setAwardEnforcement] = useState(null);
  const [iTArbitration, setITArbitration] = useState(null);
  const [patentCase, setPatentCase] = useState(null);

  //RATING
  const [aRating, setARating] = useState(null);
  const [bRating, setBRating] = useState(null);
  const [cRating, setCRating] = useState(null);
  const [dRating, setDRating] = useState(null);

  // LISTING TYPE
  const [autoscrapedListing, setAutoscrapedListing] = useState(null);
  const [exclusiveListing, setExclusiveListing] = useState(null);

  // FILING DATE
  const [startDate, setStartDate] = useState(firstDate);
  const [endDate, setEndDate] = useState(todayDate);

  // CLAIM SIZE
  const [minClaimSize, setMinClaimSize] = useState(MIN_CLAIM_NUM);
  const [maxClaimSize, setMaxClaimSize] = useState(MAX_CLAIM_NUM);

  // VENUE
  const [venueLocation, setVenueLocation] = useState(null);

  const [bigObj, setBigObj] = useState({
    status: [],
    listing_type: [],
    rating: [],
    venue: '',
    case_type: [],
    min_claim: MIN_CLAIM_NUM,
    max_claim: MAX_CLAIM_NUM,
    start_date: firstDate,
    end_date: todayDate,
    order: 'desc',
    sort: 'date',
    party: PARTY,
  });

  /*
   Function that gets a user's ** active applied filters (bigObj) NOT live filters **
   returns index of which quick filter is the equivalent of the filters applied, if any
   */
  const objectCheck = (filters) => {
    if (
      Object.values(filters).every((val) => val == false) ||
      (filters.status.length === 0 &&
        filters.listing_type.length === 0 &&
        filters.case_type.length === 0 &&
        filters.rating.length === 0 &&
        filters.venue.length === 0 &&
        filters.min_claim === MIN_CLAIM_NUM &&
        filters.max_claim === MAX_CLAIM_NUM &&
        filters.start_date === firstDate &&
        filters.end_date === todayDate) ||
      (filters.status.length === 0 &&
        filters.listing_type.length === 0 &&
        filters.case_type.length === 0 &&
        filters.rating.length === 0 &&
        !filters.venue &&
        !filters.min_claim &&
        !filters.max_claim &&
        !filters.start_date &&
        !filters.end_date)
    ) {
      setActiveIndex(0);
      return 0;
    } else if (
      filters.status.length === 0 &&
      filters.listing_type.length === 0 &&
      filters.rating.length === 0 &&
      filters.case_type.length === 1 &&
      filters.case_type.includes('Patent') &&
      filters.min_claim === MIN_CLAIM_NUM &&
      filters.max_claim === MAX_CLAIM_NUM &&
      filters.start_date === firstDate &&
      filters.end_date === todayDate &&
      !filters.venue
    ) {
      setActiveIndex(3);
      return 3;
    } else if (
      filters.status.length === 0 &&
      filters.listing_type.length === 0 &&
      filters.rating.length === 0 &&
      filters.case_type.length === 1 &&
      filters.case_type.includes('Investment Treaty Arbitration') &&
      filters.min_claim === MIN_CLAIM_NUM &&
      filters.max_claim === MAX_CLAIM_NUM &&
      filters.start_date === firstDate &&
      filters.end_date === todayDate &&
      !filters.venue
    ) {
      setActiveIndex(2);
      return 2;
    } else if (
      filters.status.length === 0 &&
      filters.listing_type.length === 0 &&
      filters.rating.length === 0 &&
      filters.case_type.length === 1 &&
      filters.case_type.includes('Award Enforcement') &&
      filters.min_claim === MIN_CLAIM_NUM &&
      filters.max_claim === MAX_CLAIM_NUM &&
      filters.start_date === firstDate &&
      filters.end_date === todayDate &&
      !filters.venue
    ) {
      setActiveIndex(1);
      return 1;
    } else {
      setActiveIndex(null);
    }
    return;
  };
  /*
In order to get a user's live filter choices a
useEffect is created with an object (activeFilters) 
that adds user's filters live to state object,
this gets sent to store/features/filter.js 
and then is grabbed from state as stateFilters variable
*/

  useEffect(() => {
    let activeFilters = {
      status: [],
      listing_type: [],
      venue: '',
      case_type: [],
      rating: [],
      min_claim: MIN_CLAIM_NUM,
      max_claim: MAX_CLAIM_NUM,
      start_date: firstDate,
      end_date: todayDate,
      order: 'desc',
      sort: 'date',
      party: PARTY,
    };
    let getActive = async () => {
      await Promise.all([
        //STATUS
        fStatus ? activeFilters.status.push(fStatus) : null,
        uStatus ? activeFilters.status.push(uStatus) : null,
        apStatus ? activeFilters.status.push(apStatus) : null,
        //CASE TYPE
        awardEnforcement
          ? activeFilters.case_type.push(awardEnforcement)
          : null,
        iTArbitration ? activeFilters.case_type.push(iTArbitration) : null,
        patentCase ? activeFilters.case_type.push(patentCase) : null,
        //RATING
        aRating ? activeFilters.rating.push(aRating) : null,
        bRating ? activeFilters.rating.push(bRating) : null,
        cRating ? activeFilters.rating.push(cRating) : null,
        dRating ? activeFilters.rating.push(dRating) : null,
        //LISTING TYPE
        autoscrapedListing
          ? activeFilters.listing_type.push(autoscrapedListing)
          : null,
        exclusiveListing
          ? activeFilters.listing_type.push(exclusiveListing)
          : null,
        // DATE
        endDate ? (activeFilters.end_date = endDate) : todayDate,
        startDate ? (activeFilters.start_date = startDate) : startDate,
        // VENUE
        venueLocation ? (activeFilters.venue = venueLocation) : null,
        //CLAIM SIZE
        minClaimSize ? (activeFilters.min_claim = minClaimSize) : MIN_CLAIM_NUM,
        maxClaimSize ? (activeFilters.max_claim = maxClaimSize) : MAX_CLAIM_NUM,
        //ORDER SORT
        sortBy ? (activeFilters.sort = sortBy) : 'date',
        orderBy ? (activeFilters.order = orderBy) : 'desc',
        //PARTY
        // stateFilters?.party ? (activeFilters?.party = stateFilters?.party) : null,
      ])
        .then(dispatch(filterCardsThunk({ filters: activeFilters })))
        .then(
          dispatch(
            getCardsCountThunk({
              token: persistToken?.access,
              filters: activeFilters,
            })
          )
            .then((res) => {
              if (res.status === 401) {
                navigate('/login');
                return;
              } else if (res.status >= 400) {
                navigate('/404-not-found');
              }
              return;
            })
            .catch((err) => console.error(err))
        );
    };
    getActive().catch(console.error);
    objectCheck(bigObj);
  }, [
    // status
    fStatus,
    uStatus,
    apStatus,
    // case
    awardEnforcement,
    iTArbitration,
    patentCase,
    // rating
    aRating,
    bRating,
    cRating,
    dRating,
    // listing
    autoscrapedListing,
    exclusiveListing,
    // date
    endDate,
    startDate,
    // venue
    venueLocation,
    // sort
    sortBy,
    orderBy,
    // selectAllTabs,
    setActiveIndex,
    // objectCheck
    activeIndex,
    // claim
    minClaimSize,
    maxClaimSize,
    PARTY,
  ]);

  // useEffect(() => {
  //   let activeFilters = stateFilters;
  //   //ORDER SORT
  //   if (sortBy) activeFilters.sort = sortBy;
  //   if (orderBy) activeFilters.order = orderBy;

  //   // dispatches user's live filter updates to state variables
  //   // dispatch(filterCardsThunk({ filters: activeFilters }));
  // }, [
  //   // sort
  //   sortBy,
  //   orderBy,
  // ]);

  const showModalFunction = () => {
    // allCasesTab(0);
    showModal ? setShowModal(false) : setShowModal(true);
  };

  /*
  Function that is passed down to children
  in FilterModal when user clicks apply 
  we set the bigObj to the live filters (stateFilters) 
  */
  const sendFilters = (e) => {
    e.preventDefault();
    setBigObj(stateFilters);
    return;
  };

  const myFunc = (filters) => {
    let queryString = '';

    const query = (filter) => {
      if (queryString.length) {
        queryString += `,${filter}`;
      } else {
        queryString += `${filter}`;
      }
    };

    //Party
    if (filters?.party?.length >= 0) {
      if (filters?.party.includes('&')) {
        let encoded = filters.party.replace('&', '%and');

        query(`"party":"${encoded}"`);
      } else {
        query(`"party":"${filters?.party}"`);
      }
    }

    // Status
    if (filters?.status?.length > 0) {
      let arr = [];
      filters.status.forEach((el) => {
        arr.push(`"${el}"`);
      });
      query(`"status":[${arr}]`);
    }
    // Listing Type
    if (filters?.listing_type?.length > 0) {
      let arr = [];
      filters.listing_type.forEach((el) => {
        arr.push(`"${el}"`);
      });
      query(`"listing_type":[${arr}]`);
    }

    // rating
    if (filters?.rating?.length > 0) {
      let arr = [];
      filters.rating.forEach((el) => {
        arr.push(`"${el}"`);
      });
      query(`"rating":[${arr}]`);
    }

    // Venue
    if (filters?.venue?.length > 0) {
      query(`"venue":"${filters?.venue}"`);
    }
    // Case Type
    if (filters?.case_type?.length > 0) {
      let arr = [];
      filters.case_type.forEach((el) => {
        arr.push(`"${el}"`);
      });
      query(`"case_type":[${arr}]`);
    }
    // Start Date
    if (filters?.start_date?.length > 0) {
      query(`"start_date":"${filters?.start_date}"`);
    }
    // End Date
    if (filters?.end_date?.length > 0) {
      query(`"end_date":"${filters?.end_date}"`);
    }
    // Min Claim
    if (filters?.min_claim) {
      query(`"min_number":${Number(filters?.min_claim)}`);
    }
    // Max Claim
    if (filters?.max_claim) {
      query(`"max_number":${Number(filters?.max_claim)}`);
    }

    return queryString;
  };

  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      if (!modalRef?.current?.contains(e.target)) {
        setShowModal(false);
      }
    });
  }, []);

  // // ? ********** ALL CASES TAB **********
  // async function allTabs() {
  //   await Promise.all([
  //     setFStatus(null),
  //     setUStatus(null),
  //     setAPStatus(null),
  //     setITArbitration(null),
  //     setPatentCase(null),
  //     setAutoscrapedListing(null),
  //     setVenueLocation(null),
  //     setExclusiveListing(null),
  //     setAwardEnforcement(null),
  //     setStartDate('2018-01-01'),
  //     setEndDate(todayDate),
  //     setMinClaimSize(MIN_CLAIM_NUM),
  //     setMaxClaimSize(MAX_CLAIM_NUM),
  //   ]);
  //   return;
  // }

  // const allCasesTab = (e) => {
  //   setActiveIndex(e.target?.attributes?.index?.value);
  //   setFilterCount(0);
  //   allTabs();
  //   return;
  // };

  // ? ********** SORT TAB **********

  useEffect(() => {
    setBigObj((prevState) => ({
      ...prevState,
      ['order']: orderBy,
      ['sort']: sortBy,
    }));
  }, [sortBy, orderBy]);

  /**
   * Function passed down to SortBy component to add user's sort order
   * updates live state filters and applies bigObj to render data accordingly
   * @param {*} sort
   * @param {*} order
   * @returns
   */

  useEffect(() => {
    const params = new URLSearchParams();
    dispatch(getCardsThunk({ token: persistToken, filters: bigObj }))
      .then((res) => {
        if (res?.status_code === 401) {
          navigate('/login');
          // return;
        } else if (res?.status_code >= 400) {
          navigate('/404-not-found');
        }
        return;
      })
      .catch((err) => console.error(err));

    myFunc(bigObj);
  }, [bigObj]);

  // ********** Filter Counter **********

  /**
   * These are the counter for each SECTION.
   * if a new filter section is added please add new corresponding counter state.
   * else it is not necessary, please move on to the conditional in filterLog()
   */
  const [fundingStatusCounter, setFundingStatusCounter] = useState(0);
  const [listingTypeCounter, setListingTypeCounter] = useState(0);
  const [caseTypeCounter, setCaseTypeCounter] = useState(0);
  const [ratingCounter, setRatingCounter] = useState(0);
  const [venueCounter, setVenueCounter] = useState(0);
  const [claimCounter, setClaimCounter] = useState(0);
  const [dateCounter, setDateCounter] = useState(0);

  /**
   * If a filter is enabled for a section then we add a +1. Each section receives only 1 point.
   * We add the points/count on the useLayoutEffect.
   * REMINDER - if the filter is in a section then add the conditional to the corresponding section.
   * i.e. if we add a listing type the we add it to the section and we do not need to create a new useState counter above.
   */
  const filterLog = () => {
    // Listing Type
    if (
      bigObj?.listing_type?.includes('Autoscraped') ||
      bigObj?.listing_type?.includes('Exclusive')
    ) {
      setListingTypeCounter(1);
    } else if (
      !bigObj?.listing_type?.includes('Autoscraped') ||
      !bigObj?.listing_type?.includes('Exclusive')
    ) {
      setListingTypeCounter(0);
    }
    // Status
    if (
      bigObj?.status?.includes('Green') ||
      bigObj?.status?.includes('Purple') ||
      bigObj?.status?.includes('Gray')
    ) {
      setFundingStatusCounter(1);
    } else if (
      !bigObj?.status?.includes('Green') ||
      !bigObj?.status?.includes('Purple') ||
      !bigObj?.status?.includes('Gray')
    ) {
      setFundingStatusCounter(0);
    }
    // Case Type
    if (
      bigObj?.case_type?.includes('Patent') ||
      bigObj?.case_type?.includes('Award Enforcement') ||
      bigObj?.case_type?.includes('Investment Treaty Arbitration')
    ) {
      setCaseTypeCounter(1);
    } else if (
      !bigObj?.case_type?.includes('Patent') ||
      !bigObj?.case_type?.includes('Award Enforcement') ||
      !bigObj?.case_type?.includes('Investment Treaty Arbitration')
    ) {
      setCaseTypeCounter(0);
    }

    // rating
    if (
      bigObj?.rating?.includes('A') ||
      bigObj?.rating?.includes('B') ||
      bigObj?.rating?.includes('C') ||
      bigObj?.rating?.includes('D')
    ) {
      setRatingCounter(1);
    } else if (
      !bigObj?.rating?.includes('A') ||
      !bigObj?.rating?.includes('B') ||
      !bigObj?.rating?.includes('C') ||
      !bigObj?.rating?.includes('D')
    ) {
      setRatingCounter(0);
    }
    // Venue
    if (bigObj?.venue?.length > 0) {
      setVenueCounter(1);
    } else {
      setVenueCounter(0);
    }
    // Claim Size
    if (
      bigObj?.min_claim !== MIN_CLAIM_NUM ||
      bigObj?.max_claim !== MAX_CLAIM_NUM
    ) {
      setClaimCounter(1);
    } else {
      setClaimCounter(0);
    }
    // Date
    if (bigObj?.start_date !== firstDate || bigObj?.end_date !== todayDate) {
      setDateCounter(1);
    } else {
      setDateCounter(0);
    }
  };

  useLayoutEffect(() => {
    setFilterCount(
      fundingStatusCounter +
        listingTypeCounter +
        ratingCounter +
        caseTypeCounter +
        venueCounter +
        claimCounter +
        dateCounter
    );
  }, [
    fundingStatusCounter,
    caseTypeCounter,
    listingTypeCounter,
    ratingCounter,
    venueCounter,
    claimCounter,
    dateCounter,
    bigObj,
  ]);

  useEffect(() => {
    filterLog();
  }, [filterLog, bigObj]);

  return (
    <div className="center-filter-container">
      <div className="filter-sort-container" as="ul">
        {/* <Tabs
          setFilterCount={setFilterCount}
          setFStatus={setFStatus}
          setUStatus={setUStatus}
          setAPStatus={setAPStatus}
          setAwardEnforcement={setAwardEnforcement}
          setITArbitration={setITArbitration}
          setAutoscrapedListing={setAutoscrapedListing}
          setExclusiveListing={setExclusiveListing}
          startDate={startDate}
          setStartDate={setStartDate}
          setVenueLocation={setVenueLocation}
          setMinClaimSize={setMinClaimSize}
          setMaxClaimSize={setMaxClaimSize}
          objectCheck={objectCheck}
          bigObj={bigObj}
          todayDate={todayDate}
          firstDate={firstDate}
          setBigObj={setBigObj}
          MAX_CLAIM_NUM={MAX_CLAIM_NUM}
          MIN_CLAIM_NUM={MIN_CLAIM_NUM}
          setActiveIndex={setActiveIndex}
          activeIndex={activeIndex}
          selectAllTabs={selectAllTabs}
          setEndDate={setEndDate}
          setPatentCase={setPatentCase}
          setARating={setARating}
          setBRating={setBRating}
          setCRating={setCRating}
          setDRating={setDRating}
        /> */}
        <svg
          className="search-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
        </svg>

        {/* <p> &nbsp; &nbsp;</p> */}
        <SearchBar
          setBigObj={setBigObj}
          bigObj={bigObj}
          firstDate={firstDate}
          todayDate={todayDate}
          MIN_CLAIM_NUM={MIN_CLAIM_NUM}
          MAX_CLAIM_NUM={MAX_CLAIM_NUM}
        />

        {/* <div className="menu-divider"></div> */}

        {/* <div className="menu-divider"></div> */}
        {/* Bootstrap Dropdown - Sort by dropdown  */}
        <div className="dropdown-container">
          <SortBy setSortBy={setSortBy} setOrderBy={setOrderBy} />

          <div className="ref-holder-container" ref={modalRef}>
            <button
              className="filter-button"
              onClick={showModalFunction}
              // ref={modalRef}
            >
              Filters
              <div className="filter-svg"></div>
            </button>
            <div ref={modalRef}>
              {showModal && (
                <FilterModal
                  setShowModal={setShowModal}
                  showModal={showModal}
                  showModalFunction={showModalFunction}
                  setFilterCount={setFilterCount}
                  modalRef={modalRef}
                  setFStatus={setFStatus}
                  setUStatus={setUStatus}
                  setAPStatus={setAPStatus}
                  setAwardEnforcement={setAwardEnforcement}
                  setITArbitration={setITArbitration}
                  setPatentCase={setPatentCase}
                  setAutoscrapedListing={setAutoscrapedListing}
                  setExclusiveListing={setExclusiveListing}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  setVenueLocation={setVenueLocation}
                  setMinClaimSize={setMinClaimSize}
                  setMaxClaimSize={setMaxClaimSize}
                  todayDate={todayDate}
                  firstDate={firstDate}
                  sendFilters={sendFilters}
                  bigObj={bigObj}
                  setBigObj={setBigObj}
                  MIN_CLAIM_NUM={MIN_CLAIM_NUM}
                  MAX_CLAIM_NUM={MAX_CLAIM_NUM}
                  setActiveIndex={setActiveIndex}
                  // Pass down useState variables for filter counter
                  iTArbitration={iTArbitration}
                  setARating={setARating}
                  setBRating={setBRating}
                  setCRating={setCRating}
                  setDRating={setDRating}
                  count={count}
                  stateFilters={stateFilters}
                />
              )}
            </div>
            {filterCount > 0 && (
              <div className="total-filter-count">{filterCount}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SortFilter;
// if (fStatus && !activeStatus.includes(fStatus)) {
//   if (activeStatus.length > 0) {
//     setActiveStatus((prevState) => [...prevState, fStatus]);
//   } else {
//     setActiveStatus(fStatus);
//   }
// } else if (!fStatus) {
//   if (activeStatus.indexOf(fStatus) > -1)
//     setActiveStatus((prevState) => [
//       prevState.slice(prevState.indexOf(fStatus), 1),
//     ]);
// }

// if (uStatus) {
//   if (activeStatus.length > 1) {
//     setActiveStatus((prevState) => [...prevState, uStatus]);
//   } else {
//     setActiveStatus(uStatus);
//   }
// } else {
//   setActiveStatus((prevState) => [
//     prevState.slice(prevState.indexOf(uStatus), 1),
//   ]);
// }

// if (uStatus) {
//   setActiveStatus((prevState) => new Set([...prevState, uStatus]));
// }
// if (apStatus) {
//   setActiveStatus((prevState) => new Set([...prevState, apStatus]));
// }
// if (uStatu) activeFilters.status.push(uStatus);
// if (apStatus) activeFilters.status.push(apStatus);
