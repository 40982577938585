import { useEffect, useState } from 'react';
import { login } from '../../store/features/session';
import { Formik, replace } from 'formik';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const dispatch = useDispatch();
  let user = useSelector((state) => state?.session?.user);
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user]);

  useEffect(() => {}, [width]);

  const notify = () =>
    toast('Invalid Credentials - Please check login email and password', {
      hideProgressBar: true,
      theme: 'dark',
      className: 'single-case-toast',
    });

  return (
    <div className="auth-background">
      <div className="auth-container">
        {width > 599 ? (
          <div className="auth-header">Sign In</div>
        ) : (
          <>
            <svg className="arbilex-blue-logo-m" />
            <div className="auth-header">Sign In</div>
            <div className="forgot-pass-text">Welcome back to AFX</div>
          </>
        )}
        <Formik
          initialValues={{ email: '', password: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Email required*';
            }
            if (!values.password) {
              errors.password = 'Password required*';
            }
            return errors;
          }}
          onSubmit={(values) => {
            dispatch(login({ email: values.email, password: values.password }))
              .then((res) => {
                if (
                  res?.message &&
                  res?.message === 'Please reset the password using the OTP'
                ) {
                  navigate('/reset_password', {
                    state: { email: values.email, otp: res?.otp },
                  });
                } else if (res.status_code < 400) {
                  navigate('/');
                } else {
                  notify();
                }
              })
              .catch((err) => console.error(err));
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="input-container">
                {width > 599 ? (
                  <>
                    <input
                      className="auth-input"
                      type="email"
                      name="email"
                      placeholder="Email*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <p className="formik-error">
                      {touched.email && errors.email}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="input-labels-m">Email</p>
                    <input
                      className="auth-input"
                      type="email"
                      name="email"
                      placeholder="Email*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <p className="formik-error">
                      {touched.email && errors.email}
                    </p>
                  </>
                )}
              </div>

              {width > 599 ? (
                <>
                  <div className="input-container">
                    <input
                      className="auth-input"
                      type="password"
                      name="password"
                      placeholder="Password*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <p className="formik-error">
                      {touched.password && errors.password}
                    </p>
                  </div>
                  <div className="helper-text">
                    <Link to={'/change_password'}>
                      <span className="underline login-forgot-link">
                        Forgot your password?
                      </span>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div className="forgot-pass-container-m">
                    <p className="input-labels-m">Password</p>
                    <div className="helper-text">
                      <Link to={'/change_password'}>
                        <span className="underline login-forgot-link">
                          Forgot your password?
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="input-container ic-login-m">
                    <input
                      className="auth-input"
                      type="password"
                      name="password"
                      placeholder="Password*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <p className="formik-error">
                      {touched.password && errors.password}
                    </p>
                  </div>
                </>
              )}
              <button type="submit" className="submit-button">
                Log In
              </button>
              {width <= 599 ? (
                <div className="mobile-form-mailto">
                  Don&#39;t have an account? Reach out at{' '}
                  <a href="mailto: hello@arbiLex.co">hello@arbiLex.co</a>
                </div>
              ) : (
                <></>
              )}

              <ToastContainer
                className="case-toast"
                progressClassName="case-toast-progress"
              />
            </form>
          )}
        </Formik>
        <hr />
        {/* <div className="helper-text">
          Don't have an account yet?{' '}
          {/* <Link to="signup" className="blue-text">
            Sign up
          </Link>
        </div> */}
        {width > 599 ? (
          <div className="mobile-form-mailto">
            Don&#39;t have an account? Reach out at{' '}
            <a href="mailto: hello@arbiLex.co">hello@arbiLex.co</a>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default Login;
