import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';

function ElementModal({ children, inline }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {/* Default Modal button if NO INLINE  */}
      {!inline && (
        <Button variant="primary" onClick={handleShow}>
          Launch modal
        </Button>
      )}
      {/* Modal 'button/link' given inline */}
      {inline && (
        <div className="modal-element-click" onClick={handleShow}>
          {inline.el}
        </div>
      )}
      <Modal
        dialogClassName="modal-element-body"
        show={show}
        onHide={handleClose}
      >
        <div className="modal-element-close-holder">
          <svg className="modal-element-close-button" onClick={handleClose} />
        </div>
        {/* <br />
        <br /> */}
        {/* <Modal.Body>{children()}</Modal.Body> */}
        {typeof children === 'function' ? (
          <Modal.Body>{children()}</Modal.Body>
        ) : (
          <Modal.Body>{children}</Modal.Body>
        )}
      </Modal>
    </>
  );
}

ElementModal.propTypes = {
  children: PropTypes.element.isRequired,
  inline: PropTypes.shape({
    el: PropTypes.element.isRequired,
  }),
};

export default ElementModal;
