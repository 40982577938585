import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _Modal from '../Modal';
import modalCoS from '../Modal/modalCoS';
import cn from 'classnames';
import RatingHistoryChart from './RatingHistoryChart';
import RatingHistoryEvents from './RatingHistoryEvents';


const RatingCards = ({ rating }) => {
  const [chanceOpen, setChanceOpen] = useState(true);
  const [loadFactor, setLoadFactor] = useState(false);
  const [factorOpen, setFactorOpen] = useState({});

  let user = useSelector((state) => state.session?.user);
  let membership = user?.subscription;

  const openChance = (e) => {
    e.preventDefault();
    chanceOpen ? setChanceOpen(false) : setChanceOpen(true);
  };

  const openFactor = (e, i) => {
    e.preventDefault();

    if (factorOpen[i].open) {
      setFactorOpen((prevState) => ({
        ...prevState,
        [i]: { open: false },
      }));
    } else {
      setFactorOpen((prevState) => ({
        ...prevState,
        [i]: { open: true },
      }));
    }
  };

  // Keys=number of month; Value=abbrevieated month
  const months = {
    "01": 'Jan',
    "02": 'Feb',
    "03": 'Mar',
    "04": 'Apr',
    "05": 'May',
    "06": 'Jun',
    "07": 'Jul',
    "08": 'Aug',
    "09": 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

      // Converter for date format string
  const dateConverter = (date) => {
    let newDate = date.split('-');
    newDate = `${months[newDate[1]]} ${Number(newDate[2])}, ${Number(
      newDate[0]
    )}`;
    return newDate;
  };

  const scoreConverter = (score) => {
    if (score >= 0 && score <= 25) {
      return "D";
    } 
    if (score >= 26 && score <= 50) {
      return "C";
    } 
    if (score >= 51 && score <= 75) {
      return "B";
    } 
    if (score >= 76 && score <= 100) {
      return "A";
    } 
  };

  useEffect(() => {
    (async () => {
      rating?.card?.detailed_information?.factors?.forEach((el, i) =>
        setFactorOpen((prevState) => ({
          ...prevState,
          [i]: { open: false },
        }))
      );
      setLoadFactor(true);
    })();
  }, []);
  return (
    <>
      <div className="rating-holder">
        <div className="factor-container">
          <div className="factor-header">
            <div className="factor-label-container">
              <div className="factor-label">Rating Factor</div>
              <div className="factor-label">Factor Score</div>
            </div>
            <div className="factor-content-container">
              <div className="factor-content">
                {/* {membership !== 'Free' && `Chance of Success`} */}
                Chance of Success
                {/* {membership === 'Free' && (
                  <div className="free-chance">
                    Upgrade to see actual case factors
                  </div>
                )} */}
              </div>
              <div className="factor-content">
                {rating?.card?.detailed_information?.chance?.score}
                {/* {membership === 'Free' && (
                  <div className="free-chance">
                    XX
                  </div>
                )} */}
              </div>
            </div>
          </div>
          {rating?.card?.text?.rating_updated === true && (
          <div className="factor-history-container" >
            <div className="factor-label">Rating History</div>
            <div className="factor-content factor-history">
              <div className="factor-history-table">
                  <div className="factor-history-table-headers">
                      <div className="factor-history-table-date-column factor-history-header-table-column factor-label">
                        <img
                          src={require(`../../assets/Feather Icons/fi_calendar.png`)}
                          className="rating-updated-date-icon"
                          alt="Date Icon for Rating Updated"
                        />
                        Date
                      </div>
                      <div className="factor-history-table-score-column factor-history-header-table-column factor-label">Score</div>
                      <div className="factor-history-table-event-column factor-history-header-table-column factor-label">AFX Rating</div>
                  </div>
                  <div className="factor-history-table-body">
                    {rating?.card?.text?.rating_timeline?.map((item, i) => {
                      if(item.factor === "chance_of_success") {
                        return (
                          <div className='factor-history-body-table-row' key={i}>
                            <div className="factor-history-table-date-column factor-history-body-table-column">{dateConverter(item.date)}</div>
                            <div className="factor-history-table-score-column factor-history-body-table-column">{item.score}</div>
                            <div className="factor-history-table-event-column factor-history-body-table-column">{scoreConverter(item.score)}</div>
                          </div>
                        );
                      }
                    })}
                  </div>
              </div>
              {/* <RatingHistoryChart chartData={{ rating }} /> */}
              {/* <RatingHistoryEvents chartData={{ rating }} /> */}
            </div>
          </div>
          )}
          <div className="factor-explanation-container">
            <div className="factor-label">Explanation</div>
            <div className="factor-content content-explanation">
              {membership !== 'Free' &&
                rating?.card?.detailed_information?.chance?.text}
              {membership !== 'Free' && (
                <div className="modal-intro-paragraph">
                  <br />
                  To predict the chance of success at trial, our AI model is trained on publicly available decisions. The training dataset does not include cases with settlements, cases filed under seal, other termination and case dismissals where the judicial decisions were not observed. To learn more about the latest predictive performance of our AI models, please refer to our{' '}
                  <_Modal children={modalCoS}
                    inline={{ el: <span className='underline-animation data-table-modal-link'>chance of success model evaluation results.</span> }} 
                  />
                </div>
              )}
              {membership === 'Free' && (
                <div className="free-chance">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </div>
              )}
            </div>
          </div> 
        </div>

        {membership !== 'Free' &&
          rating?.card?.detailed_information?.factors &&
          rating?.card?.detailed_information?.factors?.map((ft, i) => (
            <div className="factor-container" key={i}>
              <div className="factor-header">
                <div className="factor-label-container">
                  <div className="factor-label">Rating Factor</div>
                  <div className="factor-label">Factor Score</div>
                </div>
                <div className="factor-content-container">
                  <div className="factor-content">{ft?.factor_name}</div>
                  <div className="factor-content">{ft?.score}</div>
                </div>
              </div>
              <div className="factor-explanation-container">
                <div className="factor-label">Explanation</div>
                <div className="factor-content">{ft?.text}</div>
              </div>
            </div>
          ))}
      </div>

      {/* {membership !== 'Free' &&
        rating?.card?.detailed_information?.factors &&
        rating?.card?.detailed_information?.factors?.map((ft, i) => (
          <div className="rating-holder" key={i}>
            <div onClick={(e) => openFactor(e, i)} className="rating-card">
              <div className="right-card-row">
                <h3 className="right-factor-title">
                  <button
                    className={cn('rating-card-arrow', {
                      open_chance: factorOpen[i]?.open,
                    })}
                  ></button>
                  {ft?.factor_name}
                </h3>
                <div className="right-factor-score-holder">
                  Factor Score:
                  <p className="right-factor-score">{ft?.score}</p>
                </div>
              </div>
              {loadFactor && factorOpen[i].open && (
                <div className="right-open-holder">
                  <p className="right-open-title">Explanation:</p>
                  {ft?.text}
                </div>
              )}
            </div>
          </div>
        ))}
      {membership === 'Free' &&
        rating?.card?.detailed_information?.factors &&
        rating?.card?.detailed_information?.factors?.map((ft, i) => (
          <div className="rating-holder" key={i}>
            <div className="rating-card">
              <div className="right-card-row">
                <h3 className="right-factor-title">
                  <button className={cn('rating-card-arrow', {})}></button>
                  {ft?.factor_name}
                  <div className="free-check"></div>
                </h3>
                <div className="right-factor-score-holder">
                  Factor Score:
                  <p className="right-factor-score free-member">XX</p>
                </div>
              </div>
            </div>
          </div>
        ))} */}
    </>
  );
};


export default RatingCards;
