import Stats from './Stats';
import Bookmarks from './Bookmarks';
import MostViewed from './MostViewed';
import Requests from './Requests';
import DashboardWatchlist from './DashboardWatchlist';
import Breakdown from './Breakdown';
import useToken from '../Hooks/useToken';
import { useEffect,useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Footer from '../Cards/Footer';
import cn from 'classnames';
import omnibridgwaylogo from '../../assets/Logos/OmniBridgewayLogo.svg';

const Dashboard = ({ persistToken }) => {
  let user = useSelector((state) => state?.session?.user);
  const [summaryOpen, setSummaryOpen] = useState(true);


  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, []);

  useEffect(() => {}, [persistToken]);
  const openCloseSummary = (e) => {
    e.stopPropagation();
    summaryOpen === true ? setSummaryOpen(false) : setSummaryOpen(true);
  };

  const organizationLogoRender = (org) => {
    switch(org) {
      case 1:
        return (
          <img
              src={require(`../../assets/logo-black.png`)}
              className="user-organization-logo"
              alt="ArbiLex Logo"
            />
        );
      case 2:
        return (
          <img
              src={omnibridgwaylogo}
              className="user-organization-logo"
              alt="Omni Bridgeway Logo"
            />
        );
      default:
        return;
    }
  };
  
  return (
    <>
      <div className="dash-container">
        <div className="dash-comp-container">
          <div className="my-dash-title"> 
            {user?.name}'s Dashboard
            {organizationLogoRender(user?.organization)}
          </div>
          <div className="dash-title-divider"></div>
          <div className="dash-section-box">
            <div className="dash-section-holder stat-breakdown-holder">
              {/* <Stats persistToken={persistToken} />
              <Breakdown persistToken={persistToken} /> */}
              <div
                className={cn('stat-breakdown-collapse', {
                  collapsed: summaryOpen === false,
                })}
                onClick={(e) => openCloseSummary(e)}
              >
                AFX Summary
                <div
                  className={cn('dashboard-dropdown-icon', {
                    collapsed: summaryOpen === false,
                  })}
                ></div>
              </div>
              <div
                className={cn('dash-top-stat-breakdown', {
                collapsed: summaryOpen === false,
              })}
              >
              {/* <div className="dash-top-stat-breakdown"> */}
                <Stats persistToken={persistToken} />
                <Breakdown persistToken={persistToken} />
              </div>
            </div>
            <div className="dash-section-holder"></div>
            <div className="dash-section-holder dash-book-req-holder">
              {/* <MostViewed /> */}
              {/* <Bookmarks /> */}
              <DashboardWatchlist persistToken={persistToken} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;