import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

const FactorProgress = ({ completed }) => {
  let user = useSelector((state) => state.session?.user);
  let membership = user?.subscription


  const fillerStyles = {
    width: `${completed}%`,
  };
  const freeStyles = {
    width: `${100}%`,
  };

  let split = fillerStyles.width.split('%')[0];

  // if (membership !== 'Free') {
    return (
      <section className="factor-progress-container">
        <div className="factor-progress-container-styles">
          <div
            style={fillerStyles}
            className={cn('factor-progress-styles', {
              isA: split >= 75,
              isB: split >= 50 && split < 75,
              isC: split >= 25 && split < 50,
              isD: split < 25,
            })}
          ></div>
        </div>
        <div className="factor-progress-info"></div>
      </section>
    );
  // }

  // if (membership === 'Free') {
  //   return (
  //     <section className="factor-progress-container">
  //       <div className="factor-progress-container-styles">
  //         <div
  //           style={freeStyles}
  //           className={cn('factor-progress-styles', {
  //             // isA: split >= 75,
  //             // isB: split >= 50 && split < 75,
  //             // isC: split >= 25 && split < 50,
  //             // isD: split < 25,
  //           })}
  //         ></div>
  //       </div>
  //       <div className="factor-progress-info"></div>
  //     </section>
  //   );
  // }
};

export default FactorProgress;
