import NavMenu from './NavMenu';
import { Link, useNavigate } from 'react-router-dom';
import { Nav, NavDropdown, Dropdown, NavItem } from 'react-bootstrap';
import { logout } from '../../store/features/session';
import { useDispatch } from 'react-redux';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import { useEffect } from 'react';

const Navbar = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  let persistToken = JSON.parse(localStorage.getItem('_uA'));

  useEffect(() => {}, [persistToken]);

  const logoutUser = () => {
    dispatch(logout({ token: persistToken?.access })).then((res) => {
      navigate('/login');
    });
  };

  const resetPasswordLink = () => {
    navigate('/change_password');
  };

  const dashboardLink = () => {
    navigate('/dashboard');
  };

  return (
    <>
      {user && (
        <nav className="main-nav">
          <div className="center-nav-holder">
            <Link to="/dashboard" className="logo-link">
              <div className="afx-logo">ArbiLex</div>
            </Link>
            <div className="nav-menu">
              <NavMenu />
            </div>
            <div className="user-menu-dropdown">
              <Nav defaultActiveKey="/home" as="ul">
                {width <= 744 ? (
                  <NavDropdown
                    title={`${user?.name.split(' ')[0][0]}`}
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item onClick={dashboardLink}>
                      Dashboard
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={resetPasswordLink}>
                      Reset Password
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={logoutUser}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <NavDropdown title={`${user?.name}`} id="basic-nav-dropdown">
                    <NavDropdown.Item onClick={dashboardLink}>
                      Dashboard
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={resetPasswordLink}>
                      Reset Password
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={logoutUser}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
              </Nav>
            </div>
          </div>
        </nav>
      )}

      {!user && (
        <nav className="main-nav">
          <div className="center-nav-holder">
            <Link to="/login" className="logo-link">
              <div className="afx-logo">ArbiLex</div>
            </Link>
            <div className="nav-menu">
              <NavMenu />
            </div>
            <div className="no-account-nav">
              {/* <p className="no-account-nav-title">Don't have an account?</p>
            <Link to="/signup" title={`Sign Up`} className="to-sign-up">
              Sign Up
            </Link> */}
            </div>
          </div>
        </nav>
      )}
    </>
  );
};

export default Navbar;
