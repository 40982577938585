const GET_PARTIES = 'cards/GET_PARTIES';
const SET_PARTIES = 'cards/SET_PARTIES';

//******ACTIONS******//

export const getParties = (parties) => ({
  type: GET_PARTIES,
  parties,
});
export const setParties = (party) => ({
  type: SET_PARTIES,
  party,
});

//******THUNKS******//

export const getPartiesThunk =
  ({ token }) =>
  async (dispatch) => {
    const res = await fetch(
      // `https://d1i2jq400lm6rf.cloudfront.net/card/parties`,
      // LOCAL TEST URLS for debugging:
      `http://127.0.0.1:8000/card/parties`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    //   .then(response => response.json())

    //   .then(data => setOptions(data.parties))

    if (res.ok) {
      let data = await res.json();
      dispatch(getParties(data));
      return data;
    }
  };

export const setPartiesThunk =
  ({ party }) =>
  async (dispatch) => {
    dispatch(setParties(party));
    return;
  };
//******REDUCER******//

const initialState = {
  party: null,
};

const partiesReducer = (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case GET_PARTIES:
      newState['parties'] = {};
      action?.parties?.parties.forEach((party, i) => {
        newState['parties'][i] = party;
      });
      return {
        ...newState,
      };
    case SET_PARTIES:
      newState['party'] = action.party;
      return {
        ...newState,
      };
    default:
      return state;
  }
};
export default partiesReducer;
