import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

const AllRequests = ({ persistToken }) => {
  const [allRequest, setAllRequest] = useState();
  const [currentRequest, setCurrentRequest] = useState(0);
  const requestLength = (string) => `${string.slice(0, 137)}...`;

  useEffect(() => {
    fetch(`https://d1i2jq400lm6rf.cloudfront.net/user/toggle-interest`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${persistToken?.access}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAllRequest(data?.user_interest);
      });
  }, []);

  return (
    <div className="dash-container">
      <div className="dash-comp-container">
        <div className="my-dash-title">All Requests</div>
        <div className="dash-title-divider"></div>
        <div className="dash-section-box">
          <div className="dash-section-holder">
            <div className="dash-reqs-container all-reqs-container">
              <div className="dash-request-container all-requests-container">
                {allRequest?.length > 0 &&
                  allRequest?.map((el, i) => (
                    <div
                      className={cn('dash-req-module all-req-module', {
                        selected_req: i === currentRequest,
                      })}
                      key={i}
                      onClick={() => setCurrentRequest(i)}
                    >
                      <div className="dash-req-mod-header">
                        <div className="dash-req-uuid">Request UUID# {} </div>
                      </div>
                      <div className="dash-req-title" to="/dashboard">
                        {el.card_title}
                      </div>
                    </div>
                  ))}
              </div>
              {allRequest?.length > 0 && (
                <div className="right-single-request">
                  <div className="dash-req-uuid">Request UUID# {} </div>

                  <div className="right-req-title">
                    {allRequest[currentRequest]?.card_title}
                  </div>
                  <div>{allRequest[currentRequest]?.text}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllRequests;
