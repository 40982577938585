import { useEffect, useState } from 'react';
import useToken from '../Hooks/useToken';
import { Link, useNavigate } from 'react-router-dom';


const Stats = ({}) => {
  const [stats, setStats] = useState('');
  const { persistToken } = useToken();
  const updatedOn = new Date(stats?.lastUpdateTime)?.toDateString();
  const navigate = useNavigate();

  const dateFormatFunction = (date) => {
    let newFormat = date?.split(' ');
    return `${newFormat[1]} ${newFormat[2]}, ${newFormat[3]} `;
  };

  const toListings = (e) => {
    e.preventDefault();
    navigate('/cases');
  };
  
  useEffect(() => {
    fetch(`https://d1i2jq400lm6rf.cloudfront.net/card/stats`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${persistToken?.access}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status_code < 400) {
          setStats(data);
          return;
        } else if (data.status_code === 401) {
          fetch(
            `https://d1i2jq400lm6rf.cloudfront.net/user/refresh?refresh_token=${persistToken?.refresh}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          ).then(window.location.reload(true));
        } else {
          return data;
        }
      });
  }, [persistToken]);

  return (
    <div className="dash-stats-container">
      <div className="dash-stats-list">
        <div className="stat-list-item">
          <svg className="dash-stats-icons dash-cases-svg" />
          {stats && (
            <div className="dash-stat-right">
              <div className="stat-description">Cases</div>
              <div className="stat-number">
                {stats.CardCount.toLocaleString()}
              </div>
            </div>
          )}
        </div>
        <div className="stat-list-item">
          <svg className="dash-stats-icons dash-parties-svg" />
          {stats && (
            <div className="dash-stat-right">
              <div className="stat-description">Parties</div>
              <div className="stat-number">
                {stats.partyCount.toLocaleString()}
              </div>
            </div>
          )}
        </div>
        <div className="stat-list-item">
          <svg className="dash-stats-icons dash-firms-svg" />
          {stats && (
            <div className="dash-stat-right">
              <div className="stat-description">Law Firms</div>
              <div className="stat-number">
                {stats.law_firm_count.toLocaleString()}
              </div>
            </div>
          )}
        </div>
        <div className="stat-list-item">
          <svg className="dash-stats-icons dash-lawyers-svg" />
          {stats && (
            <div className="dash-stat-right">
              <div className="stat-description">Lawyers</div>
              <div className="stat-number">
                {stats.lawyer_count.toLocaleString()}
              </div>
            </div>
          )}
        </div>

        {/* <div className="stat-list-item">
          <svg className="dash-stats-icons dash-clock-svg" />
          <div className="stat-date">{dateFormatFunction(updatedOn)}</div>
        </div> */}
      </div>

      {/* <div className="dash-mod-subtitle">
        These stats are a snapshot of AFX as of the current day.
      </div> */}
      <button onClick={(e) => toListings(e)} className="goto-listing">
        Go To AFX
      </button>
    </div>
  );
};

export default Stats;