import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h1 className="nf-title">404</h1>
      <h3 className="nf-subtitle">Ooops!...Page not found.</h3>
      <Link to="/" className="nf-link">
        Return home{' '}
      </Link>
    </div>
  );
};

export default NotFound;

