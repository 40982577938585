import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Redirect } from 'react-router';
import { resetPWThunk } from '../../store/features/endpoints';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useWindowDimensions from '../Hooks/useWindowDimensions';
// import { Link } from 'react-router-dom';

const ResetPassword = () => {
  let user = useSelector((state) => state?.session?.user?.id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { width, height } = useWindowDimensions();
  useEffect(() => {}, [width]);
  // if (user) {
  //   <Navigate to="/" replace={true} />;
  // }
  const notify = () =>
    toast('Please make sure OTP is correct', {
      hideProgressBar: true,
      theme: 'dark',
      className: 'single-case-toast password-reset-toast',
    });

    if(location?.state?.otp && location?.state?.email){
      return (
        <div className="auth-background">
          <div className="auth-container">
            {width <= 599 ? <svg className="arbilex-blue-logo-m" /> : <></>}
            <div className="auth-header">Select a new password</div>
            {}
            <Formik
              initialValues={{
                otp: location.state.otp,
                email: location.state.email,
                password: '',
                passwordConfirm: '',
              }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Email required*';
                }
                if (!values.otp) {
                  errors.otp = 'One time password required*';
                }
                if (!values.password) {
                  errors.password = 'Password required*';
                }
                if (values.passwordConfirm != values.password) {
                  errors.passwordConfirm = 'Passwords do not match*';
                }
                return errors;
              }}
              onSubmit={(values) => {
                // test();
                dispatch(
                  resetPWThunk({
                    email: values.email,
                    password: values.password,
                    otp: values.otp,
                  })
                )
                  .then((res) => {
                    if (res.status_code === 200) {
                      navigate('/success');
                    } else {
                      notify();
                    }
                    return;
                  })
                  .catch((err) => {
                    notify();
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="input-container">
                    <input
                      className="auth-input"
                      type="email"
                      name="email"
                      placeholder="Email*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      disabled
                    />
                    <p className="formik-error">{touched.email && errors.email}</p>
                  </div>
                  <div className="input-container">
                    <input
                      className="auth-input"
                      type="otp"
                      name="otp"
                      placeholder="One time password*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.otp}
                      disabled
                    />
                    <p className="formik-error">{touched.otp && errors.otp}</p>
                  </div>
                  <div className="input-container">
                    <input
                      className="auth-input"
                      type="password"
                      name="password"
                      placeholder="Password*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <p className="formik-error">
                      {touched.password && errors.password}
                    </p>
                  </div>
                  <div className="input-container">
                    <input
                      className="auth-input"
                      type="password"
                      name="passwordConfirm"
                      placeholder="Confirm Password*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.passwordConfirm}
                    />
                    <p className="formik-error">
                      {touched.passwordConfirm && errors.passwordConfirm}
                    </p>
                  </div>
                  {/* <div className="helper-text">
                      <span className="underline">Forgot your password?</span>
                    </div> */}
                  <button to="/" type="submit" className="submit-button">
                    Finalize new password
                  </button>
                  {/* <Link to="/" type="submit" className="submit-button">
                      Create new password
                    </Link> */}
                  {width <= 599 ? (
                    <div className="mobile-form-mailto">
                      Any questions? Reach us at{' '}
                      <a href="mailto: support@arbiLex.co">support@arbiLex.co</a>
                    </div>
                  ) : (
                    <></>
                  )}
                  <ToastContainer
                    className="case-toast"
                    progressClassName="case-toast-progress"
                  />
                </form>
              )}
            </Formik>
            <hr />
            {/* <div className="helper-text">
                Already have an account{' '}
                <Link to="/" className="blue-text">
                  Sign in
                </Link>
              </div> */}
            {width > 599 ? (
              <div className="mobile-form-mailto">
                Any questions? Reach us at{' '}
                <a href="mailto: support@arbiLex.co">support@arbiLex.co</a>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      );
    }

  // if (!user) {
  return (
    <div className="auth-background">
      <div className="auth-container">
        {width <= 599 ? <svg className="arbilex-blue-logo-m" /> : <></>}
        <div className="auth-header">Select a new password</div>
        <Formik
          initialValues={{
            otp: "",
            email: "",
            password: '',
            passwordConfirm: '',
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Email required*';
            }
            if (!values.otp) {
              errors.otp = 'One time password required*';
            }
            if (!values.password) {
              errors.password = 'Password required*';
            }
            if (values.passwordConfirm != values.password) {
              errors.passwordConfirm = 'Passwords do not match*';
            }
            return errors;
          }}
          onSubmit={(values) => {
            // test();
            dispatch(
              resetPWThunk({
                email: values.email,
                password: values.password,
                otp: values.otp,
              })
            )
              .then((res) => {
                if (res.status_code === 200) {
                  navigate('/success');
                } else {
                  notify();
                }
                return;
              })
              .catch((err) => {
                notify();
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="input-container">
                <input
                  className="auth-input"
                  type="email"
                  name="email"
                  placeholder="Email*"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <p className="formik-error">{touched.email && errors.email}</p>
              </div>
              <div className="input-container">
                <input
                  className="auth-input"
                  type="otp"
                  name="otp"
                  placeholder="One time password*"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.otp}
                />
                <p className="formik-error">{touched.otp && errors.otp}</p>
              </div>
              <div className="input-container">
                <input
                  className="auth-input"
                  type="password"
                  name="password"
                  placeholder="Password*"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <p className="formik-error">
                  {touched.password && errors.password}
                </p>
              </div>
              <div className="input-container">
                <input
                  className="auth-input"
                  type="password"
                  name="passwordConfirm"
                  placeholder="Confirm Password*"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.passwordConfirm}
                />
                <p className="formik-error">
                  {touched.passwordConfirm && errors.passwordConfirm}
                </p>
              </div>
              {/* <div className="helper-text">
                  <span className="underline">Forgot your password?</span>
                </div> */}
              <button to="/" type="submit" className="submit-button">
                Finalize new password
              </button>
              {/* <Link to="/" type="submit" className="submit-button">
                  Create new password
                </Link> */}
              {width <= 599 ? (
                <div className="mobile-form-mailto">
                  Any questions? Reach us at{' '}
                  <a href="mailto: support@arbiLex.co">support@arbiLex.co</a>
                </div>
              ) : (
                <></>
              )}
              <ToastContainer
                className="case-toast"
                progressClassName="case-toast-progress"
              />
            </form>
          )}
        </Formik>
        <hr />
        {/* <div className="helper-text">
            Already have an account{' '}
            <Link to="/" className="blue-text">
              Sign in
            </Link>
          </div> */}
        {width > 599 ? (
          <div className="mobile-form-mailto">
            Any questions? Reach us at{' '}
            <a href="mailto: support@arbiLex.co">support@arbiLex.co</a>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
  // }
};
export default ResetPassword;
