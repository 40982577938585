import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { MdClose } from 'react-icons/md';
import DoubleSlider from '../DoubleSlider';
import { NavDropdown, Dropdown, Form } from 'react-bootstrap';
import { getCardsCountThunk } from '../../store/features/count-new';
import Select from 'react-select';

function FilterModal({
  setShowModal,
  showModal,
  modalRef,
  setFStatus,
  setUStatus,
  setAPStatus,
  setAwardEnforcement,
  setITArbitration,
  setPatentCase,
  setAutoscrapedListing,
  setExclusiveListing,
  setStartDate,
  setEndDate,
  setVenueLocation,
  setMinClaimSize,
  setMaxClaimSize,
  todayDate,
  firstDate,
  sendFilters,
  bigObj,
  setBigObj,
  MIN_CLAIM_NUM,
  MAX_CLAIM_NUM,
  setActiveIndex,
  setARating,
  setBRating,
  setCRating,
  setDRating,
  count,
  stateFilters,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const closeFilterMenu = () => setShowModal(false);

  const stateParty = useSelector((state) => state?.parties.party);
  /**
   the following useState variables check the bigObj (applied user filters)
   therefore, when user opens modal they know which filters have been applied 
   */
  const [inputStartDate, setInputStartDate] = useState(bigObj?.start_date);
  const [inputEndDate, setInputEndDate] = useState(
    bigObj?.end_date || todayDate
  );

  // ********** Funding Status *********
  const [isAccepting, setIsAccepting] = useState(
    bigObj?.status?.includes('Green') || false
  );
  const [isUnknown, setIsUnknown] = useState(
    bigObj?.status?.includes('Gray') || false
  );
  const [isFunded, setIsFunded] = useState(
    bigObj?.status?.includes('Purple') || false
  );

  // ********** Listing Type *********
  const [isAutoscraped, setIsAutoscraped] = useState(
    bigObj?.listing_type?.includes('Autoscraped') || false
  );
  const [isExclusive, setIsExclusive] = useState(
    bigObj?.listing_type?.includes('Exclusive') || false
  );

  // ********** Case Type *********
  const [isPatent, setIsPatent] = useState(
    bigObj?.case_type?.includes('Patent') || false
  );
  const [isAwardEn, setIsAwardEn] = useState(
    bigObj?.case_type?.includes('Award Enforcement') || false
  );
  const [isInvestTreatyArb, setIsInvestTreatyArb] = useState(
    bigObj?.case_type?.includes('Investment Treaty Arbitration') || false
  );

  // ********** Rating *********
  const [isA, setIsA] = useState(bigObj?.rating?.includes('A') || false);
  const [isB, setIsB] = useState(bigObj?.rating?.includes('B') || false);
  const [isC, setIsC] = useState(bigObj?.rating?.includes('C') || false);
  const [isD, setIsD] = useState(bigObj?.rating?.includes('D') || false);

  // ********* Venue *********
  const [isVenue, setIsVenue] = useState(bigObj?.venue);

  // ********** Claim Size *********
  const [minClaim, setMinClaim] = useState(bigObj?.min_claim);
  const [maxClaim, setMaxClaim] = useState(bigObj?.max_claim);

  /**
   * Function that checks the state of each filter.
   * If it has changed it will update the parent useState variables.
   * SortFilter/index.js will update live filter object and wait for apply function execution.
   */
  const filterLogic = () => {
    //? SET STATE VARIABLES
    // DATE
    inputStartDate
      ? setStartDate(new Date(inputStartDate).toISOString().split('T')[0])
      : setStartDate('2018-01-01');
    inputEndDate
      ? setEndDate(new Date(inputEndDate)?.toISOString().split('T')[0])
      : setEndDate(todayDate);

    // CASE TYPE
    isAwardEn
      ? setAwardEnforcement('Award Enforcement')
      : setAwardEnforcement(null);

    isInvestTreatyArb
      ? setITArbitration('Investment Treaty Arbitration')
      : setITArbitration(null);

    isPatent ? setPatentCase('Patent') : setPatentCase(null);

    // RATING
    isA ? setARating('A') : setARating(null);
    isB ? setBRating('B') : setBRating(null);
    isC ? setCRating('C') : setCRating(null);
    isD ? setDRating('D') : setDRating(null);

    // LISTING TYPE
    isAutoscraped
      ? setAutoscrapedListing('Autoscraped')
      : setAutoscrapedListing(null);
    isExclusive ? setExclusiveListing('Exclusive') : setExclusiveListing(null);

    // STATUS
    isFunded ? setFStatus('Purple') : setFStatus(null);
    isUnknown ? setUStatus('Gray') : setUStatus(null);
    isAccepting ? setAPStatus('Green') : setAPStatus(null);

    // VENUE
    isVenue ? setVenueLocation(isVenue) : setVenueLocation(null);

    // CLAIM SIZE
    minClaim ? setMinClaimSize(minClaim) : setMinClaimSize(1);
    maxClaim ? setMaxClaimSize(maxClaim) : setMaxClaimSize(null);
  };

  const addVenueFilter = (opt) => {
    if (opt?.value === 'All Venues') {
      setIsVenue(null);
    } else {
      setIsVenue(opt?.value);
    }
    return;
  };

  useEffect(() => {
    filterLogic();
  }, [
    filterLogic,
    isFunded,
    isUnknown,
    isAccepting,
    setFStatus,
    setAwardEnforcement,
    setITArbitration,
    inputEndDate,
    inputStartDate,
    minClaim,
    maxClaim,
  ]);

  /*
  Venue array - MUST BE updated from the Google Drive venue list if new venue is added 
  must ask Raj 
  */
  let venueArray = [
    'All Venues',
    'Ad Hoc Arbitration',
    'Arizona District Court',
    'California Central District Court',
    'California Eastern District Court',
    'California Northern District Court',
    'California Southern District Court',
    'Colorado District Court',
    'Delaware District Court',
    'District of Columbia District Court',
    'Florida Middle District Court',
    'Florida Northern District Court',
    'Florida Southern District Court',
    'Georgia Northern District Court',
    'Hawaii District Court',
    'ICSID',
    'Idaho District Court',
    'Illinois Northern District Court',
    'Indiana Southern District Court',
    'Iowa Northern District Court',
    'Iowa Southern District Court',
    'Kentucky Western District Court',
    'Maryland District Court',
    'Massachusetts District Court',
    'Michigan Eastern District Court',
    'Michigan Western District Court',
    'Minnesota District Court',
    'Missouri Eastern District Court',
    'Nevada District Court',
    'New Jersey District Court',
    'New York Eastern District Court',
    'New York Northern District Court',
    'New York Southern District Court',
    'New York Western District Court',
    'North Carolina Eastern District Court',
    'North Carolina Western District Court',
    'Ohio Northern District Court',
    'Ohio Southern District Court',
    'Oklahoma Western District Court',
    'Oregon District Court',
    'PCA',
    'Pennsylvania Middle District Court',
    'Pennsylvania Western District Court',
    'Rhode Island District Court',
    'South Carolina District Court',
    'Texas Eastern District Court',
    'Texas Northern District Court',
    'Texas Southern District Court',
    'Texas Western District Court',
    'Virginia Eastern District Court',
    'Virginia Western District Court',
    'Washington Western District Court',
    'Wisconsin Eastern District Court',
    'Wisconsin Western District Court',
  ];

  const onChangeDate = (e) => {
    if (e.target.className === 'start-date') {
      e.target.value
        ? setInputStartDate(e.target.value)
        : setInputStartDate(firstDate);
    } else if (e.target.className === 'end-date') {
      e.target.value
        ? setInputEndDate(e.target.value)
        : setInputEndDate(todayDate);
    }
    return;
  };

  const clearFilters = (e) => {
    e.preventDefault();

    // status
    setIsAccepting(false);
    setIsUnknown(false);
    setIsFunded(false);
    // case type
    setIsAwardEn(false);
    setIsInvestTreatyArb(false);
    setIsPatent(false);
    // rating
    setIsA(false);
    setIsB(false);
    setIsC(false);
    setIsD(false);
    // listing type
    setIsAutoscraped(false);
    setIsExclusive(false);
    // filing date
    setInputStartDate(firstDate);
    setInputEndDate(todayDate);
    // claim size
    setMinClaim(MIN_CLAIM_NUM);
    setMaxClaim(MAX_CLAIM_NUM);
    // venue
    setIsVenue(null);
    setActiveIndex(0);

    setBigObj({
      status: [],
      listing_type: [],
      venue: '',
      case_type: [],
      min_claim: MIN_CLAIM_NUM,
      max_claim: MAX_CLAIM_NUM,
      start_date: firstDate,
      end_date: todayDate,
      order: 'desc',
      sort: 'date',
      rating: [],
      party: stateParty,
    });
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
    return;
  };

  const applyClose = (e) => {
    sendFilters(e);
    setShowModal(false);
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
    return;
  };

  return (
    <>
      {/* <button id="filter-button" onClick={handleShow}>
        Filters
        <div className="filter-svg"></div>
      </button> */}

      <Modal
        dialogClassName="modal-90w modal-mega-container"
        show={showModal}
        onHide={handleClose}
        size="xl"
      >
        <Modal.Body>
          <div className="filter-dropdown" ref={modalRef}>
            <Modal.Header>
              <MdClose
                className="modal-close-button"
                onClick={closeFilterMenu}
              />
              Filters
            </Modal.Header>
            <form id="filter-container">
              {/* RATING - SECTION DIVIDER */}
              <section className="case-type-section">
                <h3 className="filter-title">Rating</h3>
                <div className="case-type-container">
                  <label className="top-checkbox">
                    <input
                      type="checkbox"
                      onClick={(e) => setIsA(e.target.checked)}
                      // className="a-rating"
                      checked={isA}
                      readOnly
                    />
                    A
                  </label>
                  <div className="right-checkbox-spacing">
                    <label className="top-checkbox">
                      <input
                        type="checkbox"
                        onClick={(e) => setIsB(e.target.checked)}
                        // className="b-rating"
                        checked={isB}
                        readOnly
                      />
                      B
                    </label>
                  </div>
                </div>
                <div className="case-type-container">
                  <label className="top-checkbox">
                    <input
                      type="checkbox"
                      onClick={(e) => setIsC(e.target.checked)}
                      // className="c-rating"
                      checked={isC}
                      readOnly
                    />
                    C
                  </label>
                  <div className="right-checkbox-spacing">
                    <label className="top-checkbox">
                      <input
                        type="checkbox"
                        onClick={(e) => setIsD(e.target.checked)}
                        // className="d-rating"
                        checked={isD}
                        readOnly
                      />
                      D
                    </label>
                  </div>
                </div>
              </section>

              {/* END SECTION DIVIDER*/}
              <div className="filter-divider"></div>

              {/* CLAIM SIZE - SECTION DIVIDER */}
              <section className="case-type-section long-section">
                <h3 className="filter-title">Claim size</h3>
                <div
                  className="case-type-container"
                  id="filter-slider-container"
                >
                  {/* DOUBLE SLIDER  */}
                  {/* <label className="filter-range-container">
              min
              <input type="number" />
            </label>
            <div className="filter-range-separator">
              <div className="separator-hyphen-container">
                <span></span>
              </div>
            </div>
            <label className="filter-range-container">
              max
              <input type="number" />
            </label> 

            */}
                  <DoubleSlider
                    setMinClaim={setMinClaim}
                    setMaxClaim={setMaxClaim}
                    min={MIN_CLAIM_NUM}
                    max={MAX_CLAIM_NUM}
                    bigObj={bigObj}
                  />
                </div>
              </section>
              {/* END SECTION DIVIDER */}
              <div className="filter-divider"></div>

              {/* CASE TYPE - SECTION DIVIDER */}
              <section className="case-type-section">
                <h3 className="filter-title">Case type</h3>
                <div className="case-type-container">
                  <label className="top-checkbox">
                    <input
                      type="checkbox"
                      onClick={(e) => setIsAwardEn(e.target.checked)}
                      checked={isAwardEn}
                      readOnly
                    />
                    Award Enforcement
                  </label>
                  <div className="right-checkbox-spacing">
                    <label className="top-checkbox">
                      <input
                        type="checkbox"
                        onClick={(e) => setIsInvestTreatyArb(e.target.checked)}
                        checked={isInvestTreatyArb}
                        readOnly
                      />
                      Investment Treaty Arbitration
                    </label>
                  </div>
                </div>
                <div className="case-type-container">
                  <label className="top-checkbox patent-checkbox">
                    <input
                      type="checkbox"
                      onClick={(e) => setIsPatent(e.target.checked)}
                      checked={isPatent}
                      readOnly
                    />
                    Patent
                  </label>
                </div>
              </section>
              {/* END SECTION DIVIDER */}
              <div className="filter-divider"></div>
              {/* FILING DATE - SECTION DIVIDER */}
              <section className="case-type-section long-section">
                <h3 className="filter-title">Filing date</h3>
                <div className="case-type-container">
                  <label className="filter-range-container">
                    <p className="filter-section-subtitle">start date</p>
                    <div className="date-overlay">
                      <input
                        value={inputStartDate}
                        type="date"
                        className="start-date"
                        id="filter-date"
                        onChange={(e) => onChangeDate(e)}
                      />
                    </div>
                  </label>
                  <div className="filter-range-separator">
                    <div className="separator-hyphen-container">
                      <span></span>
                    </div>
                  </div>
                  <label className="filter-range-container">
                    <p className="filter-section-subtitle">end date</p>
                    <div className="date-overlay" placeholder="start">
                      <input
                        type="date"
                        className="end-date"
                        id="filter-date"
                        value={inputEndDate}
                        onChange={(e) => onChangeDate(e)}
                      />
                    </div>
                  </label>
                </div>
              </section>
              {/* END SECTION DIVIDER */}
              <div className="filter-divider"></div>
              {/* VENUE - SECTION DIVIDER */}
              <section className="case-type-section venue-filter">
                <h3 className="filter-title">Venue</h3>
                {/* <div className="case-type-container"> */}
                {/* <NavDropdown
                    title={isVenue ? isVenue : 'All Venues'}
                    id="filter-venue-dropdown"
                  >
                    {venueArray?.map((venue, i) => (
                      <NavDropdown.Item
                        eventKey={`4.${i}`}
                        key={i}
                        className="ICSID-v"
                        onClick={(e) => addVenueFilter(e)}
                      >
                        {venue}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown> */}
                <Select
                  options={venueArray.map((venue, i) => ({
                    label: venue,
                    value: venue,
                    key: { i },
                    className: 'ICSID-v',
                  }))}
                  onChange={(opt) => addVenueFilter(opt)}
                  value={{label: isVenue ? isVenue : 'All venues' }}
                />
                {/* </div> */}
              </section>
              {/* END SECTION DIVIDER*/}
              <div className="filter-divider"></div>
              {/* LISTING TYPE - SECTION DIVIDER */}
              <section className="case-type-section">
                <h3 className="filter-title">Listing type</h3>
                <div className="case-type-container">
                  <label>
                    <input
                      type="checkbox"
                      className="autoscraped-check"
                      onClick={(e) => setIsAutoscraped(e.target.checked)}
                      checked={isAutoscraped}
                      readOnly
                    />
                    {/* AUTOSCRAPED */}
                    Court Filings
                  </label>
                  <div className="right-checkbox-spacing">
                    <label>
                      <input
                        type="checkbox"
                        className="exclusive-check"
                        onClick={(e) => setIsExclusive(e.target.checked)}
                        checked={isExclusive}
                        readOnly
                      />
                      {/* EXCLUSIVE */}
                      Claimant Listed
                    </label>
                  </div>
                </div>
              </section>
              {/* END SECTION DIVIDER */}
              <div className="filter-divider"></div>
              {/* FUNDING STATUS - SECTION DIVIDER*/}
              <section className="case-type-section last-filter-section">
                <h3 className="filter-title">Funding status</h3>
                <div className="case-type-container">
                  <label>
                    <input
                      type="checkbox"
                      className="unknown-check"
                      onClick={(e) => setIsUnknown(e.target.checked)}
                      checked={isUnknown}
                      readOnly
                    />
                    Unknown
                  </label>
                  <div className="right-checkbox-spacing">
                    <label>
                      <input
                        type="checkbox"
                        className="accepting-check"
                        onClick={(e) => setIsAccepting(e.target.checked)}
                        checked={isAccepting}
                        readOnly
                      />
                      Accepting Proposal
                    </label>
                  </div>
                </div>
                <div className="case-type-container">
                  <label>
                    <input
                      type="checkbox"
                      className="funded-check"
                      onClick={(e) => setIsFunded(e.target.checked)}
                      checked={isFunded}
                      readOnly
                    />
                    Funded
                  </label>
                </div>
              </section>

              {/* END SECTION DIVIDER */}
              {/* <div className="filter-divider"></div> */}
            </form>
            <div id="dropdown-button-holder">
              <button
                className="filter-submit-button clear-button"
                onClick={(e) => clearFilters(e)}
              >
                Clear Filters
              </button>
              <button
                className="filter-submit-button apply-button"
                onClick={(e) => {
                  applyClose(e);
                }}
              >
                Show {count ? count : 0} Results
                {/* Show Results */}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default FilterModal;
// useEffect(() => {

//     let activeFilters = {
//       status: [],
//       listing_type: [],
//       venue: '',
//       case_type: [],
//       rating: [],
//       min_claim: MIN_CLAIM_NUM,
//       max_claim: MAX_CLAIM_NUM,
//       start_date: firstDate,
//       end_date: todayDate,
//       order: 'desc',
//       sort: 'date',
//       party: filter?.party,
//     };
//   //STATUS
//   if (isAccepting) activeFilters.status.push('Green');
//   if (isUnknown) activeFilters.status.push('Gray');
//   if (isFunded) activeFilters.status.push('Purple');

//   //CASE TYPE
//   if (isAwardEn) activeFilters.case_type.push('Award Enforcement');
//   if (isInvestTreatyArb)
//     activeFilters.case_type.push('Investment Treaty Arbitration');
//   if (isPatent) activeFilters.case_type.push('Patent');

//   //RATING
//   if (isA) activeFilters.rating.push('A');
//   if (isB) activeFilters.rating.push('B');
//   if (isC) activeFilters.rating.push('C');
//   if (isD) activeFilters.rating.push('D');

//   //LISTING TYPE
//   if (isAutoscraped) activeFilters.listing_type.push('Autoscraped');
//   if (isExclusive) activeFilters.listing_type.push('exclusive');

//   // DATE
//   if (inputEndDate) activeFilters.end_date = inputEndDate;
//   if (inputStartDate) activeFilters.start_date = inputStartDate;

//   // VENUE
//   if (isVenue) activeFilters.venue = isVenue;

//   //CLAIM SIZE
//   if (minClaim) activeFilters.min_claim = minClaim;
//   if (maxClaim) activeFilters.max_claim = maxClaim;
//   console.log('min claim:', minClaim);

//   dispatch(
//     getCardsCountThunk({
//       token: persistToken?.access,
//       filters: activeFilters,
//     })
//   )
//     .then((res) => {
//       if (res.status === 401) {
//         navigate('/login');
//         return;
//       } else if (res.status >= 400) {
//         navigate('/404-not-found');
//       }
//       return;
//     })
//     .catch((err) => console.error(err));
// }, [
//   // status
//   isAccepting,
//   isFunded,
//   isUnknown,
//   // case
//   isAwardEn,
//   isInvestTreatyArb,
//   isPatent,
//   // rating
//   isA,
//   isB,
//   isC,
//   isD,
//   // listing
//   isAutoscraped,
//   isExclusive,
//   // date
//   inputStartDate,
//   inputEndDate,
//   // venue
//   isVenue,
//   // claim
//   minClaim,
//   maxClaim,
//   bigObj,
// ]);
