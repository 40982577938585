const FORGOT_PASSWORD = 'endpoints/FORGOT_PASSWORD';

//******ACTIONS******//
export const forgotPW = (email) => ({
  type: FORGOT_PASSWORD,
  email,
});

export const forgotPWThunk =
  ({ email }) =>
  async (dispatch) => {
    const res = await fetch(
      'https://d1i2jq400lm6rf.cloudfront.net/user/forgot-password',
      // 'http://127.0.0.1:8000/user/forgot-password',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      }
    );

    if (res.ok) {
      const data = await res.json();
      return data;
    }
    return;
  };

export const resetPWThunk =
  ({ email, password, otp }) =>
  async (dispatch) => {
    const res = await fetch(
      `https://d1i2jq400lm6rf.cloudfront.net/user/reset-password`,
      // 'http://127.0.0.1:8000/user/forgot-password',
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          otp: otp,
          new_password: password,
        }),
      }
    );
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      return res;
    }
  };

const initialState = {};

//******REDUCER******//
const endpointsReducer = (state = initialState, action) => {};
export default endpointsReducer;
