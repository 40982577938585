import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useToken from './components/Hooks/useToken';
import { logout, persistUser } from './store/features/session';
import { useDispatch, useSelector } from 'react-redux';

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { persistToken } = useToken();
  let user = useSelector((state) => state?.session?.user);

  useEffect(() => {
    dispatch(persistUser(persistToken?.access)).then((res) => {
      if (res.status >= 400) {
        navigate('/login');
      }
    });
  }, [persistToken]);

  return children;
};

export default ProtectedRoute;
