import React, { Component } from 'react';
import { default as ReactSelect } from 'react-select';
import { components } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useLayoutEffect } from 'react';
import { filterCardsThunk } from '../../store/features/filter';
import useToken from '../Hooks/useToken';
import { setPartiesThunk } from '../../store/features/parties';
import { useNavigate } from 'react-router-dom';
import { colourStyles1, colourStyles2 } from './style.ts';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import Select from 'react-select';

export default function SearchBar({
  bigObj,
  setBigObj,
  MIN_CLAIM_NUM,
  MAX_CLAIM_NUM,
  firstDate,
  todayDate,
}) {
  const { width, height } = useWindowDimensions();
  const party = useSelector((state) => state?.filter?.party);
  const [options, setOptions] = useState([]);
  const [optionSelected, setOptionSelected] = useState(null);
  const dispatch = useDispatch();
  const { persistToken } = useToken();
  const navigate = useNavigate();

  const [flag, setFlag] = useState(0);

  useEffect(() => {
    fetch(
      `https://d1i2jq400lm6rf.cloudfront.net/card/parties`,
      // LOCAL TEST URLS for debugging:
      // `http://127.0.0.1:8000/card/parties`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${persistToken?.access}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => setOptions(data?.parties));
  }, []);

  const [activeFilters, setActiveFilters] = useState({
    status: [],
    listing_type: [],
    rating: [],
    venue: '',
    case_type: [],
    min_claim: MIN_CLAIM_NUM,
    max_claim: MAX_CLAIM_NUM,
    start_date: firstDate,
    end_date: todayDate,
    order: bigObj?.order,
    sort: bigObj?.sort,
    party: null,
  });

  const handleChange = (newValue, actionMeta) => {
    // if(actionMeta.action === 'select-option'){
    //   setSelect(newValue.value)
    // }else
    // setSelect(newValue?.value);
    setActiveFilters((prevState) => ({
      ...prevState,
      party: newValue?.value,
    }));
    setFlag(flag + 1);
    return;
  };

  useEffect(() => {
    setBigObj(activeFilters);
    dispatch(setPartiesThunk({ party: activeFilters.party }));
  }, [activeFilters, setBigObj]);

  // const onInputChange = (inputValue, { action, prevInputValue }) => {
  //     if (action === 'input-change') return inputValue;
  //     console.log(inputValue)
  //     console.log(action)
  //     console.log(prevInputValue)
  //     return prevInputValue;
  //   };

  // useEffect(() => {
  //   dispatch(
  //     getCardsThunk({ token: persistToken?.access, filters: activeFilters })
  //   )
  //     .then((res) => {
  //       if (res.status === 401) {
  //         navigate('/login');
  //         return;
  //       } else if (res.status >= 400) {
  //         navigate('/404-not-found');
  //       }
  //       return;
  //     })
  //     .catch((err) => console.error(err));
  // }, [activeFilters]);

  useEffect(() => {
    dispatch(filterCardsThunk({ filters: activeFilters }));
    // dispatch(
    //   getCardsThunk({ token: persistToken?.access, filters: activeFilters })
    // )
    //   .then((res) => {
    //     if (res.status === 401) {
    //       navigate('/login');
    //       return;
    //     } else if (res.status >= 400) {
    //       navigate('/404-not-found');
    //     }
    //     return;
    //   })
    //   .catch((err) => console.error(err));
  }, [bigObj]);

  // console.log('select', select);
  let PartyComponent = (
    <span className="search-div">
      <Select
        id="search-party"
        placeholder={
          width < 380 ? 'Search by party ...' : 'Search by party name . . .'
        }
        // placeholder={'  Search by party name   . . .        '}
        defaultValue={'All Parties'}
        options={options}
        isClearable={true}
        isSearchable={true}
        styles={colourStyles2}
        closeMenuOnSelect={true}
        onChange={handleChange}
      />
    </span>
  );

  useEffect(() => {}, [width]);

  // if (width <= 599) {
  //     return PartyComponent1
  // } else if((width >= 601 && width <= 766)){
  //   return PartyComponent2
  // } else
  return PartyComponent;
}

{
  /* <Select
            className="select"
            closeMenuOnSelect={false}
            defaultValue={[]}
            isMulti
            options={options}
            //   styles={colourStyles}
            onChange={(e)=> handleChange(e)}
            styles={colourStyles}
            /> */
}
{
  /* <p 
                className="search-button"
                onClick={(e) => handleSearch(e)}
                style={{"borderRadius":'10px', float:'right', position:'absolute'}}>
                <i class="fa fa-search"></i>
            </p> */
}

// export  function A ({
//   }) {
//     return (
//         <span
//           className="d-inline-block"
//           data-toggle="popover"
//           data-trigger="focus"
//           data-content="Select Party Names"
//         >
//           <ReactSelect
//             options={colourOptions}
//             isMulti
//             closeMenuOnSelect={false}
//             hideSelectedOptions={false}
//             components={{
//               Option
//             }}
//             onChange={(e) => handleChange(e)}
//             allowSelectAll={true}
//             value={optionSelected}
//           />
//           <button
//                 className="filter-submit-button clear-button"
//                 onClick={(e) => handleSearch(e)}
//               >
//                 Search
//               </button>
//         </span>
//       );

// }
