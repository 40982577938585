import * as d3_old from 'd3-old';
import cn from 'classnames';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from '../Hooks/useWindowDimensions';

const CaseDonut = ({ total, data, tab }) => {
  const { width, height } = useWindowDimensions();
  let WIDTH = 290;
  let HEIGHT = 290;
  let containerWidth = width < 905 ? 320 : 330;
  let containerHeight = width < 905 ? 320 : 330;

  const newData = [];
  if (tab === 1) {
    for (let key in data?.rating) {
      newData.push({
        rating: key,
        value: data?.rating[key],
      });
    }
  } else if (tab === 2) {
    for (let key in data?.tags) {
      newData.push({
        tag: key,
        value: data?.tags[key],
      });
    }
  } else if (tab === 3) {
    for (let key in data?.case_type) {
      newData.push({
        tag: key,
        value: data?.case_type[key],
      });
    }
  }

  // Set color range
  const colors1 = d3_old.scale
    .ordinal()
    .range(['#2F5BEA', '#0090FC', '#5FBCFF', '#B1E4FF']);

  const colors2 = d3_old.scale
    .ordinal()
    .range([
      '#1f3509',
      '#245e03',
      '#448310',
      '#66962d',
      '#a3c744',
      '#c5da95',
      '#E3F0BD',
    ]);
  // const colors2 = d3_old.scale
  //   .ordinal()
  //   .range([
  //     '#EDF8FB',
  //     '#CCECE6',
  //     '#99D8C9',
  //     '#66C2A4',
  //     '#41AE76',
  //     '#238B45',
  //     '#005824',
  //   ]);

  const colors3 = d3_old.scale
    .ordinal()
    .range([
      '#FB49B0',
      '#4053D3',
      '#00BEFF',
      '#00B25D',
      '#CACACA',
      '#DDB310',
      '#B51D14',
    ]);

  // Select SVG element and remove any existing elements
  // d3_old.select('.my-brk-donut').remove();
  d3_old.select('.my-brk-donut').select('g').remove();

  // Create SVG
  const svg = d3_old
    .select('.my-brk-donut')
    .attr('viewBox', `0 0 ${containerWidth} ${containerHeight}`)
    // .attr(
    //   'viewBox',
    //   `${-(containerWidth - WIDTH) / 2} ${
    //     -(containerHeight - HEIGHT) / 4
    //   } ${containerWidth} ${containerHeight}`
    // )
    // .attr('WIDTH', containerWidth)
    // .attr('HEIGHT', containerHeight)
    // .append('svg')
    .append('g')
    .attr('class', 'donut-box')
    // .attr('height ', '180')
    // .attr('width', '180')
    .attr('preserveAspectRatio', 'xMinYMin meet')
    //half of viewbox
    .attr(
      'transform',
      `translate( ${containerWidth / 2}, ${containerHeight / 2})`
    );

  // svg
  // .select('g')
  // .data(data)
  // .enter()
  // .append('path')
  // .attr('text-anchor', 'middle');

  svg
    .append('text')
    .style('fill', '#0e0a0a')
    .style('font-family', 'DM Sans')
    .style('font-size', WIDTH <= 120 ? '36px' : '36px')
    .attr('class', 'donut-inside-text')
    .attr('line-HEIGHT', WIDTH <= 120 ? '48px' : '48px')
    .attr('text-anchor', 'middle')
    .attr('y', WIDTH <= 120 ? '.2rem' : '.4rem')
    .style('font-weight', 500)
    .text(`${total} `);

  svg
    .append('text')
    .style('fill', '#0e0a0a')
    .style('font-family', 'DM Sans')
    .style('font-size', WIDTH <= 120 ? '20px' : '20px')
    .attr('class', 'inside')
    .attr('line-HEIGHT', WIDTH <= 120 ? '26px' : '26px')
    .attr('text-anchor', 'middle')
    .attr('margin-top', '10px')
    .attr('y', WIDTH <= 120 ? '.2rem' : '25px')
    .style('font-weight', 400)
    .text(`cases`);

  // Generate the pie/donut element
  const pieGen =
    tab === 1 || tab === 2
      ? d3_old.layout
          .pie()
          .value((d) => d.value)
          .sort(null)
      : d3_old.layout.pie().value((d) => d.value);

  // Generate the arcs elements
  const arcGen = d3_old.svg
    .arc()
    .outerRadius(WIDTH / 2)
    .innerRadius(WIDTH / 3.4);
  // Add data to arcs element
  const arcs = svg
    .selectAll()
    .data(
      pieGen(
        tab === 1 ? newData : tab === 2 ? newData : tab === 3 ? newData : null
      )
    )
    .enter();

  arcs
    .append('path')
    .attr('class', 'donut-paths')
    // .attr('stroke', 'white')
    // .style('stroke-WIDTH', '2px')
    .attr('d', arcGen)
    .attr('WIDTH', WIDTH <= 120 ? 120 : 260)
    .style('fill', (d) =>
      tab === 1
        ? colors1(d.data.rating)
        : tab === 2
        ? colors2(d.data.tag)
        : tab === 3
        ? colors3(d.data.tag)
        : null
    )
    .append('svg:defs')

    //CHANGE
    // let meter = svg.append('g').attr('class', 'funds-allocated-meter');

    // meter
    //   .append('path')
    //   .attr('class', 'background')
    //   .attr('d', arc.endAngle(twoPi));

    // let foreground = meter.append('path').attr(
    //   'class',
    //   cn({
    //     bkd_a: 35,
    //     bkd_b: 132,
    //     bkd_c: 23,
    //     bkd_d: 25,
    //   })
    // );

    // let calcPercentage = d3_old.interpolate(progress, 50 / 100);

    // d3_old.transition()
    //   .duration(0)
    //   .tween('progress', () => {
    //     return (t) => {
    //       progress = calcPercentage(t);
    //       foreground.attr('d', arc.endAngle(twoPi * progress));
    //     };
    //   });

    .append('svg:filter')
    .attr('id', 'inset-shadow')

    .append('svg:feOffset')
    .attr({
      dx: 1,
      dy: 1,
    })

    .append('svg:feGaussianBlur')
    .attr({
      stdDeviation: 4,
      result: 'offset-blur',
    })

    /* Invert drop shadow to make an inset shadow */
    .append('svg:feComposite')
    .attr({
      operator: 'out',
      in: 'SourceGraphic',
      in2: 'offset-blur',
      result: 'inverse',
    })

    /* Cut color inside shadow */
    .append('svg:feFlood')
    .attr({
      'flood-color': '#ff0000a9',
      'flood-opacity': 0.3,
      result: 'color',
    })

    .append('svg:feComposite')
    .attr({
      operator: 'in',
      in: 'color',
      in2: 'inverse',
      result: 'shadow',
    })

    // appends filter to the svg elements
    .append('svg:feComposite')
    .attr({
      operator: 'over',
      in: 'shadow',
      in2: 'SourceGraphic',
    });

  return (
    <svg className="my-brk-donut">
      {/* <div className="dash-right-donut">hello</div> */}
    </svg>
  );
};

export default CaseDonut;
