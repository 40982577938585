import ProgressBar from "./ProgressBar";

const ExclusiveCard = ({ props }) => {
  return (
    <div id="exclusive-bottom-container">
      <p className="days-left"> x days left</p>
      <ProgressBar props={props} />
    </div>
  );
};
export default ExclusiveCard;
