const ProgressBar = ({ props }) => {
  const fillerStyles = {
    width: `${props.completed}%`,
  };

  return (
    <div className="container-styles">
      <div style={fillerStyles} className="filler-styles"></div>
    </div>
  );
};

export default ProgressBar;
