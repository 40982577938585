import CaseDonut from './CaseDonut';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import useToken from '../Hooks/useToken';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import { Nav, NavDropdown, Dropdown, NavItem } from 'react-bootstrap';

const Breakdown = ({}) => {
  const { persistToken } = useToken();
  const [donutData, setDonutData] = useState();
  const [donutTab, setDonutTab] = useState(1);
  const [currentTab, setCurrentTab] = useState();
  const [currentTotal, setCurrentTotal] = useState();
  const [donutType, setDonutType] = useState('rating');
  const [caseTypeObject, setCaseTypeObject] = useState();
  const { width, height } = useWindowDimensions();

  const WIDTH_BREAK = 905;

  useEffect(() => {
    fetch(`https://d1i2jq400lm6rf.cloudfront.net/card/summary`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${persistToken?.access}`,
      },
    })
      .then((res) => {
        if (res.status < 400) {
          return res.json();
        } else if (res.status_code === 401) {
          fetch(
            `https://d1i2jq400lm6rf.cloudfront.net/user/refresh?refresh_token=${persistToken?.refresh}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          ).then(window.location.reload(true));
        } else {
          console.error('Error', res.status);
          return;
        }
      })
      .then((data) => {
        let tagKeys = Object.keys(data.data.tags);
        if (tagKeys.includes("Higher Uncertainty, Claim Size Unknown")) {
          if (tagKeys.includes("Claim Size Unknown")) {
            data.data.tags["Claim Size Unknown"] += data.data.tags["Higher Uncertainty, Claim Size Unknown"];
            delete data.data.tags["Higher Uncertainty, Claim Size Unknown"];
          } else {
            data.data.tags["Claim Size Unknown"] = data.data.tags["Higher Uncertainty, Claim Size Unknown"];
            delete data.data.tags["Higher Uncertainty, Claim Size Unknown"];
          }
        }
        setDonutData(data);
      });
  }, [persistToken]);

  useEffect(() => {
    if (donutData) {
      if (donutTab === 1) {
        setCurrentTotal(totalArray(donutData?.data?.rating));
        setDonutType('rating');
      } else if (donutTab === 2) {
        setCurrentTotal(totalArray(donutData?.data?.rating));
        setDonutType('claim');
      } else if (donutTab === 3) {
        setCurrentTotal(totalArray(donutData?.data?.rating));
        setDonutType('case');
      }
    }
    sortedValues();
  }, [donutTab, donutData]);

  const tabSelect = (e, num) => {
    e.stopPropagation();
    setDonutTab(num);
    return;
  };

  const totalArray = (data) => {
    let num = Object.values(data).reduce((acc, curr) => acc + curr);
    return num;
  };

  // Function to get sorted values can then be used to auto render highest to lowest
  let sortedValues = () => {
    let data = donutData?.data?.case_type;
    let newObj = {};
    if (data) {
      let values = Object.values(data).sort((a, b) => a - b);
      for (let i = values.length - 1; i >= 0; i--) {
        let pairs = Object.keys(data).find((key) => data[key] === values[i]);
        newObj[pairs] = values[i];
      }
    }
    setCaseTypeObject(newObj);
  };
  return (
    <div className="dash-breakdown-container">
      <div className="dash-mod-title case-donut-title-holder">
        <div className='case-donut-title'>Case Breakdown</div>
        {width >= WIDTH_BREAK ? (
          <div className="donut-tab-container">
            <div className="donut-tab-holder">
              <button
                className={cn('donut-tab-title', {
                  donut_tab_title: donutTab === 1,
                })}
                onClick={(e) => tabSelect(e, 1)}
              >
                Rating
              </button>
              <div
                className={cn('tab-bottom-highlight', {
                  donut_tab_ht: donutTab === 1,
                })}
              ></div>
            </div>
            <div className="donut-tab-holder">
              <button
                className={cn('donut-tab-title', {
                  donut_tab_title: donutTab === 2,
                })}
                onClick={(e) => tabSelect(e, 2)}
              >
                Claim Size
              </button>
              <div
                className={cn('tab-bottom-highlight', {
                  donut_tab_ht: donutTab === 2,
                })}
              ></div>
            </div>
            <div className="donut-tab-holder">
              <button
                className={cn('donut-tab-title', {
                  donut_tab_title: donutTab === 3,
                })}
                onClick={(e) => tabSelect(e, 3)}
              >
                Case Type
              </button>
              <div
                className={cn('tab-bottom-highlight', {
                  donut_tab_ht: donutTab === 3,
                })}
              ></div>
            </div>
          </div>
        ) : (
          // <div className="donut-select-drop">
          <NavDropdown
            title="Rating"
            className="donut-select-drop"
            id="breakdown-link-container"
          >
            <NavDropdown.Item
              id="breakdown-drop-link"
              onClick={(e) => tabSelect(e, 1)}
            >
              Rating
            </NavDropdown.Item>
            <NavDropdown.Item onClick={(e) => tabSelect(e, 2)}>
              Claim Size
            </NavDropdown.Item>
            <NavDropdown.Item onClick={(e) => tabSelect(e, 3)}>
              Case Type
            </NavDropdown.Item>
          </NavDropdown>
          // </div>
        )}
      </div>
      {width >= 905 && <div className="dash-brk-divider"></div>}
      <div
        className={cn('dash-donut-holder', {
          dash_donut_holder_amnt: donutTab === 1,
          dash_donut_holder_claim: donutTab === 2,
          dash_donut_holder_case: donutTab === 3,
        })}
      >
        {donutData && (
          // <CaseDonut total={totalArray(donutData?.rating)} />
          <CaseDonut
            total={currentTotal}
            data={donutData?.data}
            tab={donutTab}
          />
        )}
        {/* {donutData && donutTab === 2 && <CaseDonut total={56} />} */}
        <div className={cn("donut-stats-container", {
          dsc_tab1: donutTab === 1,
          dsc_tab2: donutTab === 2,
          dsc_tab3: donutTab === 3,
        })}>
          {/* GREATER THAN 905 STRUCTURE CHANGE */}
          {donutData &&
            donutTab === 1 &&
            // width >= 905 &&
            Object.entries(donutData?.data?.rating)?.map((el, i) => (
              <div className="dash-donut-rows" key={i}>
                <div
                  className={cn('dash-donut-circle', {
                    donut_rating_a: el[0] === 'A',
                    donut_rating_b: el[0] === 'B',
                    donut_rating_c: el[0] === 'C',
                    donut_rating_d: el[0] === 'D',
                  })}
                ></div>
                <div className="dash-donut-letter">{el[0]+':'}</div>
                <div className="dash-donut-number">{el[1].toLocaleString()}</div>
              </div>
            ))}
          {donutData && donutTab === 2 && (
            // width >= 905 &&
            // Object.entries(donutData?.tags)?.map((el, i) => (
            <>
              <div className="dash-donut-rows">
                <div
                  className={cn('dash-donut-circle', {
                    donut_1bplus_claim: donutData?.data?.tags['$1B+ Claim'],
                  })}
                ></div>
                <div className="dash-donut-letter">$1B+: {}</div>
                <div className="dash-donut-number">
                  {donutData?.data?.tags['$1B+ Claim']}
                </div>
              </div>
              <div className="dash-donut-rows">
                <div
                  className={cn('dash-donut-circle', {
                    donut_1b_claim: donutData?.data?.tags['$500M-1B Claim'],
                  })}
                ></div>
                <div className="dash-donut-letter">$500M-1B: {}</div>
                <div className="dash-donut-number">
                  {donutData?.data?.tags['$500M-1B Claim']}
                </div>
              </div>
              <div className="dash-donut-rows">
                <div
                  className={cn('dash-donut-circle', {
                    donut_500_claim: donutData?.data?.tags['$100-500M Claim'],
                  })}
                ></div>
                <div className="dash-donut-letter">$100-500M: {}</div>
                <div className="dash-donut-number">
                  {donutData?.data?.tags['$100-500M Claim']}
                </div>
              </div>
              <div className="dash-donut-rows">
                <div
                  className={cn('dash-donut-circle', {
                    donut_100_claim: donutData?.data?.tags['$50-100M Claim'],
                  })}
                ></div>
                <div className="dash-donut-letter">$50-100M: {}</div>
                <div className="dash-donut-number">
                  {donutData?.data?.tags['$50-100M Claim']}
                </div>
              </div>
              <div className="dash-donut-rows">
                <div
                  className={cn('dash-donut-circle', {
                    donut_50_claim: donutData?.data?.tags['$10-50M Claim'],
                  })}
                ></div>
                <div className="dash-donut-letter">$10-50M: {}</div>
                <div className="dash-donut-number">
                  {donutData?.data?.tags['$10-50M Claim']}
                </div>
              </div>
              <div className="dash-donut-rows">
                <div
                  className={cn('dash-donut-circle', {
                    donut_10_claim: donutData?.data?.tags['$1-10M Claim'],
                  })}
                ></div>
                <div className="dash-donut-letter">$1-10M:</div>
                <div className="dash-donut-number">
                  {donutData?.data?.tags['$1-10M Claim']}
                </div>
              </div>
              <div className="dash-donut-rows">
                <div
                  className={cn('dash-donut-circle', {
                    donut_unk_claim:
                      donutData?.data?.tags['Claim Size Unknown'],
                  })}
                ></div>
                <div className="dash-donut-letter">Unknown: {}</div>
                <div className="dash-donut-number">
                  {donutData?.data?.tags['Claim Size Unknown']}
                </div>
              </div>
            </>
          )}
          {donutData &&
            donutTab === 3 &&
            // width >= 905 &&
            Object.entries(caseTypeObject)?.map((el, i) => (
              <div className="dash-donut-rows" key={i}>
                <div
                  className={cn('dash-donut-circle', {
                    donut_case_p: el[0] === 'Patent',
                    donut_case_ae: el[0] === 'Award Enforcement',
                    donut_case_ita: el[0] === 'Investment Treaty Arbitration',
                  })}
                ></div>
                <div className="dash-donut-letter">{el[0]+':'}</div>
                <div className="dash-donut-number">{el[1]}</div>
              </div>
            ))}
          {/* LESS THAN 905 STRUCTURE CHANGE */}
          {/* {donutData &&
            donutTab === 1 &&
            width < 905 &&
            Object.entries(donutData?.data?.rating)?.map((el, i) => (
              <div className="dash-donut-rows" key={i}>
                <div
                  className={cn('dash-donut-circle', {
                    donut_rating_a: el[0] === 'A',
                    donut_rating_b: el[0] === 'B',
                    donut_rating_c: el[0] === 'C',
                    donut_rating_d: el[0] === 'D',
                  })}
                ></div>
                <div className="dash-donut-letter">{el[0]}</div>
                <div className="dash-donut-number">{el[1]}</div>
              </div>
            ))} */}
        </div>
      </div>
    </div>
  );
};

export default Breakdown;
