import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { forgotPWThunk } from '../../store/features/endpoints';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useWindowDimensions from '../Hooks/useWindowDimensions';

const ForgotPassword = () => {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  
  const notify = () =>
    toast('A one time password has been sent to your email', {
      hideProgressBar: true,
      theme: 'dark',
      className: 'single-case-toast',
    });

  useEffect(() => {}, width);
  return (
    <div className="auth-background">
      <div className="auth-container">
        {width > 599 ? (
          <div className="auth-header">Reset Password</div>
        ) : (
          <>
            <svg className="arbilex-blue-logo-m" />
            <div className="auth-header">Forgot your password?</div>
            <div className="forgot-pass-text">
              Enter the email address associated with your account and we’ll
              send you an email with instructions
            </div>
          </>
        )}
        <Formik
          initialValues={{ email: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Email required*';
            }
            return errors;
          }}
          onSubmit={(values) => {
            dispatch(forgotPWThunk({ email: values.email }));
            notify();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="input-container">
                {/* {width < 600 ? <p className="input-labels-m">Email</p> : <></>} */}
                <input
                  className="auth-input"
                  type="email"
                  name="email"
                  placeholder="Email*"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <p className="formik-error">{touched.email && errors.email}</p>
              </div>
              {width > 599 ? (
                <button type="submit" className="submit-button">
                  Request Password Reset
                </button>
              ) : (
                <>
                  <button type="submit" className="submit-button">
                    Send password reset instructions
                  </button>
                  <div className="mobile-form-mailto">
                    Any questions? Reach us at{' '}
                    <a href="mailto: support@arbiLex.co">support@arbiLex.co</a>
                  </div>
                </>
              )}
              <ToastContainer
                className="case-toast"
                progressClassName="case-toast-progress"
              />
            </form>
          )}
        </Formik>
        <hr />
        {/* <div className="helper-text">
          Don't have an account yet?{' '}
          {/* <Link to="signup" className="blue-text">
            Sign up
          </Link>
        </div> */}
        {width > 599 ? (
          <div className="mobile-form-mailto">
            Any questions? Reach us at{' '}
            <a href="mailto: support@arbiLex.co">support@arbiLex.co</a>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default ForgotPassword;
