const GET_FACTORS = 'factors/GET_FACTORS';
const ADD_FACTORS = 'factors/ADD_FACTORS';

//******ACTIONS******//
export const getFactors = (factor) => ({
  type: GET_FACTORS,
  factor,
});
export const addFactors = (factor) => ({
  type: ADD_FACTORS,
  factor,
});

//******THUNKS******//

export const getFactorsThunk = (factor) => async (dispatch) => {
  dispatch(getFactors(factor));
  return factor;
};

export const addFactorsThunk = (factor) => async (dispatch) => {
  // CHECK IF ADD TO 100
  dispatch(addFactors(factor));
  return factor;
};

//******REDUCER******//

const initialState = {};

const factorsReducer = (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case GET_FACTORS:
      for (const [key, value] of Object.entries(action.factor)) {
        newState[key] = value;
      }
      return {
        ...newState,
      };
    case ADD_FACTORS:
      for (const [key, value] of Object.entries(action.factor)) {
        newState[key] = value;
      }
      return {
        ...newState,
      };
    default:
      return state;
  }
};
export default factorsReducer;
