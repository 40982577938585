import { Nav, Tab } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

const NavMenu = () => {
  const location = useLocation();

  // Determine the current key based on the route
  let currentKey = '';
  if (location.pathname.startsWith('/dashboard')) {
    currentKey = 'first';
  } else if (location.pathname.startsWith('/cases')) {
    currentKey = 'second';
  }

  return (
    <Nav defaultActiveKey="/" as="ul">
      <Tab.Container id="left-tabs-example" activeKey={currentKey}>
        <Nav variant="pills" className="flex-row">
          <Nav.Item>
            <Nav.Link
              as={Link}
              to="/dashboard"
              eventKey="first"
              className="nav-tab"
            >
              Dashboard
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={Link}
              to="/cases"
              eventKey="second"
              className="nav-tab"
            >
              AFX
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Tab.Container>
    </Nav>
  );
};

export default NavMenu;