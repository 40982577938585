// Privacy Modal
const modalPrivacy = () => {
  return (
    <>
      <div className="modal-title-element">Privacy Policy</div>
      <div className="modal-body-element policy-modal">
        Effective date: 08/05/2019
        <br />
        <br />
        We at ArbiLex know you care about how your personal information is used
        and shared, and we take your privacy seriously. Please read the
        following to learn more about our Privacy Policy.
        <strong>
          {' '}
          By using or accessing the Services in any manner, you acknowledge that
          you accept the practices and policies outlined in this Privacy Policy,
          and you hereby consent that we will collect, use, and share your
          information in the following ways.
        </strong>
        <br />
        <br />
        Remember that your use of ArbiLex's Services is at all times subject to
        our Terms of Use, which incorporates this Privacy Policy. Any terms we
        use in this Policy without defining them have the definitions given to
        them in the Terms of Use.
        <br />
        <br />
        <strong>
          <u>What does this Privacy Policy cover?</u>
        </strong>
        <br />
        <br />
        This Privacy Policy covers our treatment of personally identifiable
        information (“Personal Data”) that we gather when you are accessing or
        using our Services, but not to the practices of companies we don’t own
        or control, or people that we don’t manage. We gather various types of
        Personal Data from our users, as explained in more detail below, and we
        use this Personal Data internally in connection with our Services,
        including to personalize, provide, and improve our services, to contact
        you and allow other users to contact you, to fulfill your requests for
        certain products and services, and to analyze how you use the Services.
        In certain cases, we may also share some Personal Data with third
        parties, but only as described below.
        <br />
        <br />
        <strong>
          <u>What Personal Data does ArbiLex collect?</u>
        </strong>
        <br />
        <br />
        We collect Personal Data about you when you provide such information
        directly to us, such as when ArbiLex manually creates an account for you
        to use our Services, when third parties such as our business partners or
        service providers provide us with Personal Data about you, or when
        Personal Data about you is automatically collected in connection with
        your use of our Services.
        <br />
        <br />
        <u>
          <i>Personal Data You Provide to Us:</i>
        </u>
        <br />
        <br />
        We receive and store any information you provide to us. For example, we
        may collect Personal Data such as the following:
        <ul>
          <li>First and last name</li>
          <li>Username</li>
          <li>Password</li>
          <li>Email address</li>
          <li>Occupation and title</li>
          <li>
            Employer, company, association or other institutional affiliation
          </li>
        </ul>
        Please note that certain information may be required to take advantage
        of some of our features.
        <br />
        <br />
        <u>
          <i>Information Collected Automatically</i>
        </u>
        <br />
        <br />
        Whenever you interact with our Services, we automatically receive and
        record information on our server logs from your browser or device, which
        may include the following:
        <ul>
          <li>IP address</li>
          <li>Device identifiers and information</li>
          <li>Web browser information</li>
          <li>Page view statistics</li>
          <li>Browsing history</li>
          <li>Usage information</li>
          <li>
            Transaction information (e.g. transaction amount, date and time such
            transaction occurred)
          </li>
          <li>Cookies — for more information, see below</li>
          <li>Location information (e.g. IP address, zip code)</li>
          <li>
            Log data (e.g. access times, hardware and software information)
          </li>
        </ul>
        <br />
        <br />
        Additional Information about Cookies:
        <br />
        <br />
        “Cookies” are identifiers we transfer to your browser or device that
        allow us to recognize your browser or device and tell us how and when
        pages and features in our Services are visited and by how many people.
        You may be able to change the preferences on your browser or device to
        prevent or limit your device’s acceptance of cookies, but this may
        prevent you from taking advantage of some of our features.
        <br />
        <br />
        Our service providers may also transmit cookies to your browser or
        device in certain situations, such as when you click on a link to a
        third party website or service. This Privacy Policy does not cover the
        use of cookies by any third parties, and we aren’t responsible for their
        privacy policies and practices. Please be aware that cookies placed by
        third parties may continue to track your activities online even after
        you have left our Services, and those third parties may not honor “Do
        Not Track” requests you have set using your browser or device.
        <br />
        <br />
        <u>
          <i>Information We Receive From Third Party Sources</i>
        </u>
        <br />
        <br />
        Some third parties such as our business partners and service providers
        provide us with Personal Data about you, such as the following:
        <br />
        <br />
        <ul>
          <li>
            <u>Information from our service providers:</u> We receive
            information collected by third parties about your use of the
            Services. For example, we may use analytics service providers to
            analyze how you interact and engage with the Services, so we can
            learn and make enhancements to offer you a better experience. Some
            of these entities may use Cookies, web beacons and other
            technologies to collect information about your use of the Services
            and other websites, which may include tracking activity across time
            and unaffiliated properties, including your IP address, web browser,
            pages viewed, time spent on pages, links clicked and conversion
            information. Third parties may also help us provide you with
            customer support, and provide us with information so that we may
            help you use our Services.
          </li>
        </ul>
        <br />
        <br />
        <strong>
          <u>How do we use your Personal Data?</u>
        </strong>
        <br />
        <br />
        We process Personal Data to operate, improve, understand and personalize
        our Services. For example, we use Personal Data to:
        <ul>
          <li>
            Communicate with you about the Services, including Service
            announcements, updates or offers
          </li>
          <li>Provide support and assistance for the Services</li>
          <li>
            Personalize website content and communications based on your
            preferences
          </li>
          <li>Process orders</li>
          <li>Respond to user inquiries</li>
          <li>Fulfill user requests </li>
          <li>Comply with our legal or contractual obligations</li>
          <li>Resolve disputes</li>
          <li>
            Protect against or deter fraudulent, illegal or harmful actions
          </li>
          <li>Enforce our Terms of Use</li>
        </ul>
        <br />
        <br />
        As noted above, we may communicate with you if you’ve provided us the
        means to do so. For example, if you’ve given us your email address, we
        may send you promotional email offers on behalf of other businesses, or
        email you about your use of the Services. If you do not want to receive
        communications from us, please indicate your preference by emailing us
        at <a href="mailto: support@arbilex.co">support@arbilex.co</a>.
        <br />
        <br />
        We will only process your Personal Data if we have a lawful basis for
        doing so. Lawful bases for processing include consent, contractual
        necessity and our “legitimate interests” or the legitimate interest of
        others, as further described below.
        <br />
        <br />
        <ul>
          <li>
            <u>Contractual Necessity:</u> We process the data that you provide
            to us as a matter of “contractual necessity”, meaning that we need
            to process that data to perform under our Terms of Use with you,
            which enables us to provide you with the Services. When we process
            data due to contractual necessity, failure to provide such Personal
            Data will result in your inability to use some or all portions of
            the Services that require such data.
          </li>
          <li>
            <u>Legitimate Interest:</u> We process all categories of your
            Personal Data when we believe it furthers the legitimate interest of
            us or third parties. Examples of these legitimate interests include:
            <ul>
              <li>
                Operation and improvement of our business, products and services
              </li>
              <li>Marketing of our products and services </li>
              <li>Provision of customer support</li>
              <li>Protection from fraud or security threats</li>
              <li>Compliance with legal obligations</li>
              <li>Completion of corporate transactions </li>
            </ul>
          </li>
          <li>
            <u>Consent:</u> In some cases, we process Personal Data based on the
            consent you expressly grant to us at the time we collect such data.
            When we process Personal Data based on your consent, it will be
            expressly indicated to you at the point and time of collection.
          </li>
          <li>
            <u>Other Processing Grounds:</u> From time to time we may also need
            to process Personal Data to comply with a legal obligation, if it is
            necessary to protect the vital interests of you or other data
            subjects, or if it is necessary for a task carried out in the public
            interest.
          </li>
        </ul>
        <br />
        <br />
        <strong>
          <u>Will ArbiLex share any of the Personal Data it receives?</u>
        </strong>
        <br />
        <br />
        We may share your Personal Data with third parties as described in this
        section:
        <br />
        <br />
        <u>
          <i>Data That’s Been De-identified</i>
        </u>
        <br />
        <br />
        We may de-identify your Personal Data so that you are not identified as
        an individual, and provide that information to our partners. We may also
        provide aggregate usage information to our partners (or allow partners
        to collect that information from you), who may use such information to
        understand how often and in what ways people use our Services, so that
        they, too, can provide you with an optimal online experience. However,
        we never disclose aggregate usage or de-identified information to a
        partner (or allow a partner to collect such information) in a manner
        that would identify you as an individual person.
        <br />
        <br />
        <u>
          <i>Service Providers</i>
        </u>
        <br />
        <br />
        We share Personal Data with vendors and third party service providers
        who work on our behalf and provide us with services related to the
        purposes described in this Privacy Policy or our Terms of Use. A
        complete list of sub-processors with whom we share Personal Data to
        assist us in providing the Services is available below.
        <ul>
          <li>
            <a href="https://rollbar.com">Rollbar</a>
          </li>
          <li>
            <a href="https://logdna.com">LogDNA</a>
          </li>
          <li>
            <a href="https://scoutapm.com/">Scout APM</a>
          </li>
          <li>
            <a href="https://www.tinfoilsecurity.com/">Tinfoil Security</a>
          </li>
          <li>
            <a href="https://ipapi.co/">ipapi</a>
          </li>
          <li>
            <a href="https://segment.com/">Segment.io</a>
          </li>
          <li>
            <a href="https://mixpanel.com/">Mixpanel</a>
          </li>
        </ul>
        These parties may include:
        <ul>
          <li>Fraud prevention service providers</li>
          <li>Analytics service providers</li>
          <li>Staff augmentation and contract personnel </li>
          <li>Hosting service providers</li>
          <li>Co-location service providers</li>
          <li>Telecommunications service providers</li>
        </ul>
        <br />
        <br />
        Unless we tell you differently, our vendors and service providers do not
        have any right to use the Personal Data we share with them beyond what
        is necessary to assist us.
        <br />
        <br />
        <u>
          <i>Data Shared at Your Request</i>
        </u>
        <br />
        <br />
        We also share Personal Data when necessary to complete a transaction
        initiated or authorized by you or provide you with a product or service
        you have requested. In addition to those set forth above, these parties
        also include:
        <ul>
          <li>
            Other users (where you post information publicly or as otherwise
            necessary to effect a transaction initiated or authorized by you
            through the Services){' '}
          </li>
          <li>
            Third party business partners who you access through the Services
          </li>
          <li>Other parties authorized by you</li>
        </ul>
        <br />
        <br />
        <u>
          <i>User Profiles and Submissions</i>
        </u>
        <br />
        <br />
        <u>
          <i>Business Transfers</i>
        </u>
        <br />
        <br />
        We may choose to buy or sell assets, and may share and/or transfer
        customer information in connection with the evaluation of and entry into
        such transactions. Also, if we (or our assets) are acquired, or if we go
        out of business, enter bankruptcy, or go through some other change of
        control, Personal Data could be one of the assets transferred to or
        acquired by a third party.
        <br />
        <br />
        <u>
          <i>Protection of ArbiLex and Others</i>
        </u>
        <br />
        <br />
        We reserve the right to access, read, preserve, and disclose any
        information that we believe is necessary to comply with law or court
        order; enforce or apply our Terms of Use and other agreements; or
        protect the rights, property, or safety of ArbiLex, our employees, our
        users, or others.
        <br />
        <br />
        <strong>
          <u>Is Personal Data about me secure?</u>
        </strong>
        <br />
        <br />
        We endeavor to protect the privacy of your Personal Data that we hold in
        our records, but unfortunately, we cannot guarantee complete security.
        Unauthorized entry or use, hardware or software failure, and other
        factors, may compromise the security of user information at any time.
        <br />
        <br />
        <strong>
          <u>How long do we retain your Personal Data?</u>
        </strong>
        <br />
        <br />
        We retain Personal Data about you for as long as you have an open
        account with us or as otherwise necessary to provide you Services . In
        some cases we retain Personal Data for longer, if doing so is necessary
        to comply with our legal obligations, resolve disputes or collect fees
        owed, or is otherwise permitted or required by applicable law, rule or
        regulation. Afterwards, we retain some information in a depersonalized
        or aggregated form but not in a way that would identify you personally.
        <br />
        <br />
        <strong>
          <u>What Personal Data can I access?</u>
        </strong>
        <br />
        <br />
        <ul>
          <li>Email</li>
          <li>First and last name</li>
          <li>Password</li>
        </ul>
        <br />
        <br />
        The information you can view, update, and delete may change as the
        Services change. If you have any questions about viewing or updating
        information we have on file about you, please contact us at{' '}
        <a href="mailto: support@arbilex.co">support@arbilex.co</a>. Residents
        of the European Union may have other rights – please see below for
        additional information.
        <br />
        <br />
        <strong>
          <u>What choices do I have?</u>
        </strong>
        <br />
        <br />
        You can always opt not to disclose information to us, but keep in mind
        some information may be needed to register with us or to take advantage
        of some of our features.
        <br />
        <br />
        You may be able to add, update, or delete information as explained
        above. When you update information, however, we may maintain a copy of
        the unrevised information in our records. We may use any aggregated data
        derived from or incorporating your Personal Data after you update or
        delete it, but not in a manner that would identify you personally.
        <br />
        <br />
        <strong>
          <u>What about the Personal Data of children?</u>
        </strong>
        <br />
        <br />
        As noted in the Terms of Use, we do not knowingly collect or solicit
        personally identifiable information from children under eighteen (18);
        if you are a child under eighteen (18), please do not attempt to
        register for or otherwise use the Services or send us any personal
        information. If we learn we have collected personal information from a
        child under eighteen (18), we will delete that information as quickly as
        possible. If you believe that a child under eighteen (18) may have
        provided us personal information, please contact us at{' '}
        <a href="mailto: support@arbilex.co">support@arbilex.co</a>.
        <br />
        <br />
        <strong>
          <u>Will ArbiLex ever change this Privacy Policy?</u>
        </strong>
        <br />
        <br />
        We’re constantly trying to improve our Services, so we may need to
        change this Privacy Policy from time to time as well, but we will alert
        you to changes by placing a notice on the arbilex.co website, by sending
        you an email, and/or by some other means. Please note that if you’ve
        opted not to receive legal notice emails from us (or you haven’t
        provided us with your email address), those legal notices will still
        govern your use of the Services, and you are still responsible for
        reading and understanding them. If you use the Services after any
        changes to the Privacy Policy have been posted, that means you agree to
        all of the changes. Use of information we collect now is subject to the
        Privacy Policy in effect at the time such information is used.
        <br />
        <br />
        <strong>
          <u>What if I live in California?</u>
        </strong>
        <br />
        <br />
        California residents are entitled to ask us for a notice identifying the
        categories of Personal Data which we share with our affiliates and/or
        third parties for marketing purposes, and providing contact information
        for such affiliates and/or third parties. If you are a California
        resident and would like a copy of this notice, please submit a written
        request to <a href="mailto: support@arbilex.co">support@arbilex.co</a>.
        <br />
        <br />
        The following terms apply to residents of California:
        <ul>
          <li>
            <u>Access</u>: You may, not more than twice in a twelve-month
            period, request more information about the Personal Data we hold
            about you and request a copy of such Personal Data by emailing us at{' '}
            <a href="mailto: support@arbilex.co">support@arbilex.co</a>.{' '}
          </li>
          <li>
            <u>Opt-Outs</u>: You are entitled to contact us to prevent
            disclosure of Personal Data to third parties for such third parties’
            direct marketing purposes; in order to submit such a request, please
            contact us at{' '}
            <a href="mailto: support@arbilex.co">support@arbilex.co</a>. You may
            also opt out of our sale of your personal information by clicking on
            the following link: Do Not Sell My Personal Information.
          </li>
          <li>
            <u>Erasure</u>: You may request that we delete the Personal Data
            that we have collected from you by emailing us at{' '}
            <a href="mailto: support@arbilex.co">support@arbilex.co</a>.
            However, we may not be required to delete your Personal Data under
            certain circumstances, including if such data is necessary for us to
            provide you with the Services, if we use such data only for our
            internal analytical use, and if such data is needed to complete a
            transaction or other action you have requested. If you request that
            we delete your Personal Data, you may no longer be able to use the
            Services.
          </li>
        </ul>
        <strong>
          <u>What if I live in the European Union?</u>
        </strong>
        <br />
        <br />
        If you are a resident of the European Union (“EU”), United Kingdom,
        Lichtenstein, Norway, or Iceland, you may have additional rights under
        the EU General Data Protection Regulation (the “GDPR”) with respect to
        your Personal Data, as outlined below.
        <br />
        <br />
        For this section, we use the terms “Personal Data” and “processing” as
        they are defined in the GDPR, but “Personal Data” generally means
        information that can be used to individually identify a person, and
        “processing” generally covers actions that can be performed in
        connection with data such as collection, use, storage and disclosure.
        ArbiLex will be the controller of your Personal Data processed in
        connection with the Services.
        <br />
        <br />
        If there are any conflicts between this this section and any other
        provision of this Privacy Policy, the policy or portion that is more
        protective of Personal Data shall control to the extent of such
        conflict. If you have any questions about this section or whether any of
        the following applies to you, please contact us at{' '}
        <a href="mailto: support@arbilex.co">support@arbilex.co</a>. Note that
        we may also process Personal Data of our customers’ end users or
        employees in connection with our provision of services to customers, in
        which case we are the processor of Personal Data. If we are the
        processor of your Personal Data (i.e., not the controller), please
        contact the controller party in the first instance to address your
        rights with respect to such data.
        <br />
        <br />
        <u>
          <i>What Rights Do You Have Regarding Your Personal Data?</i>
        </u>
        <br />
        <br />
        You have certain rights with respect to your Personal Data, including
        those set forth below. For more information about these rights, or to
        submit a request, please email{' '}
        <a href="mailto: support@arbilex.co">support@arbilex.co</a>. Please note
        that in some circumstances, we may not be able to fully comply with your
        request, such as if it is frivolous or extremely impractical, if it
        jeopardizes the rights of others, or if it is not required by law, but
        in those circumstances, we will still respond to notify you of such a
        decision. In some cases, we may also need to you to provide us with
        additional information, which may include Personal Data, if necessary to
        verify your identity and the nature of your request.
        <ul>
          <li>
            <u>Access</u>: You can request more information about the Personal
            Data we hold about you and request a copy of such Personal Data.{' '}
          </li>
          <li>
            <u>Rectification</u>: If you believe that any Personal Data we are
            holding about you is incorrect or incomplete, you can request that
            we correct or supplement such data.{' '}
          </li>
          <li>
            <u>Erasure</u>: You can request that we erase some or all of your
            Personal Data from our systems.{' '}
          </li>
          <li>
            <u>Withdrawal of Consent</u>: If we are processing your Personal
            Data based on your consent (as indicated at the time of collection
            of such data), you have the right to withdraw your consent at any
            time. Please note, however, that if you exercise this right, you may
            have to then provide express consent on a case-by-case basis for the
            use or disclosure of certain of your Personal Data, if such use or
            disclosure is necessary to enable you to utilize some or all of our
            Services.{' '}
          </li>
          <li>
            <u>Portability</u>: You can ask for a copy of your Personal Data in
            a machine-readable format. You can also request that we transmit the
            data to another controller where technically feasible.{' '}
          </li>
          <li>
            <u>Objection</u>: You can contact us to let us know that you object
            to the further use or disclosure of your Personal Data for certain
            purposes, such as for direct marketing purposes.{' '}
          </li>
          <li>
            <u>Restriction of Processing</u>: You can ask us to restrict further
            processing of your Personal Data.
          </li>
          <li>
            <u>Right to File Complaint</u>: You have the right to lodge a
            complaint about ArbiLex's practices with respect to your Personal
            Data with the supervisory authority of your country or EU Member
            State. A list of Supervisory Authorities is available here:{' '}
            <a href="https://edpb.europa.eu/about-edpb/board/members_en.">
              https://edpb.europa.eu/about-edpb/board/members_en.
            </a>
          </li>
        </ul>
        <br />
        <br />
        <u>
          <i>Transfers of Personal Data</i>
        </u>
        <br />
        <br />
        The Services are hosted and operated in the United States (“U.S.”)
        through ArbiLex and its service providers, and if you do not reside in
        the U.S., laws in the U.S. may differ from the laws where you reside. By
        using the Services, you acknowledge that any Personal Data about you,
        regardless of whether provided by you or obtained from a third party, is
        being provided to ArbiLex in the U.S. and will be hosted on U.S.
        servers, and you authorize ArbiLex to transfer, store and process your
        information to and in the U.S., and possibly other countries. You hereby
        consent to the transfer of your data to the U.S. pursuant to: (i) a data
        processing agreement incorporating standard data protection clauses
        promulgated by the European Commission, a copy of which can be obtained
        at{' '}
        <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32010D0087">
          https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32010D0087
        </a>
        , (ii) binding corporate rules for data protection that align with the
        GDPR’s requirements, or (iii) adherence to an industry- or
        technology-specific approved code of conduct blessed by the European
        Commission.
        <br />
        <br />
        <strong>
          <u>What if I have questions about this policy?</u>
        </strong>
        <br />
        <br />
        If you have any questions or concerns regarding our privacy policies,
        please send us a detailed message to{' '}
        <a href="mailto: support@arbilex.co">support@arbilex.co</a> and we will
        try to resolve your concerns.
        <br />
        <br />
        If you are located in the European Union, you may use the following
        information to contact our Data Protection Officer and our European
        Union-Based Member Representative:{' '}
        <a href="mailto: dpo@arbilex.co">
          <u>dpo@arbilex.co</u>
        </a>
      </div>
    </>
  );
};

export default modalPrivacy;
