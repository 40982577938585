const GET_SINGLE_CARD = 'cards/GET_SINGLE_CARD';

//******ACTIONS******//
export const getSingleCard = (card) => ({
  type: GET_SINGLE_CARD,
  card,
});

//******THUNKS******//

export const getSingleCardThunk =
  ({ token, id }) =>
  async (dispatch) => {
    // const res = await fetch(`http://127.0.0.1:8000/card?card=${id}`, {
    // const res = await fetch(`http://3.237.199.50:8000/card?card=${id}`, {
    const res = await fetch(
      `https://d1i2jq400lm6rf.cloudfront.net/card?card=${id}`,
      // `http://127.0.0.1:8000/card?card=${id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.ok) {
      const data = await res.json();
      dispatch(getSingleCard(data));
      return data;
    } else {
      return res;
    }
  };

//******REDUCER******//

const initialState = {};

const singleCardReducer = (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case GET_SINGLE_CARD:
      newState[action?.card?.data?.id] = action?.card?.data;
      return {
        ...newState,
      };
    default:
      return state;
  }
};
export default singleCardReducer;
