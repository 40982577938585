const FILTERS = 'filter/ADD_FILTERS';

//******ACTIONS******//

export const filterCheck = (flt) => ({
  type: FILTERS,
  flt,
});

//******THUNKS******//

export const filterCardsThunk =
  ({ filters }) =>
  async (dispatch) => {
    dispatch(filterCheck(filters));
  };

//******REDUCER******//

const initialState = {party:''};

const filterReducer = (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case FILTERS:
      newState = action?.flt;
      return {
        ...newState,
      };
    default:
      return state;
  }
};
export default filterReducer;
