import { StylesConfig } from "react-select";

interface ColourOption {
    readonly value: string;
    readonly label: string;
    
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
  }



export const colourStyles1: StylesConfig<ColourOption, true> = {
  container: (provided, state) => ({
    ...provided,
    padding: 0,
    height: 'fit-content',
  }),
  control: (styles) => ({ 
    ...styles, 
    // backgroundColor: 'black', 
    //  width: '125px',
    //   fontSize: '12px',
    //   lineHeight: '15.62px',
  //  padding: '4px 12px',
    // borderColor:'#2c2e30;', 
    // borderRadius:'0.5rem',
    // borderWidth: '0.1',
    // minHeight: 'fit-content',
    // height: 'fit-content'
  }),

  // indicatorsContainer: (provided, state) => ({
  //     ...provided,
  //     height: '35px'
  // }),

  // input: (provided, state) => ({
  //     ...provided,
  //     height: '28px'
  // }),

  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: "100%"
  }),
  };


  export const colourStyles2: StylesConfig<ColourOption, true> = {
    container: (provided, state) => ({
      ...provided,
      padding: 0,
      height: 'fit-content',
    }),
    // control: (styles) => ({ 
    //   ...styles, 
    //   // backgroundColor: 'white', 
    //   width:'100%',
    //   border: '0.063rem solid #2c2e30',
    //   borderRadius: '0.5rem',
    //   // minHeight: '20px',
    //   height: 'fit-content',
    //   // lineHeight: '24px',
    //   // fontSize: '14px',
    //   // height: '36px',
    //   // paddingBottom: '6px',
    //   // paddingTop: '6px',

    // }),
  
  

  
    input: (provided, state) => ({
        ...provided,
        height:'fit-content'

    }),
  
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "100%"
    }),
    };



    //600-767
