const GET_CARDS_COUNT = 'cards/GET_CARDS_COUNT';

//******ACTIONS******//

export const getCardsCount = (card, url) => ({
  type: GET_CARDS_COUNT,
  card,
  url,
});


//******THUNKS******//

export const getCardsCountThunk =
  ({ token, filters }) =>
  async (dispatch) => {
    let queryString = '';

    const query = (filter) => {
      if (queryString.length) {
        queryString += `,${filter}`;
      } else {
        queryString += `${filter}`;
      }
    };

    //Party
    if (filters?.party?.length >= 0) {
      if(filters?.party.includes('&')){
        let encoded = filters.party.replace('&','%and')

        query(`"party":"${encoded}"`);
      }else{
        query(`"party":"${filters?.party}"`);
      }
    }

    // Status
    if (filters?.status?.length > 0) {
      let arr = [];
      filters.status.forEach((el) => {
        arr.push(`"${el}"`);
      });
      query(`"status":[${arr}]`);
    }
    // Listing Type
    if (filters?.listing_type?.length > 0) {
      let arr = [];
      filters.listing_type.forEach((el) => {
        arr.push(`"${el}"`);
      });
      query(`"listing_type":[${arr}]`);
    }

    // rating
    if (filters?.rating?.length > 0) {
      let arr = [];
      filters.rating.forEach((el) => {
        arr.push(`"${el}"`);
      });
      query(`"rating":[${arr}]`);
    }
    
    // Venue
    if (filters?.venue?.length > 0) {
      query(`"venue":"${filters?.venue}"`);
    }
    // Case Type
    if (filters?.case_type?.length > 0) {
      let arr = [];
      filters.case_type.forEach((el) => {
        arr.push(`"${el}"`);
      });
      query(`"case_type":[${arr}]`);
    }
    // Start Date
    if (filters?.start_date?.length > 0) {
      query(`"start_date":"${filters?.start_date}"`);
    }
    // End Date
    if (filters?.end_date?.length > 0) {
      query(`"end_date":"${filters?.end_date}"`);
    }
    // Min Claim
    if (filters?.min_claim) {
      query(`"min_number":${Number(filters?.min_claim)}`);
    }
    // Max Claim
    if (filters?.max_claim) {
      query(`"max_number":${Number(filters?.max_claim)}`);
    }

    
      const res = await fetch(
        `https://d1i2jq400lm6rf.cloudfront.net/card/get-count?filter={${queryString}}`,
        // LOCAL TEST URLS for debugging:
        // `http://127.0.0.1:8000/card/get-count?filter={${queryString}}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res) {
        let data = await res.json();
        dispatch(getCardsCount(data, res.url));
        return data;
      }
    return;
  };

  const initialState = {
    count:0,
  };

  const countReducer = (state = initialState, action) => {
    let newState = {};
    switch (action.type) {
      case GET_CARDS_COUNT:
        newState['count'] = action?.card?.count;
        return {
          ...newState,
        };
      default:
        return state;
    }
  };

export default countReducer;
