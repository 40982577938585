import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import _Modal from '../Modal';
import cn from 'classnames';
import ElementModal from '../Modal/ElementModal';
import MasonryLayout from '../MasonryLayout/index.js';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import { Tooltip } from 'react-tooltip'

const DashboardWatchlist = ({ persistToken }) => {
  const [dashboardWatchlist, setDashboardWatchlist] = useState('');
  const { width, height } = useWindowDimensions();
  let privateDashboardWatchlist = [];
  const navigate = useNavigate();

  const toListings = (e) => {
    e.preventDefault();
    navigate('/cases');
  };

  useEffect(() => {
    fetch(`https://d1i2jq400lm6rf.cloudfront.net/card/private-card`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${persistToken?.access}`,
      },
    })
      .then((res) => {
        if (res.status < 400) {
          const results = res.json()
          // console.log(results);
          return results;
        } else if (res.status_code === 401) {
          fetch(
            `https://d1i2jq400lm6rf.cloudfront.net/user/refresh?refresh_token=${persistToken?.refresh}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          ).then(window.location.reload(true));
        } else {
          console.error('Error', res.status);
          return;
        }
      })
      .then((results) => {
        setDashboardWatchlist(results);
        return;
      });
  }, []);

  const toUpper = (string) => {
    let first = string[0].toUpperCase();
    let rest = string.slice(1, string.length);

    return first + rest;
  };

  // Keys=number of month; Value=abbrevieated month
  const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };
  // Converter for date format string
  const dateConverter = (date) => {
    let newDate = date.split('-');
    newDate = `${months[newDate[1]]} ${Number(newDate[2])}, ${Number(
      newDate[0]
    )}`;
    return newDate;
  };

  const acceptingCard = (card) => {
    // if (card[1].tags && card[1].tags.split(",").includes("AFX Exclusive"))
    //   return "Blue";
    if (card[1].listing_type === 'exclusive') return 'Blue';
    if (card[1].status === 'Green') return true;
    return false;
  };

  const organizationText = (org) => {
    switch(org) {
      case 1:
        return ("ArbiLex");
      case 2:
        return ("Omni Bridgeway");
      default:
        return;
    }
  };

  const organizationLogoRender = (org) => {
    switch(org) {
      case 1:
        return (
          <img
              src={require(`../../assets/logo-black.png`)}
              className="user-organization-logo"
              alt="ArbiLex Logo"
            />
        );
      case 2:
        return (
          <img
              src={require(`../../assets/Logos/OmniBridgewayLogo.svg`)}
              className="user-organization-logo"
              alt="Omni Bridgeway Logo"
            />
        );
      default:
        return;
    }
  };

  return (
    <div className="dash-reqs-container">
      <div className="dash-req-title-holder">
        <div className="dash-mod-title">My Watchlist</div>
      </div>
      {dashboardWatchlist?.data?.card_pipelines.map((pipeline, i) => {
        pipeline.private_cards?.map((cardInfo,j) => {
          privateDashboardWatchlist.push(cardInfo);
        })
      })}
      {privateDashboardWatchlist.length === 0 ? (
        <div className="no-private-card-container">
          <div className="no-private-card-title">
            You currently have no cases with exclusive information.
          </div>
          <div className="no-private-card-body">
            If you want exclusive information, analytics reports, or private case opportunities. Please express interest in the particular case or reach out if you have a case in mind that isn't on AFX.
          </div>
          <button onClick={(e) => toListings(e)} className="no-private-card-c2a">
            Request analytics on cases
          </button>
        </div>
      ) : (
        <div className="dash-request-container">
          <div className="masonry-layout-holder">
            <MasonryLayout>
              {privateDashboardWatchlist &&
                Object?.entries(privateDashboardWatchlist)?.map((cardInfo, i) => (
                  <div
                    className={cn('single-case-card', {
                      // insert adjustable class CSS here for cases
                    })}
                    key={i}
                  >
                    {cardInfo?.[1]?.text?.concluded_status && (
                      <div className='pre-card-container'>
                        <div className='pre-card-concluded-text'>
                          {cardInfo?.[1]?.text?.concluded_status}
                        </div>
                        <div className='pre-card-concluded-background'/>
                      </div>  
                    )}
                    <div className='card-container'>
                      <div className="card-image-container">
                      <figure className={cn('card-image', {
                            // insert adjustable class CSS here for cases
                          })}>
                        <img className='card-image'
                          src={cardInfo[1]?.text?.img_url}
                        />
                        {/* <figcaption className='private-card-caption-1'>
                          {"Private case for " + organizationText(cardInfo[1]?.card_pipeline)}
                        </figcaption> */}
                        {/* <figcaption className='private-card-caption-2'>
                          {organizationLogoRender(cardInfo[1]?.card_pipeline)}
                        </figcaption> */}
                        {/* <figcaption className='private-card-caption-3'>
                          {organizationText(cardInfo[1]?.card_pipeline)}
                        </figcaption> */}
                        {/* <figcaption className='private-card-caption-4'>
                          {organizationText(cardInfo[1]?.card_pipeline)}
                        </figcaption> */}
                        <figcaption className='private-card-caption-5'>
                          {organizationText(cardInfo[1]?.card_pipeline)}
                        </figcaption>
                      </figure>
                      </div>
                      <div
                        className={cn('single-card-info', {
                          // insert adjustable class CSS here for cases
                          regular_content:!acceptingCard(cardInfo)
                        })}
                      >
                        <div className='single-card-header-container'>
                          <div className={cn('image-tags', {})}>
                            {cardInfo[1]?.rating && (
                              <div
                                className={cn('status-tag secondary_tag rating_tag', {
                                  rating_a: cardInfo[1]?.rating === 'A',
                                  rating_b: cardInfo[1]?.rating === 'B',
                                  rating_c: cardInfo[1]?.rating === 'C',
                                  rating_d: cardInfo[1]?.rating === 'D',
                                })}
                              >
                                AFX Rating: {cardInfo[1]?.rating}
                              </div>
                            )}
                            {cardInfo[1]?.tags &&
                              cardInfo[1]?.tags?.split(',').map((tag, i) => {
                                if ((tag.toLowerCase().includes('uncertainty'))) {
                                  return (
                                    <div
                                      className={cn('status-tag secondary_tag uncertainty_tag', {
                                        higher_uncertainty_tag: tag === 'Higher Uncertainty',
                                        medium_uncertainty_tag:tag === 'Medium Uncertainty',
                                        lower_uncertainty_tag: tag === 'Lower Uncertainty',
                                      })}
                                      key = {i}
                                    >
                                      {tag}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div className="status-tag secondary_tag test_tag" key={i}>
                                      {tag}
                                    </div>
                                  );
                                }
                              }
                            )} 
                          </div>
                          <div className='private-card-icon'>
                            <a 
                              data-tooltip-id="private-card-tooltip"
                              data-tooltip-delay-hide={300}
                              data-tooltip-place='bottom'
                              data-tooltip-offset={12}
                            >
                              <img
                                src={require(`../../assets/icons/manillaEnvelopeFilled.png`)}
                                className="private-card-icon-image"
                                alt="Private Card Icon"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="single-card-container">
                          <div className="top-card-container">
                            {cardInfo[1]?.text?.rating_updated === true && (
                              <div className="rating-updated-tag-container">
                                <a 
                                  data-tooltip-id="rating-updated-tooltip"
                                  data-tooltip-delay-hide={300}
                                  data-tooltip-place='bottom-start'
                                  data-tooltip-offset={-2}
                                  data-some-relevant-attr={cardInfo[1]?.title}
                                  data-tooltip-content={dateConverter(cardInfo[1]?.text?.rating_timeline[0]?.date)}
                                >
                                  <div className="rating-updated-tag">
                                    <img
                                      src={require(`../../assets/information-icon.png`)}
                                      className="rating-updated-info-icon"
                                      alt="Icon for Rating Updated"
                                    />
                                    Rating Updated
                                  </div>
                                </a>
                              </div>
                            )}
                            <div className="single-card-date">
                              {dateConverter(cardInfo[1]?.date)}
                            </div>
                            <div className="to-card-link">
                              <Link
                                to={`/privatecases/${cardInfo[1]?.id}`}
                                className="single-card-title underline-animation"
                              >
                                {cardInfo[1]?.title}
                              </Link>
                            </div>
                          </div>
                          <div className="bottom-card-container">
                            <div
                              className={cn('single-card-stats', {
                                // insert adjustable class CSS here for cases
                              })}
                            >
                              <div className="stats-paragraph">
                                Claim size
                                {cardInfo[1]?.tags?.includes('Claim Size Unknown') ? (
                                  <span>Unknown</span>
                                ) : (
                                  <span>
                                    ${cardInfo[1]?.number}
                                    {cardInfo[1]?.unit}
                                  </span>
                                )}
                              </div>
                              <div className="stats-paragraph">
                                Case Type<span>{cardInfo[1]?.case_type}</span>
                              </div>
                            </div>
                            <div className="stats-paragraph last-stat-p">
                              Venue<span>{cardInfo[1]?.venue}</span>
                            </div>
                            <div className="view-case-link-container">
                              <Link
                                to={`/privatecases/${cardInfo[1]?.id}`}
                                className="view-case-link"
                              >
                                View case →
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </MasonryLayout>
            <Tooltip 
              id="rating-updated-tooltip" 
              className="rating-updated-tooltip-popup-container" 
              classNameArrow="rating-updated-tooltip-popup-arrow"
              render={({ content, activeAnchor }) => (
                <span>
                  {content && (
                    <div className="rating-updated-info-title">
                      The AFX Rating for {activeAnchor?.getAttribute('data-some-relevant-attr') || 'not set'} was updated on {content}.
                    </div>
                  )}
                </span>
              )}
            >
            </Tooltip>
            <Tooltip 
              id="private-card-tooltip" 
              className="rating-updated-tooltip-popup-container" 
              classNameArrow="rating-updated-tooltip-popup-arrow"
              render={() => (
                <span>
                  <div className="rating-updated-info-title">
                    Private case for your organization
                  </div>
                </span>
              )}
            >
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardWatchlist;