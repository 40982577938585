// Acceptable Use Modal 
const modalAUP = () => {
  return (
    <>
      <div className="modal-title-element">Acceptable Use Policy</div>
      <div className="modal-body-element policy-modal">
        <div>
          The following Acceptable Use Policy (the “AUP”) applies to all users
          of the ArbiLex service from Arbi, Inc. (“ArbiLex”). This AUP is a
          supplement to our Terms of Use and Privacy Policy at www.ArbiLex.co.
          All use of the ArbiLex Services and Site is subject to the Terms of
          Use and this AUP.
        </div>
        <div>
          ArbiLex reserves the right, at our discretion, to modify this AUP at
          any time by posting a notice on the ArbiLex website, or by sending you
          a notice via email and/or by some other means. You are responsible for
          reviewing and becoming familiar with any such modifications. Use of
          the Services or Site by you following such notification constitutes
          your acceptance of the terms and conditions of the AUP as modified.
          Any capitalized terms which are not defined herein have the meaning
          set forth in the Terms of Use or Privacy Policy, as applicable.
        </div>
        <div>
          You acknowledge and agree that your account to use the Services or
          Site is individual to you, and you will not share your account or any
          information associated with your account with any other person,
          including, without limitation, your coworkers.
        </div>
        <div>
          Any information that is accessed or generated through or by the
          Services or Site shall be considered Confidential Information of
          ArbiLex. Unless as otherwise expressly permitted in another written
          agreement or any Policy, you shall not copy, reproduce or disclose any
          Confidential Information without the prior written consent of ArbiLex,
          and you shall use reasonable care to protect Confidential Information
          from unauthorized access, use and disclosure. In the event you believe
          you have a bona fide reason to disclose Confidential Information to a
          third party, such as, for example, pitching the ArbiLex platform to a
          potential customer, you must obtain ArbiLex’s prior written consent
          (email is sufficient) and conspicuously and appropriately attribute
          any such information to ArbiLex.
        </div>
        <div>
          “Confidential Information” means all interface design, UX, trademarks,
          trade names, other service names or marks, data and other legal,
          business or technical information disclosed by ArbiLex to you or
          disclosed for your use of the Services, but not including any
          information that you can demonstrate is (a) rightfully furnished to
          you without restriction by a third party, (b) publicly available
          without breach of this Policy or any other Policy or (c) independently
          developed by it without reliance on such information. Confidential
          Information shall also include any information that ArbiLex provides
          to you on behalf of a third party.
        </div>
        <div>
          You represent, warrant and agree that you shall not (and shall not
          permit any third party to) use or interact with the ArbiLex Services
          or Site in manner that:
        </div>
        <ul>
          <li>
            Infringes or violates the intellectual property rights or any other
            rights of anyone else (including ArbiLex);
          </li>
          <li>Violates any law, ordinance, or regulation;</li>
          <li>
            Is harmful, fraudulent, deceptive, threatening, abusive, harassing,
            defamatory, vulgar, obscene, or otherwise objectionable (as
            determined by ArbiLex in its sole and absolute discretion);
          </li>
          <li>
            Attempts, in any manner, to obtain the password, account, private
            information or other security information from any other user of the
            Services;
          </li>
          <li>
            Enables others, in any manner, to access your account, plan or any
            other information that is unique or individual to you;
          </li>
          <li>
            Constitutes unauthorized, unsolicited or illegal advertising, junk
            or bulk communications (“spamming”);
          </li>
          <li>
            Involves contests, sweepstakes, barter, advertising, or pyramid
            schemes;
          </li>
          <li>
            Impersonates any person or entity, including any employee or
            representative of ArbiLex;
          </li>
          <li>Promotes, incites or instructs on criminal matters;</li>
          <li>
            “Crawls,” “scrapes,” or “spiders” (through use of manual or
            automated means): (i) any page or portion of the ArbiLex Services or
            Site or (ii) any third party site in violation of that site’s terms
            of service or any other third party rights;
          </li>
          <li>
            Violates the security of any computer network, crack passwords or
            security encryption codes, transfer or store illegal material;
          </li>
          <li>
            Decompiles, reverse engineers, or otherwise attempts to obtain the
            source code or any proprietary information of the Services; or
          </li>
          <li>
            Is false, misleading or deceptive, or likely to mislead or deceive.
          </li>
        </ul>
        <div>
          ArbiLex, in its sole discretion, shall determine whether there has
          been a violation of this Policy.
        </div>
        <div>
          The following list of provides examples of prohibited uses. The list
          is provided by way of example and should not be considered exhaustive.
          Violation of this Policy may result in termination or suspension of
          all services provided by ArbiLex and may also result in civil,
          criminal, or administrative liability or penalties against you. Any
          failure to enforce this Policy does not amount to a waiver of
          ArbiLex’s rights.
        </div>
      </div>
    </>
  );
};

export default modalAUP;
