import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useLayoutEffect } from 'react';
import cn from 'classnames';
import ExclusiveCard from './ExclusiveCard.js';
import MasonryLayout from '../MasonryLayout/index.js';
import { Link } from 'react-router-dom';
import { logout } from '../../store/features/session';
import { nextThunk, pageThunk } from '../../store/features/cards.js';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import { Tooltip } from 'react-tooltip'

const Cards = ({ persistToken, user }) => {
  const dispatch = useDispatch();
  const cards = useSelector((state) => state?.cards?.list);
  const NEXT = useSelector((state) => state?.cards?.next);
  const PREV = useSelector((state) => state?.cards?.previous);
  const COUNT = useSelector((state) => state?.cards?.count);
  const BASE_URL = useSelector((state) => state?.cards?.url);
  const cardLength = useSelector((state) => state?.cards?.cardLength);
  const { width, height } = useWindowDimensions();
  // NUMBER NEEDS TO COINCIDE WITH AMOUNT IN RESPONSE OBJECT => backend => pagination.py
  const PAGE_LIMIT = 20;
  const [nextUrl, setNextUrl] = useState();
  const [prevUrl, setPrevUrl] = useState();

  /**
  Function that returns truthy or falsy value depending on card status or tags
  in previous iterations this would check for exclusivity
  currently there are NO EXCLUSIVE cards 'BLUE' listing type NOR 'GREEN' status,
  so its only purpose is to apply certain styles to cards for falsy values.
  
  Going forward depending on card design,
  if card status is 'GREEN' then it will check for exclusivity and apply styles accordingly 
  same for listing type 'BLUE'
   */
  const acceptingCard = (card) => {
    // if (card[1].tags && card[1].tags.split(",").includes("AFX Exclusive"))
    //   return "Blue";
    if (card[1].listing_type === 'exclusive') return 'Blue';
    if (card[1].status === 'Green') return true;
    return false;
  };

  /*
  In previous iterations was used to check status,
  may be implemented once we have exclusive cards 
   */
  const fundedCard = (card) => card[1].status === 'Purple';
  const unknownCard = (card) => card[1].status === 'Gray';
  const isAcceptingTag = (tag) => (tag ? 'Accepting Proposals' : false);
  const isExclusiveTag = (tag) => tag === 'AFX Exclusive';

  // Updates width for dependent functions
  useEffect(() => {}, [width]);

  // Sets URL for next and previous pages
  useEffect(() => {
    setNextUrl(NEXT);
    setPrevUrl(PREV);
  }, [NEXT, PREV]);

  // Keys=number of month; Value=abbrevieated month
  const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };
  // Converter for date format string
  const dateConverter = (date) => {
    let newDate = date.split('-');
    newDate = `${months[newDate[1]]} ${Number(newDate[2])}, ${Number(
      newDate[0]
    )}`;
    return newDate;
  };

  return (
    <div className="masonry-layout-holder">
      {cardLength === false ||
      cardLength === null ||
      cardLength === undefined ? (
        <div className="masonry-container">
          <div className="no-card-message">
            At the moment, no case on the platform matches the selected
            filtering criteria.
          </div>
        </div>
      ) : (
        <MasonryLayout>
          {cards &&
            Object?.entries(cards)?.map((cardInfo, i) => (
              <div
                className={cn('single-case-card', {
                  is_exclusive: acceptingCard(cardInfo) === 'Blue',
                })}
                key={i}
              >
                {cardInfo?.[1]?.text?.concluded_status && (
                  <div className='pre-card-container'>
                    <div className='pre-card-concluded-text'>
                      {cardInfo?.[1]?.text?.concluded_status}
                    </div>
                    <div className='pre-card-concluded-background'/>
                  </div>  
                )}
                <div className='card-container'>
                  <div className="card-image-container">
                    <img
                      className={cn('card-image', {
                        exclusive_img_size: acceptingCard(cardInfo) === 'Blue',
                      })}
                      src={cardInfo?.[1]?.text?.img_url}
                    />
                  </div>
                  <div
                    className={cn('single-card-info', {
                      regular_content: !acceptingCard(cardInfo),
                      exclusive_content: acceptingCard(cardInfo) === 'Blue',
                    })}
                  >
                    <div className={cn('image-tags', {})}>
                      {cardInfo[1]?.rating && (
                        <div
                          className={cn('status-tag secondary_tag rating_tag', {
                            rating_a: cardInfo[1].rating === 'A',
                            rating_b: cardInfo[1].rating === 'B',
                            rating_c: cardInfo[1].rating === 'C',
                            rating_d: cardInfo[1].rating === 'D',
                          })}
                        >
                          AFX Rating: {cardInfo[1]?.rating}
                        </div>
                      )}
                      {cardInfo[1]?.tags &&
                        cardInfo[1]?.tags?.split(',').map((tag, i) => {
                          if ((tag.toLowerCase().includes('uncertainty'))) {
                            return (
                              <div
                                className={cn('status-tag secondary_tag uncertainty_tag', {
                                  higher_uncertainty_tag: tag === 'Higher Uncertainty',
                                  medium_uncertainty_tag:tag === 'Medium Uncertainty',
                                  lower_uncertainty_tag: tag === 'Lower Uncertainty',
                                })}
                                key = {i}
                              >
                                {tag}
                              </div>
                            );
                          } else {
                            return (
                              <div className="status-tag secondary_tag test_tag" key={i}>
                                {tag}
                              </div>
                            );
                          }
                        }
                      )} 
                    </div>
                    {/* RESPONSIVE TAGS */}
                    {/* <div className={cn('image-tags', {})}>
                  {cardInfo[1].tags &&
                    cardInfo[1].tags.split(',').map((tag, i) => {
                      return (
                        <div className="status-tag secondary_tag" key={i}>
                          {tag}
                        </div>
                      );
                    })}
                </div> */}
                    <div className="single-card-container">
                      <div className="top-card-container">
                        {cardInfo?.[1]?.text?.rating_updated === true && (
                          <div className="rating-updated-tag-container">
                            <a 
                              data-tooltip-id="rating-updated-tooltip"
                              data-tooltip-delay-hide={300}
                              data-tooltip-place='bottom-start'
                              data-tooltip-offset={-2}
                              data-some-relevant-attr={cardInfo[1].title}
                              data-tooltip-content={dateConverter(cardInfo[1]?.text?.rating_timeline[0]?.date)}
                            >
                              <div className="rating-updated-tag">
                                <img
                                  src={require(`../../assets/information-icon.png`)}
                                  className="rating-updated-info-icon"
                                  alt="Icon for Rating Updated"
                                />
                                Rating Updated
                              </div>
                            </a>
                          </div>
                        )}
                        <div className="single-card-date">
                          {dateConverter(cardInfo[1]?.date)}
                        </div>
                        <div className="to-card-link">
                          <Link
                            to={`/cases/${cardInfo[1]?.id}`}
                            // to={`/${cardInfo[1]?.id}`}
                            className="single-card-title underline-animation"
                          >
                            {cardInfo[1].title}
                          </Link>
                        </div>
                      </div>
                      <div className="bottom-card-container">
                        <div
                          className={cn('single-card-stats', {
                            exc_speratation: acceptingCard(cardInfo),
                          })}
                        >
                          <div className="stats-paragraph">
                            Claim size
                            {cardInfo[1]?.tags?.includes('Claim Size Unknown') ? (
                              <span>Unknown</span>
                            ) : (
                              <span>
                                ${cardInfo[1].number}
                                {cardInfo[1].unit}
                              </span>
                            )}
                          </div>
                          <div className="stats-paragraph">
                            Case Type<span>{cardInfo[1]?.case_type}</span>
                          </div>
                        </div>
                        <div className="stats-paragraph last-stat-p">
                          Venue<span>{cardInfo[1]?.venue}</span>
                        </div>
                        <div className="view-case-link-container">
                          <Link
                            to={`/cases/${cardInfo[1]?.id}`}
                            className="view-case-link"
                          >
                            View case →
                          </Link>
                        </div>
                      </div>
                      {acceptingCard(cardInfo) === 'Blue' && (
                        <div className="exclusive-stats-container">
                          <p className="exc-stats-paragraph">
                            Estimated duration
                          </p>
                          <p className="exc-stats-paragraph">Investment size</p>
                          <p className="exc-stats-paragraph">Funding status</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {acceptingCard(cardInfo) === 'Blue' && (
                    <div
                      className={cn({
                        exclusive_info: acceptingCard(cardInfo) === 'Blue',
                      })}
                    >
                      <ExclusiveCard props={cardInfo[1]} />
                    </div>
                  )}
                </div>
              </div>
            ))}
        </MasonryLayout>
      )}
      {Object?.entries(cards)?.length ? (
        <Pagination
          NEXT={NEXT}
          PREV={PREV}
          nextUrl={nextUrl}
          prevUrl={prevUrl}
          persistToken={persistToken}
          COUNT={COUNT}
          PAGE_LIMIT={PAGE_LIMIT}
          BASE_URL={BASE_URL}
        />
      ) : null}
      <Tooltip 
        id="rating-updated-tooltip" 
        className="rating-updated-tooltip-popup-container" 
        classNameArrow="rating-updated-tooltip-popup-arrow"
        render={({ content, activeAnchor }) => (
          <span>
            {content && (
              <div className="rating-updated-info-title">
                The AFX Rating for {activeAnchor?.getAttribute('data-some-relevant-attr') || 'not set'} was updated on {content}.
              </div>
            )}
          </span>
        )}
      >
      </Tooltip>
    </div>
  );
};

// Pagination function
const Pagination = ({
  NEXT,
  PREV,
  nextUrl,
  prevUrl,
  persistToken,
  COUNT,
  PAGE_LIMIT,
  BASE_URL,
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const pageNumbers = [];

  // For loop to get number of pages
  for (let i = 1; i <= Math.ceil(COUNT / PAGE_LIMIT); i++) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    setCurrentPage(1);
  }, [COUNT]);
  // const innerNumbers = pageNumbers.slice(1, pageNumbers.length - 2);
  // Goes to next page
  async function nextPage(e, url) {
    e.preventDefault();
    if (NEXT) {
      dispatch(nextThunk({ token: persistToken, url: url }));
      setCurrentPage(currentPage + 1);
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      });
    }
    return;
  }
  /// Goes to previous page
  async function prevPage(e, url) {
    e.preventDefault();
    if (PREV) {
      dispatch(nextThunk({ token: persistToken, url: url }));
      setCurrentPage(currentPage - 1);
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      });
    }
    return;
  }

  // Handle Scroll and get Next URL
  const paginate = (e, number) => {
    e.preventDefault();
    const url = `${BASE_URL}&page=${number}`;
    dispatch(
      nextThunk({
        token: persistToken,
        url: url,
      })
    );
    setCurrentPage(number);
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  };

  return (
    <>
      <div className="pagination-container">
        {/* PREV PAGE ARROW BUTTON */}
        <button
          className={cn('pagination-btn', {
            inactive_page_btn: !PREV,
          })}
          onClick={(e) => prevPage(e, prevUrl)}
        >
          <svg className="left-p-btn" />
        </button>
        {/* FIRST PAGE BUTTON */}
        <div className="pagination-button-holder">
          {pageNumbers?.length && pageNumbers[0] && (
            <button
              onClick={(e) => paginate(e, 1)}
              className={cn('pagination-count-btn', {
                activePage: currentPage === 1,
              })}
            >
              {1}
            </button>
          )}
          {/* IF ONLY 5 PAGES  */}
          {pageNumbers?.length === 5 &&
            pageNumbers?.slice(1, 4)?.map((number, i) => (
              <button
                key={i}
                onClick={(e) => paginate(e, number)}
                className={cn('pagination-count-btn', {
                  activePage: currentPage === number,
                })}
              >
                {number}
              </button>
            ))}
          {pageNumbers?.length < 5 &&
            pageNumbers
              ?.slice(1, pageNumbers[pageNumbers.length - 2])
              ?.map((number, i) => (
                <button
                  key={i}
                  onClick={(e) => paginate(e, number)}
                  className={cn('pagination-count-btn', {
                    activePage: currentPage === number,
                  })}
                >
                  {number}
                </button>
              ))}
          {/* 3 DOTS START */}
          {pageNumbers?.length > 5 && currentPage > 3 && (
            <div className="pagination-svg-div">
              <svg className="three-dots" />
            </div>
          )}

          {/* The following logic represents how each page is displayed, 3 numbers centered with ellipses on each side unless currentPages' subsequent number is last  */}
          {/* CURRENT PAGE > 3 PAGINATION STYLE */}
          {pageNumbers?.length > 5 &&
            currentPage > 3 &&
            currentPage < pageNumbers[pageNumbers.length - 2] &&
            pageNumbers
              ?.slice(currentPage - 2, currentPage + 1)
              ?.map((number, i) => (
                <button
                  key={i}
                  onClick={(e) => paginate(e, number)}
                  className={cn('pagination-count-btn', {
                    activePage: currentPage === number,
                  })}
                >
                  {number}
                </button>
              ))}

          {pageNumbers?.length > 5 &&
            currentPage > 3 &&
            currentPage === pageNumbers[pageNumbers.length - 2] &&
            pageNumbers
              ?.slice(currentPage - 3, currentPage)
              ?.map((number, i) => (
                <button
                  key={i}
                  onClick={(e) => paginate(e, number)}
                  className={cn('pagination-count-btn', {
                    activePage: currentPage === number,
                  })}
                >
                  {number}
                </button>
              ))}
          {pageNumbers?.length > 5 &&
            currentPage > 3 &&
            currentPage === pageNumbers[pageNumbers.length - 1] &&
            pageNumbers
              ?.slice(currentPage - 4, currentPage - 1)
              ?.map((number, i) => (
                <button
                  key={i}
                  onClick={(e) => paginate(e, number)}
                  className={cn('pagination-count-btn', {
                    activePage: currentPage === number,
                  })}
                >
                  {number}
                </button>
              ))}

          {/*  CURRENT PAGE < 4 PAGINATION STYLE */}
          {pageNumbers?.length > 5 &&
            currentPage < 4 &&
            pageNumbers?.slice(1, 4)?.map((number, i) => (
              <button
                key={i}
                onClick={(e) => paginate(e, number)}
                className={cn('pagination-count-btn', {
                  activePage: currentPage === number,
                })}
              >
                {number}
              </button>
            ))}

          {/* 3 DOTS END */}
          {pageNumbers?.length > 5 &&
            pageNumbers[pageNumbers.length - 1] - (currentPage + 1) > 1 && (
              <div className="pagination-svg-div">
                <svg className="three-dots" />
              </div>
            )}
          {/* LAST PAGE NUMBER BUTTON */}
          {pageNumbers?.length > 1 &&
            pageNumbers[pageNumbers.length - 1] !== pageNumbers[0] && (
              <button
                onClick={(e) => paginate(e, pageNumbers.length)}
                className={cn('pagination-count-btn', {
                  activePage: currentPage === pageNumbers.length,
                })}
              >
                {pageNumbers?.length}
              </button>
            )}
        </div>
        {/* NEXT PAGE ARROW BUTTON */}
        <button
          className={cn('pagination-btn', {
            inactive_page_btn: !NEXT,
          })}
          onClick={(e) => nextPage(e, nextUrl)}
        >
          <svg className="right-p-btn" />
        </button>
      </div>
    </>
  );
};
export default Cards;
{
  /* {pageNumbers?.map((number) => (
            <button
              onClick={(e) => paginate(e, number)}
              className={cn('pagination-count-btn', {
                activePage: currentPage === number,
              })}
            >
              {number}
            </button>

            IMAGE TAGS 
             {/* <div
                  className={cn('status-tag', {
                    unknown_status: unknownCard(cardInfo),
                    funded_status: fundedCard(cardInfo),
                    exclusive_status: acceptingCard(cardInfo) === 'Blue',
                    accepting_status: acceptingCard(cardInfo),
                  })}
                >
                  <div>
                    {acceptingCard(cardInfo) === 'Blue'
                      ? 'AFX Exclusive'
                      : acceptingCard(cardInfo)
                      ? 'Accepting Proposals'
                      : unknownCard(cardInfo)
                      ? 'Funding Status: Unknown'
                      : 'Funded'}
                  </div>
                </div>
                {cardInfo[1].listing_type &&
                  cardInfo[1].listing_type === 'exclusive' && (
                    <div
                      className={cn('status-tag', {
                        accepting_status: acceptingCard(cardInfo),
                      })}
                    >
                      Accepting Proposals
                    </div>
                  )} */
}
