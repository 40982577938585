import cn from 'classnames';
import { getFactorsThunk } from '../../store/features/factors';
import { addFactorsThunk } from '../../store/features/client-factors';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FactorProgress from './FactorProgress';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import _Modal from '../Modal';
import modalCoS from '../Modal/modalCoS';

const FactorsConsidered = ({ rating, card, membership }) => {
  const renderFactors = useSelector((state) => state.factors);
  const dispatch = useDispatch();
  const [chanceOpen, setChanceOpen] = useState(true);
  const [loadFactor, setLoadFactor] = useState(false);
  const [factorOpen, setFactorOpen] = useState({});
  const { width, height } = useWindowDimensions();

  const [factorObject, setFactorObject] = useState({
    'Chance of success': {
      total: rating?.detailedInformation?.chance?.score,
      weight: 100,
    },
  });

  const [clientFactors, setClientFactors] = useState({
    'Chance of success': {
      total: rating?.detailedInformation?.chance?.score,
      weight: 100,
    },
  });

  const openChance = (e) => {
    e.preventDefault();
    chanceOpen ? setChanceOpen(false) : setChanceOpen(true);
  };

  const openFactor = (e, i) => {
    e.preventDefault();

    if (factorOpen[i].open) {
      setFactorOpen((prevState) => ({
        ...prevState,
        [i]: { open: false },
      }));
    } else {
      setFactorOpen((prevState) => ({
        ...prevState,
        [i]: { open: true },
      }));
    }
  };

  // Keys=number of month; Value=abbrevieated month
  const months = {
    "01": 'Jan',
    "02": 'Feb',
    "03": 'Mar',
    "04": 'Apr',
    "05": 'May',
    "06": 'Jun',
    "07": 'Jul',
    "08": 'Aug',
    "09": 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

  // Converter for date format string
  const dateConverter = (date) => {
    let newDate = date.split('-');
    newDate = `${months[newDate[1]]} ${Number(newDate[2])}, ${Number(
      newDate[0]
    )}`;
    return newDate;
  };

  const scoreConverter = (score) => {
    if (score >= 0 && score <= 25) {
      return "D";
    } 
    if (score >= 26 && score <= 50) {
      return "C";
    } 
    if (score >= 51 && score <= 75) {
      return "B";
    } 
    if (score >= 76 && score <= 100) {
      return "A";
    } 
  };
  
  useEffect(() => {
    (async () => {
      rating?.detailedInformation?.factors?.forEach((el, i) =>
        setFactorOpen((prevState) => ({
          ...prevState,
          [i]: { open: false },
        }))
      );
      setLoadFactor(true);
    })();
  }, []);

  const notify = () =>
    toast('Please make sure weight total is equal to 100', {
      hideProgressBar: true,
      theme: 'dark',
      className: 'single-case-toast',
    });

  const factorCheck = (factor, total, e) => {
    if (e.target.checked) {
      setFactorObject((prevState) => ({
        ...prevState,
        [factor]: { total: total, weight: 100 },
      }));
    }
  };

  const factorCheck2 = (factor, e) => {
    if (!e.target.checked) {
      const newFactorObject = { ...factorObject };
      delete newFactorObject[factor];
      setFactorObject(newFactorObject);
    }
  };

  const clientFactorsFunc = (e, total, factor) => {
    setFactorObject((prevState) => ({
      ...prevState,
      [factor]: { total: total, weight: Number(e.target.value) },
    }));
  };

  const changeHandler = (e) => {
    return e.target.checked;
  };

  const handleCalc = (e) => {
    e.preventDefault();
    let arr = [];
    Object.entries(renderFactors).forEach((el, i) => {
      arr.push(Number(el[1].weight));
    });

    const sum = arr.reduce(
      (previousValue, currentValue) => previousValue + currentValue
    );

    if (sum !== 100) {
      notify();
    } else {
      dispatch(addFactorsThunk(renderFactors));
    }
  };

  useEffect(() => {
    dispatch(getFactorsThunk(factorObject));
  }, [factorObject]);

  useEffect(() => {
    dispatch(addFactorsThunk(clientFactors));
  }, []);

  useEffect(() => {}, [width]);

  return (
    <>
      <div className="factors-title-container-left">
        <div className="first-grouping">
          <h4 className="fact-con-title">
            {width <= 904 ? 'Factors' : 'Factors Considered'}
          </h4>
          <h4 className="fact-con-score">Score</h4>
        </div>
        <h4 className="fact-con-weight">Weight</h4>
      </div>
      {width > 599 ? (
        //? ***************** SCREEN BIGGER THAN 599 *****************

        <div className="factors-considered">
          <section className="factor-info-section">
            <label>
              <input
                type="checkbox"
                name="chance-of-success"
                checked={true}
                onChange={changeHandler}
                onClick={() => false}
              />
              <p className="factor-label">Chance of success</p>{' '}
              <p className="label-ep-number">
                {rating?.detailedInformation?.chance?.score}
              </p>
            </label>
            {Object.entries(factorObject)?.length > 1 ? (
              <input
                max={100}
                min={0}
                type="number"
                defaultValue={100}
                className="factor-weight-number"
                onChange={(e) =>
                  clientFactorsFunc(
                    e,
                    rating?.detailedInformation?.chance?.score,
                    'Chance of success'
                  )
                }
              />
            ) : (
              <div className="factor-weight-number">100%</div>
            )}
            {/* <input
            max={100}
            min={0}
            type="number"
            defaultValue={100}
            className="factor-weight-number"
            onChange={(e) =>
              clientFactorsFunc(
                e,
                rating?.detailedInformation?.chance?.score,
                'Chance of success'
              )
            }
          /> */}
            {/* <p className="factor-weight-number">100%</p> */}
          </section>
          <FactorProgress
            completed={rating?.detailedInformation?.chance?.score}
          />
        </div>
      ) : (
        //? ***************** SCREEN SMALLER THAN 599 *****************

        <div className="factors-considered">
          <section className="factor-info-section">
            <div
              className={cn('factor-bar', {
                factor_bar_border: chanceOpen,
              })}
            >
              <label>
                <input
                  type="checkbox"
                  name="chance-of-success"
                  checked={true}
                  onChange={changeHandler}
                  onClick={() => false}
                />
                <p className="factor-label">Chance of success</p>{' '}
                <FactorProgress
                  completed={rating?.detailedInformation?.chance?.score}
                />
                <p className="label-ep-number">
                  {rating?.detailedInformation?.chance?.score}
                </p>
              </label>
              {/* {rating?.detailedInformation?.factors?.length > 0 ? ( */}
              {Object.entries(factorObject)?.length > 1 ? (
                <input
                  max={100}
                  min={0}
                  type="number"
                  defaultValue={100}
                  className="factor-weight-number"
                  onChange={(e) =>
                    clientFactorsFunc(
                      e,
                      rating?.detailedInformation?.chance?.score,
                      'Chance of success'
                    )
                  }
                />
              ) : (
                <div className="factor-weight-number">100%</div>
              )}
              <button
                className={cn('factor-down-arrow', {
                  open_chance: openChance,
                })}
                onClick={(e) => openChance(e)}
              ></button>
            </div>
            {chanceOpen && (
              <div className="ft-exp-mobile">
                {card?.card?.text?.rating_updated === true && (
                <div className="factor-history-container" >
                  <div className="ft-open-title">Rating History</div>
                  <div className="factor-content factor-history">
                    <div className="factor-history-table">
                        <div className="factor-history-table-headers">
                            <div className="factor-history-table-date-column factor-history-header-table-column factor-label">
                              <img
                                src={require(`../../assets/Feather Icons/fi_calendar.png`)}
                                className="rating-updated-date-icon"
                                alt="Date Icon for Rating Updated"
                              />
                              Date
                            </div>
                            <div className="factor-history-table-score-column factor-history-header-table-column factor-label">Score</div>
                            <div className="factor-history-table-event-column factor-history-header-table-column factor-label">AFX Rating</div>
                        </div>
                        <div className="factor-history-table-body">
                          {card?.card?.text?.rating_timeline?.map((item, i) => {
                            if(item.factor === "chance_of_success") {
                              return (
                                <div className='factor-history-body-table-row' key={i}>
                                  <div className="factor-history-table-date-column factor-history-body-table-column">{dateConverter(item.date)}</div>
                                  <div className="factor-history-table-score-column factor-history-body-table-column">{item.score}</div>
                                  <div className="factor-history-table-event-column factor-history-body-table-column">{scoreConverter(item.score)}</div>
                                </div>
                              );
                            }
                          })}
                        </div>
                    </div>
                  </div>
                </div>
                )}
                <div className="mobile-spaced-paragraph">
                  <p className="ft-open-title">Explanation:</p>
                </div>
                <div className="mobile-spaced-paragraph">
                  {rating?.detailedInformation?.chance?.text}
                </div>
                <div className="modal-intro-paragraph">
                  <br />
                  To predict the chance of success at trial, our AI model is
                  trained on publicly available decisions. The training dataset
                  does not include cases with settlements, cases filed under
                  seal, other termination and case dismissals where the judicial
                  decisions were not observed. To learn more about the latest
                  predictive performance of our AI models, please refer to our{' '}
                  <_Modal
                    children={modalCoS}
                    inline={{
                      el: (
                        <span className="underline-animation data-table-modal-link">
                          chance of success model evaluation results.
                        </span>
                      ),
                    }}
                  />
                </div>
              </div>
            )}
          </section>
        </div>
      )}
      {/************************ SECTION END ************************/}

      {/************************ ADDITIONAL CHECKED FACTORS ************************/}
      {/************************ SECTION START ************************/}
      {rating?.detailedInformation?.factors?.length > 0 &&
        renderFactors &&
        rating?.detailedInformation?.factors?.map((ft, i) =>
          //? ***************** SCREEN BIGGER THAN 599 *****************
          width > 599 ? (
            <div
              className={cn('factors-considered', {
                show_factor: !renderFactors[ft?.factor_name],
              })}
              key={i}
            >
              <section className="factor-info-section">
                <label>
                  <input
                    type="checkbox"
                    name={ft?.factor_name}
                    onClick={(e) => factorCheck2(ft?.factor_name, e)}
                    checked={true}
                    onChange={changeHandler}
                  />
                  <p className="factor-label">{ft?.factor_name}</p>{' '}
                  <p className="label-ep-number">{ft?.score}</p>
                </label>
                {/* <p className="factor-weight-number"></p> */}
                <input
                  max={100}
                  min={0}
                  type="number"
                  defaultValue={100}
                  className="factor-weight-number"
                  onChange={(e) =>
                    clientFactorsFunc(e, ft?.score, ft?.factor_name)
                  }
                />
              </section>
              <FactorProgress completed={ft?.score} membership={membership} />
            </div>
          ) : (
            //? ***************** SCREEN SMALLER THAN 599 *****************
            <div
              className={cn('factors-considered', {
                show_factor: !renderFactors[ft?.factor_name],
              })}
              key={i}
            >
              <section className="factor-info-section">
                <div
                  className={cn('factor-bar', {
                    factor_bar_border: factorOpen[i]?.open,
                  })}
                >
                  <label>
                    <input
                      type="checkbox"
                      name={ft?.factor_name}
                      onClick={(e) => factorCheck2(ft?.factor_name, e)}
                      checked={true}
                      onChange={changeHandler}
                    />
                    <p className="factor-label">{ft?.factor_name}</p>{' '}
                    <FactorProgress completed={ft?.score} />
                    <p className="label-ep-number">{ft?.score}</p>
                  </label>
                  {/* <p className="factor-weight-number"></p> */}
                  <input
                    max={100}
                    min={0}
                    type="number"
                    defaultValue={100}
                    className="factor-weight-number"
                    onChange={(e) =>
                      clientFactorsFunc(e, ft?.score, ft?.factor_name)
                    }
                  />
                  <button
                    className={cn('factor-down-arrow', {
                      open_chance: factorOpen[i]?.open,
                    })}
                    onClick={(e) => openFactor(e, i)}
                  ></button>
                </div>

                {loadFactor && factorOpen[i].open && (
                  <div className="ft-exp-mobile">
                    <p className="ft-open-title">Explanation:</p>
                    <div className="mobile-spaced-paragraph">{ft?.text}</div>
                  </div>
                )}
              </section>
            </div>
          )
        )}
      {/************************ SECTION END ************************/}

      {/************************ UNCHECKED FACTORS - ADD FACTORS ************************/}
      {/************************ SECTION START ************************/}
      {rating?.detailedInformation?.factors.length > 0 && (
        <div className="add-factors-div-container">
          <section className="add-factors-section">
            <p className="add-factors">ADD FACTORS TO CONSIDERATION</p>{' '}
          </section>
          {/************************ ADD FACTORS************************/}
          {membership !== 'Free' &&
            rating?.detailedInformation?.factors?.length > 0 &&
            rating?.detailedInformation?.factors.map((ft, i) =>
              width > 599 ? (
                //? ***************** SCREEN BIGGER THAN 599 *****************
                <div
                  className={cn('factors-considered', {
                    show_factor: renderFactors[ft?.factor_name],
                    no_show_factor: !renderFactors[ft?.factor_name],
                  })}
                  key={i}
                >
                  <section className="factor-info-section">
                    <label>
                      <input
                        type="checkbox"
                        name={ft?.factor_name}
                        onClick={(e) =>
                          factorCheck(ft?.factor_name, ft?.score, e)
                        }
                        onChange={changeHandler}
                        checked={false}
                      />
                      <p className="factor-label">{ft?.factor_name}</p>{' '}
                      <p className="label-ep-number">{ft?.score}</p>
                    </label>
                    <p className="factor-weight-number">0%</p>
                  </section>
                  <FactorProgress completed={ft?.score} />
                </div>
              ) : (
                //? ***************** SCREEN SMALLER THAN 599 *****************
                <div
                  className={cn('factors-considered', {
                    show_factor: renderFactors[ft?.factor_name],
                    no_show_factor: !renderFactors[ft?.factor_name],
                  })}
                  key={i}
                >
                  <section className="factor-info-section">
                    <div
                      className={cn('factor-bar', {
                        factor_bar_border: factorOpen[i]?.open,
                      })}
                    >
                      <label>
                        <input
                          type="checkbox"
                          name={ft?.factor_name}
                          onClick={(e) =>
                            factorCheck(ft?.factor_name, ft?.score, e)
                          }
                          onChange={changeHandler}
                          checked={false}
                        />
                        <p className="factor-label">{ft?.factor_name}</p>{' '}
                        <FactorProgress completed={ft?.score} />
                        <p className="label-ep-number">{ft?.score}</p>
                      </label>
                      <p className="factor-weight-number">0%</p>
                      <button
                        className={cn('factor-down-arrow', {
                          open_chance: factorOpen[i]?.open,
                        })}
                        onClick={(e) => openFactor(e, i)}
                      ></button>
                    </div>
                    {loadFactor && factorOpen[i].open && (
                      <div className="ft-exp-mobile">
                        <p className="ft-open-title">Explanation:</p>
                        <div className="mobile-spaced-paragraph">
                          {ft?.text}
                        </div>
                      </div>
                    )}
                  </section>
                </div>
              )
            )}
          {/************************ SECTION END ************************/}

          {/************************ FREE MEMBERSHIP *********************** */}
          {/************************ SECTION START ************************/}
          {membership === 'Free' &&
            rating?.detailedInformation?.factors?.length > 0 &&
            rating?.detailedInformation?.factors.map((ft, i) => (
              <div
                className={cn('factors-considered', {
                  show_factor: renderFactors[ft?.factor_name],
                  no_show_factor: !renderFactors[ft?.factor_name],
                  is_free: membership === 'Free',
                })}
                key={i}
              >
                <section className="factor-info-section">
                  <div className="free-label">
                    <div
                      // type="checkbox"
                      className="free-check"
                      name={ft?.factor_name}
                      // onClick={(e) => factorCheck(ft?.factor_name, ft?.score, e)}
                      onChange={changeHandler}
                      checked={false}
                    />
                    <p className="factor-label">{ft?.factor_name}</p>{' '}
                    <p className="label-ep-number">XX</p>
                  </div>
                  <p className="factor-weight-number">0%</p>
                </section>
                <FactorProgress completed={0} />
              </div>
            ))}
          {/************************ SECTION END ************************/}

          <button className="rating-calc-button" onClick={(e) => handleCalc(e)}>
            Recalculate AFX Rating
            {/* <ToastContainer
              className="case-toast"
              progressClassName="case-toast-progress"
            /> */}
          </button>
        </div>
      )}
    </>
  );
};

export default FactorsConsidered;
