import React, {
  useCallback,
  useLayoutEffect,
  useEffect,
  useState,
  useRef,
} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const DoubleSlider = ({ min, max, setMinClaim, setMaxClaim, bigObj }) => {
  // Set min and max values form props

  const [minVal, setMinVal] = useState(bigObj?.min_claim);
  const [maxVal, setMaxVal] = useState(bigObj?.max_claim);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  useLayoutEffect(() => {
    setMinVal(bigObj?.min_claim);
    setMaxVal(bigObj?.max_claim);
  }, [bigObj]);


  //? Memoize values with useCallback and convert to percentage
  // const getPercent = useCallback(
  //   (value) => Math.round(((value - min) / (max - min)) * 100),
  //   [min, max]
  // );

  //? Set width of the range to decrease from the left side
  // useEffect(() => {
  //   if (maxValRef.current) {
  //     const minPercent = getPercent(minVal);
  //     const maxPercent = getPercent(maxValRef.current.value); // Check min and use maxValRef i.e. no dependency change

  //? If range we set the stlyes for left and range
  //     if (range.current) {
  //       range.current.style.left = `${minPercent}%`;
  //       range.current.style.width = `${maxPercent - minPercent}%`;
  //     }
  //   }
  // }, [minVal, getPercent]);

  //? Set width of the range to decrease from the right side
  // useEffect(() => {
  //   if (minValRef.current) {
  //     const minPercent = getPercent(minValRef.current.value);
  //     const maxPercent = getPercent(maxVal);

  //     if (range.current) {
  //       range.current.style.width = `${maxPercent - minPercent}%`;
  //     }
  //   }
  // }, [maxVal, getPercent]);

  // onChange functions to set min/max Val
  const changeMinValue = (e) => {
    // const value = Math.min(e.target.value, maxVal - 1);
    let split = e.target.value.split(',');
    let str = '';
    split.forEach((el) => {
      str += el;
    });
    const value = Number(str);
    setMinVal(value);
    setMinClaim(value);
  };

  const changeMaxValue = (e) => {
    let split = e.target.value.split(',');
    let str = '';
    split.forEach((el) => {
      str += el;
    });
    const value = Number(str);
    setMaxVal(value);
    setMaxClaim(value);
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handleValue = (val) => {
    return val?.toLocaleString();
  };
  return (
    <>
      {/* <div className="double-range-container">
          <input
            type="range"
            min={min}
            max={max}
            value={minVal}
            step="500000"
            ref={minValRef}
            onChange={(e) => changeMinValue(e)}
            className={cn("thumb thumb--zindex-3", {
              "thumb--zindex-5": minVal > max - 100,
            })}
          />
          <input
            type="range"
            min={min}
            max={max}
            value={maxVal}
            ref={maxValRef}
            onChange={(e) => changeMaxValue(e)}
            className="thumb thumb--zindex-4"
          />
          <div className="slider">
            <div className="slider__track" />
            <div ref={range} className="slider__range" />
          </div>
        </div> */}
      <div className="case-type-container">
        <label className="filter-range-container">
          <p className="filter-section-subtitle">min</p>
          <span className="currency-input">
            $
            <input
              // step="100000"
              className="min-max-container"
              value={handleValue(minVal)}
              ref={minValRef}
              onChange={(e) => changeMinValue(e)}
              maxLength="14"
              onFocus={handleFocus}
            />
          </span>
        </label>
        <div className="filter-range-separator">
          <div className="separator-hyphen-container">
            <span></span>
          </div>
        </div>
        <label className="filter-range-container">
          <p className="filter-section-subtitle">max</p>
          <span className="currency-input">
            $
            <input
              className="min-max-container"
              value={handleValue(maxVal)}
              ref={maxValRef}
              onChange={(e) => changeMaxValue(e)}
              maxLength="14"
              onFocus={handleFocus}
            />
          </span>
        </label>
      </div>
    </>
  );
};

DoubleSlider.propTypes = {
  // min: PropTypes.number.isRequired,
  // max: PropTypes.string.isRequired,
  // onChange: PropTypes.func.isRequired,
};

export default DoubleSlider;
