const ADD_FACTORS = 'client-factors/ADD_FACTORS';

//******ACTIONS******//

export const addFactors = (factor) => ({
  type: ADD_FACTORS,
  factor,
});

//******THUNKS******//

export const addFactorsThunk = (factor) => async (dispatch) => {
  // CHECK IF ADD TO 100
  dispatch(addFactors(factor));
  return factor;
};

//******REDUCER******//

const initialState = {};

const clientFactorsReducer = (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case ADD_FACTORS:
      for (const [key, value] of Object.entries(action.factor)) {
        newState[key] = value;
      }
      return {
        ...newState,
      };
    default:
      return state;
  }
};
export default clientFactorsReducer;
