// Terms of Use Modal
const modalToU = () => {
  return (
    <>
      <div className="modal-title-element">Terms of Use</div>
      <div className="modal-body-element policy-modal">
        Effective date: 08/05/2019
        <br />
        <br />
        Welcome to ArbiLex. Please read on to learn the rules and restrictions
        that govern your use of our website(s), products, services and
        applications (the “Services”). If you have any questions, comments, or
        concerns regarding these terms or the Services, please contact us at:
        <br />
        <br />
        Email: <a href="mailto: support@arbilex.co">support@arbilex.co</a>
        <br />
        Address: Batten Hall, 125 Western Ave Allston, MA 02163
        <br />
        <br />
        These Terms of Use (the “Terms”) are a binding contract between you and{' '}
        <strong>ARBI, INC.</strong> (d/b/a ArbiLex) (“ArbiLex,” “we” and “us”).
        Your use of the Services in any way means that you agree to all of these
        Terms, and these Terms will remain in effect while you use the Services.
        These Terms include the provisions in this document as well as those in
        the Privacy Policy and Acceptable Use Policy.{' '}
        <strong>
          Your use of or participation in certain Services may also be subject
          to additional policies, rules and/or conditions (“Additional Terms”),
          which are incorporated herein by reference, and you understand and
          agree that by using or participating in any such Services, you agree
          to also comply with these Additional Terms.{' '}
        </strong>
        <br />
        <br />
        <strong>Please read these Terms carefully</strong>. They cover important
        information about Services provided to you and any charges, taxes, and
        fees we bill you.{' '}
        <strong>
          These Terms include information about&nbsp; future changes to these
          Terms,&nbsp; automatic renewals,&nbsp; limitations of liability,&nbsp;
          a class action waiver and resolution of disputes by arbitration
          instead of in court. PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR
          SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO
          ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY
          MANNER.
        </strong>
        <br />
        <br />
        <strong>ARBITRATION NOTICE AND CLASS ACTION WAIVER:</strong> EXCEPT FOR
        CERTAIN TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION AGREEMENT SECTION
        BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY
        BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE
        IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
        <br />
        <br />
        <strong>
          <u>Will these Terms ever change?</u>
        </strong>
        <br />
        <br />
        We are constantly trying to improve our Services, so these Terms may
        need to change along with our Services. We reserve the right to change
        the Terms at any time, but if we do, we will place a notice on our site
        located at arbilex.co, send you an email, and/or notify you by some
        other means.
        <br />
        <br />
        If you don’t agree with the new Terms, you are free to reject them;
        unfortunately, that means you will no longer be able to use the
        Services. If you use the Services in any way after a change to the Terms
        is effective, that means you agree to all of the changes.
        <br />
        <br />
        Except for changes by us as described here, no other amendment or
        modification of these Terms will be effective unless in writing and
        signed by both you and us.
        <br />
        <br />
        <strong>
          <u>What about my privacy?</u>
        </strong>
        <br />
        <br />
        ArbiLex takes the privacy of its users very seriously. For the current
        ArbiLex Privacy Policy, please open the privacy policy.
        <br />
        <br />
        The Services are intended by ArbiLex to be made available only to users
        who are at least 18 years old. We do not knowingly collect or solicit
        personally identifiable information from children under thirteen (13);
        if you are a child under thirteen (13), please do not attempt to
        register for or otherwise use the Services or send us any personal
        information. If we learn we have collected personal information from a
        child under thirteen (13), we will delete that information as quickly as
        possible. If you believe that a child under thirteen (13) may have
        provided us personal information, please contact us at{' '}
        <a href="mailto: support@arbilex.co">support@arbilex.co</a>.
        <br />
        <br />
        <u>What are the basics of using ArbiLex?</u>
        <br />
        <br />
        You may be required to sign up for an account, select a password and
        user name (“ArbiLex User ID”), and provide us with certain information
        or data, such as your contact information, in order for us to register
        such an account for you. You may not select as your ArbiLex User ID a
        name that you do not have the right to use, or another person’s name
        with the intent to impersonate that person. You promise to provide us
        with accurate, complete, and updated registration information about
        yourself. You may not transfer your account to anyone else without our
        prior written permission.
        <br />
        <br />
        You represent and warrant that you are an individual of legal age to
        form a binding contract. If you’re agreeing to these Terms on behalf of
        an organization or entity, you represent and warrant that you are
        authorized to agree to these Terms on that organization’s or entity’s
        behalf and bind them to these Terms (in which case, the references to
        “you” and “your” in these Terms, except for in this sentence, refer to
        that organization or entity).
        <br />
        <br />
        You will only use the Services for your own personal, internal use, and
        not on behalf of or for the benefit of any third party, and only in a
        manner that complies with all laws that apply to you. If your use of the
        Services is prohibited by applicable laws, then you aren’t authorized to
        use the Services. We can’t and won’t be responsible for your using the
        Services in a way that breaks the law.
        <br />
        <br />
        You will not share your ArbiLex User ID, account or password with
        anyone, and you must protect the security of your ArbiLex User ID,
        account and your password and any other access tools or credentials.
        You’re responsible for any activity associated with your ArbiLex User ID
        and account.
        <br />
        <br />
        <strong>
          <u>What about messaging?</u>
        </strong>
        <br />
        <br />
        As part of the Services, you may receive communications through the
        Services, including messages that ArbiLex sends you (for example, via
        email). When signing up for the Services, you will receive a welcome
        message and instructions on how to stop receiving messages.
        <br />
        <br />
        <strong>
          <u>Are there restrictions in how I can use the Services?</u>
        </strong>
        <br />
        <br />
        You represent, warrant, and agree that you will not contribute any
        Content or User Submission (each of those terms is defined below) or
        otherwise use the Services or interact with the Services in a manner
        that:
        <ol type="a">
          <li>
            infringes or violates the intellectual property rights or any other
            rights of anyone else (including ArbiLex);
          </li>
          <li>
            violates any law or regulation, including, without limitation, any
            applicable export control laws, privacy laws or any other purpose
            not reasonably intended by ArbiLex;
          </li>
          <li>
            is dangerous, harmful, fraudulent, deceptive, threatening,
            harassing, defamatory, obscene, or otherwise objectionable;
          </li>
          <li>
            jeopardizes the security of your ArbiLex User ID, account or anyone
            else’s (such as allowing someone else to log in to the Services as
            you);
          </li>
          <li>
            attempts, in any manner, to obtain the password, account, or other
            security information from any other user;
          </li>
          <li>
            violates the security of any computer network, or cracks any
            passwords or security encryption codes;
          </li>
          <li>
            runs Maillist, Listserv, any form of auto-responder or “spam” on the
            Services, or any processes that run or are activated while you are
            not logged into the Services, or that otherwise interferes with the
            proper working of the Services (including by placing an unreasonable
            load on the Services’ infrastructure);
          </li>
          <li>
            “crawls,” “scrapes,” or “spiders” any page, data, or portion of or
            relating to the Services or Content (through use of manual or
            automated means);
          </li>
          <li>copies or stores any significant portion of the Content; or</li>
          <li>
            decompiles, reverse engineers, or otherwise attempts to obtain the
            source code or underlying ideas or information of or relating to the
            Services.
          </li>
        </ol>
        <br />
        <br />
        A violation of any of the foregoing is grounds for termination of your
        right to use or access the Services.
        <br />
        <br />
        <strong>
          <u>What are my rights in the Services?</u>
        </strong>
        <br />
        <br />
        The materials displayed or performed or available on or through the
        Services, including, but not limited to, text, graphics, data, articles,
        photos, images, illustrations and so forth (all of the foregoing, the
        “Content”) are protected by copyright and/or other intellectual property
        laws. You promise to abide by all copyright notices, trademark rules,
        information, and restrictions contained in any Content you access
        through the Services, and you won’t use, copy, reproduce, modify,
        translate, publish, broadcast, transmit, distribute, perform, upload,
        display, license, sell, commercialize or otherwise exploit for any
        purpose any Content not owned by you, (i) without the prior consent of
        the owner of that Content or (ii) in a way that violates someone else’s
        (including ArbiLex's) rights.
        <br />
        <br />
        Subject to these Terms, we grant each user of the Services a worldwide,
        non-exclusive, non-sublicensable and non-transferable license to use
        (i.e., to download and display locally) Content solely for purposes of
        using the Services. Except as expressly permitted in these Terms, use,
        reproduction, modification, distribution or storage of any Content for
        other than purposes of using the Services is expressly prohibited
        without prior written permission from us. You understand that ArbiLex
        owns the Services. You won’t modify, publish, transmit, participate in
        the transfer or sale of, reproduce (except as expressly provided in this
        Section), create derivative works based on, or otherwise exploit any of
        the Services. The Services may allow you to copy or download certain
        Content, but please remember that even where these functionalities
        exist, all the restrictions in this section still apply.
        <br />
        <br />
        <strong>
          <u>Who is responsible for what I see and do on the Services?</u>
        </strong>
        <br />
        <br />
        Any information or Content publicly posted or privately transmitted
        through the Services is the sole responsibility of the person from whom
        such Content originated, and you access all such information and Content
        at your own risk, and we aren’t liable for any errors or omissions in
        that information or Content or for any damages or loss you might suffer
        in connection with it. We cannot control and have no duty to take any
        action regarding how you may interpret and use the Content or what
        actions you may take as a result of having been exposed to the Content,
        and you hereby release us from all liability for you having acquired or
        not acquired Content through the Services. We can’t guarantee the
        identity of any users with whom you interact in using the Services and
        are not responsible for which users gain access to the Services.
        <br />
        <br />
        You are responsible for all Content you contribute, in any manner, to
        the Services, and you represent and warrant you have all rights
        necessary to do so, in the manner in which you contribute it.
        <br />
        <br />
        The Services may contain links or connections to third-party websites or
        services that are not owned or controlled by ArbiLex. When you access
        third-party websites or use third-party services, you accept that there
        are risks in doing so, and that ArbiLex is not responsible for such
        risks.
        <br />
        <br />
        ArbiLex has no control over, and assumes no responsibility for, the
        content, accuracy, privacy policies, or practices of or opinions
        expressed in any third-party websites or by any third party that you
        interact with through the Services. In addition, ArbiLex will not and
        cannot monitor, verify, censor or edit the content of any third-party
        site or service. We encourage you to be aware when you leave the
        Services and to read the terms and conditions and privacy policy of each
        third-party website or service that you visit or utilize. By using the
        Services, you release and hold us harmless from any and all liability
        arising from your use of any third-party website or service.
        <br />
        <br />
        Your interactions with organizations and/or individuals found on or
        through the Services, including payment and delivery of goods or
        services, and any other terms, conditions, warranties or representations
        associated with such dealings, are solely between you and such
        organizations and/or individuals. You should make whatever investigation
        you feel necessary or appropriate before proceeding with any online or
        offline transaction with any of these third parties. You agree that
        ArbiLex shall not be responsible or liable for any loss or damage of any
        sort incurred as the result of any such dealings.
        <br />
        <br />
        If there is a dispute between participants on this site or Services, or
        between users and any third party, you agree that ArbiLex is under no
        obligation to become involved. In the event that you have a dispute with
        one or more other users, you release ArbiLex, its directors, officers,
        employees, agents, and successors from claims, demands, and damages of
        every kind or nature, known or unknown, suspected or unsuspected,
        disclosed or undisclosed, arising out of or in any way related to such
        disputes and/or our Services. You shall and hereby do waive California
        Civil Code Section 1542 or any similar law of any jurisdiction, which
        says in substance: “A general release does not extend to claims that the
        creditor or releasing party does not know or suspect to exist in his or
        her favor at the time of executing the release and that, if known by him
        or her, would have materially affected his or her settlement with the
        debtor or released party.”
        <br />
        <br />
        <strong>
          <u>Will ArbiLex ever change the Services?</u>
        </strong>
        <br />
        <br />
        We’re always trying to improve our Services, so they may change over
        time. We may suspend or discontinue any part of the Services, or we may
        introduce new features or impose limits on certain features or restrict
        access to parts or all of the Services. We’ll try to give you notice
        when we make a material change to the Services that would adversely
        affect you, but this isn’t always practical. We reserve the right to
        remove any Content from the Services at any time, for any reason
        (including, but not limited to, if someone alleges you contributed that
        Content in violation of these Terms), in our sole discretion, and
        without notice.
        <br />
        <br />
        <strong>
          <u>Do the Services cost anything?</u>
        </strong>
        <br />
        <br />
        We may charge a fee for using the Services. We will notify you before
        any Services you are then using begin carrying a fee, and if you wish to
        continue using such Services, you must pay all applicable fees for such
        Services. Note that if you elect to receive text messages through the
        Services, data and message rates may apply. Any and all such charges,
        fees or costs are your sole responsibility. You should consult with your
        wireless carrier to determine what rates, charges, fees or costs may
        apply to your use of the Services. Certain of our Services may be
        subject to payments now or in the future (the “Paid Services”). Please
        see our Paid Services page for a description of the current Paid
        Services. Please note that any payment terms presented to you in the
        process of using or signing up for a Paid Service are deemed part of
        these Terms.
        <br />
        <br />
        <strong>
          <u>What if I want to stop using the Services?</u>
        </strong>
        <br />
        <br />
        You’re free to do that at any time by contacting us at{' '}
        <a href="mailto: support@arbilex.co">support@arbilex.co</a>; please
        refer to our Privacy Policy, as well as the licenses above, to
        understand how we treat information you provide to us after you have
        stopped using our Services.
        <br />
        <br />
        ArbiLex is also free to terminate (or suspend access to) your use of the
        Services or your account for any reason in our discretion, including
        your breach of these Terms. ArbiLex has the sole right to decide whether
        you are in violation of any of the restrictions set forth in these
        Terms.
        <br />
        <br />
        Account termination may result in destruction of any Content associated
        with your account, so keep that in mind before you decide to terminate
        your account.
        <br />
        <br />
        If you have deleted your account by mistake, contact us immediately at{' '}
        <a href="mailto: support@arbilex.co">support@arbilex.co</a> – we will
        try to help, but unfortunately, we can’t promise that we can recover or
        restore anything.
        <br />
        <br />
        Provisions that, by their nature, should survive termination of these
        Terms shall survive termination. By way of example, all of the following
        will survive termination: any obligation you have to pay us or indemnify
        us, any limitations on our liability, any terms regarding ownership or
        intellectual property rights, and terms regarding disputes between us,
        including without limitation the arbitration agreement.
        <br />
        <br />
        <strong>
          <u>What else do I need to know?</u>
        </strong>
        <br />
        <br />
        <u>
          <i>Warranty Disclaimer</i>
        </u>
        . ArbiLex and its licensors, suppliers, partners, parent, subsidiaries
        or affiliated entities, and each of their respective officers,
        directors, members, employees, consultants, contract employees,
        representatives and agents, and each of their respective successors and
        assigns (ArbiLex and all such parties together, the “ArbiLex Parties”)
        make no representations or warranties concerning the Services, including
        without limitation regarding any Content contained in or accessed
        through the Services, and the ArbiLex Parties will not be responsible or
        liable for the accuracy, copyright compliance, legality, or decency of
        material contained in or accessed through the Services or any claims,
        actions, suits procedures, costs, expenses, damages or liabilities
        arising out of use of, or in any way related to your participation in,
        the Services. The ArbiLex Parties make no representations or warranties
        regarding suggestions or recommendations of services or products offered
        or purchased through or in connection with the Services. THE SERVICES
        AND CONTENT ARE PROVIDED BY ARBILEX (AND ITS LICENSORS AND SUPPLIERS) ON
        AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
        IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR
        THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME
        STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS,
        SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
        <br />
        <br />
        <u>
          <i>Limitation of Liability</i>
        </u>
        . TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO
        CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION,
        TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE ARBILEX
        PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT,
        SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND,
        INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA,
        LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER
        FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS, SERVICES OR
        TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER
        OF (I) ONE-HUNDRED ($100) DOLLARS OR (II) THE AMOUNTS PAID AND/OR
        PAYABLE BY YOU TO ARBILEX IN CONNECTION WITH THE SERVICES IN THE TWELVE
        (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM OR (D) ANY MATTER
        BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR
        LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO
        THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
        <br />
        <br />
        <u>
          <i>Indemnity</i>
        </u>
        . You agree to indemnify and hold the ArbiLex Parties harmless from and
        against any and all claims, liabilities, damages (actual and
        consequential), losses and expenses (including attorneys’ fees) arising
        from or in any way related to any claims relating to (a) your use of the
        Services (including any actions taken by a third party using your
        account), and (b) your violation of these Terms.
        <br />
        <br />
        <u>
          <i>Assignment</i>
        </u>
        . You may not assign, delegate or transfer these Terms or your rights or
        obligations hereunder, or your Services account, in any way (by
        operation of law or otherwise) without ArbiLex's prior written consent.
        We may transfer, assign, or delegate these Terms and our rights and
        obligations without consent.
        <br />
        <br />
        <u>
          <i>Choice of Law</i>
        </u>
        . These Terms are governed by and will be construed under the Federal
        Arbitration Act, applicable federal law, and the laws of the
        Commonwealth of Massachusetts, without regard to the conflicts of laws
        provisions thereof.
        <br />
        <br />
        <u>
          <i>Arbitration Agreement</i>
        </u>
        . Please read the following ARBITRATION AGREEMENT carefully because it
        requires you to arbitrate certain disputes and claims with ArbiLex and
        limits the manner in which you can seek relief from ArbiLex. Both you
        and ArbiLex acknowledge and agree that for the purposes of any dispute
        arising out of or relating to the subject matter of these Terms,
        ArbiLex's officers, directors, employees and independent contractors
        (“Personnel”) are third-party beneficiaries of these Terms, and that
        upon your acceptance of these Terms, Personnel will have the right (and
        will be deemed to have accepted the right) to enforce these Terms
        against you as the third-party beneficiary hereof.
        <ol type="a">
          <li>
            <i>
              <u>Arbitration Rules; Applicability of Arbitration Agreement</u>
            </i>
            . The parties shall use their best efforts to settle any dispute,
            claim, question, or disagreement arising out of or relating to the
            subject matter of these Terms directly through good-faith
            negotiations, which shall be a precondition to either party
            initiating arbitration. If such negotiations do not resolve the
            dispute, it shall be finally settled by binding arbitration in
            Middlesex County, Massachusetts. The arbitration will proceed in the
            English language, in accordance with the JAMS Streamlined
            Arbitration Rules and Procedures (the “Rules”) then in effect, by
            one commercial arbitrator with substantial experience in resolving
            intellectual property and commercial contract disputes. The
            arbitrator shall be selected from the appropriate list of JAMS
            arbitrators in accordance with such Rules. Judgment upon the award
            rendered by such arbitrator may be entered in any court of competent
            jurisdiction.{' '}
          </li>
          <li>
            <i>
              <u>Costs of Arbitration</u>
            </i>
            . The Rules will govern payment of all arbitration fees. ArbiLex
            will pay all arbitration fees for claims less than seventy-five
            thousand ($75,000) dollars. ArbiLex will not seek its attorneys’
            fees and costs in arbitration unless the arbitrator determines that
            your claim is frivolous.{' '}
          </li>
          <li>
            <i>
              <u>Small Claims Court; Infringement</u>
            </i>
            . Either you or ArbiLex may assert claims, if they qualify, in small
            claims court in Middlesex County, Massachusetts or any United States
            county where you live or work. Furthermore, notwithstanding the
            foregoing obligation to arbitrate disputes, each party shall have
            the right to pursue injunctive or other equitable relief at any
            time, from any court of competent jurisdiction, to prevent the
            actual or threatened infringement, misappropriation or violation of
            a party's copyrights, trademarks, trade secrets, patents or other
            intellectual property rights.
          </li>
          <li>
            <i>
              <u>Waiver of Jury Trial</u>
            </i>
            . YOU AND ARBILEX WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO
            GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and
            ArbiLex are instead choosing to have claims and disputes resolved by
            arbitration. Arbitration procedures are typically more limited, more
            efficient, and less costly than rules applicable in court and are
            subject to very limited review by a court. In any litigation between
            you and ArbiLex over whether to vacate or enforce an arbitration
            award, YOU AND ARBILEX WAIVE ALL RIGHTS TO A JURY TRIAL, and elect
            instead to have the dispute be resolved by a judge.
          </li>
          <li>
            <i>
              <u>Waiver of Class or Consolidated Actions</u>
            </i>
            . ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
            AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND
            NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER
            CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE
            OF ANY OTHER CUSTOMER OR USER. If however, this waiver of class or
            consolidated actions is deemed invalid or unenforceable, neither you
            nor ArbiLex is entitled to arbitration; instead all claims and
            disputes will be resolved in a court as set forth in (g) below.
          </li>
          <li>
            <i>
              <u>Opt-out</u>
            </i>
            . You have the right to opt out of the provisions of this Section by
            sending written notice of your decision to opt out to the following
            address: Batten Hall, 125 Western Ave Allston, MA 02163 postmarked
            within thirty (30) days of first accepting these Terms. You must
            include (i) your name and residence address, (ii) the email address
            and/or telephone number associated with your account, and (iii) a
            clear statement that you want to opt out of these Terms’ arbitration
            agreement.
          </li>
          <li>
            <i>
              <u>Exclusive Venue</u>
            </i>
            . If you send the opt-out notice in (f), and/or in any circumstances
            where the foregoing arbitration agreement permits either you or
            ArbiLex to litigate any dispute arising out of or relating to the
            subject matter of these Terms in court, then the foregoing
            arbitration agreement will not apply to either party, and both you
            and ArbiLex agree that any judicial proceeding (other than small
            claims actions) will be brought in the state or federal courts
            located in, respectively, Middlesex County, Massachusetts, or the
            federal district in which that county falls.{' '}
          </li>
          <li>
            <i>
              <u>Severability</u>
            </i>
            . If the prohibition against class actions and other claims brought
            on behalf of third parties contained above is found to be
            unenforceable, then all of the preceding language in this
            Arbitration Agreement section will be null and void. This
            arbitration agreement will survive the termination of your
            relationship with ArbiLex.
          </li>
        </ol>
        <br />
        <br />
        <i>
          <u>Miscellaneous</u>
        </i>
        . You will be responsible for paying, withholding, filing, and reporting
        all taxes, duties, and other governmental assessments associated with
        your activity in connection with the Services, provided that the ArbiLex
        may, in its sole discretion, do any of the foregoing on your behalf or
        for itself as it sees fit. The failure of either you or us to exercise,
        in any way, any right herein shall not be deemed a waiver of any further
        rights hereunder. If any provision of these Terms are found to be
        unenforceable or invalid, that provision will be limited or eliminated,
        to the minimum extent necessary, so that these Terms shall otherwise
        remain in full force and effect and enforceable. You and ArbiLex agree
        that these Terms are the complete and exclusive statement of the mutual
        understanding between you and ArbiLex, and that these Terms supersede
        and cancel all previous written and oral agreements, communications and
        other understandings relating to the subject matter of these Terms. You
        hereby acknowledge and agree that you are not an employee, agent,
        partner, or joint venture of ArbiLex, and you do not have any authority
        of any kind to bind ArbiLex in any respect whatsoever.
        <br />
        <br />
        Except as expressly set forth in the section above regarding the
        arbitration agreement, you and ArbiLex agree there are no third-party
        beneficiaries intended under these Terms.
      </div>
    </>
  );
};

export default modalToU;
