const SET_USER = 'session/SET_USER';
const GET_USER = 'session/GET_USER';
const REMOVE_USER = 'session/REMOVE_USER';
const INTEREST = 'session/INTEREST';
const REFRESH = 'session/REFRESH';

//******ACTIONS******//

export const refresh = (token) => ({
  type: REFRESH,
  payload: token,
});

export const interest = (data) => ({
  type: INTEREST,
  payload: data,
});

const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

const getUser = (user) => ({
  type: GET_USER,
  payload: user,
});

const removeUser = () => ({
  type: REMOVE_USER,
});

//******THUNKS******//

export const refreshToken = (token) => async (dispatch) => {
  // const response = await fetch('http://3.237.199.50:8000/user/refresh?refresh_token=${token}', {
  const response = await fetch(
    `https://d1i2jq400lm6rf.cloudfront.net/user/refresh?refresh_token=${token}`,
    {
      // const response = await fetch('http://127.0.0.1:8000/user/refresh?refresh_token=${token}', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  // Action created for refreshToken dispatch currently not necessary/used because tokens are already in state
  if (response.ok) {
    let data = await response.json();
    localStorage.setItem(
      '_uA',
      JSON.stringify({
        access: data?.data?.access_tokens,
        refresh: data?.data?.refresh_token,
      })
    );
    return data;
  } else {
    return response.json();
  }
};

export const persistUser = (token) => async (dispatch) => {
  // const response = await fetch('http://3.237.199.50:8000/user/', {
  const response = await fetch('https://d1i2jq400lm6rf.cloudfront.net/user', {
    // const response = await fetch('http://127.0.0.1:8000/user/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.ok) {
    let user = await response.json();
    dispatch(getUser(user));
    let arr = [];
    user?.data?.user_interest.forEach((el, i) => arr.push(el.card));
    dispatch(interest(arr));
    return user;
  } else {
    dispatch(removeUser());
    localStorage.removeItem('_uA');
    return response;
  }
};

export const login =
  ({ email, password }) =>
  async (dispatch) => {
    // const response = await fetch('http://3.237.199.50:8000/user/login', {
    const response = await fetch(
      'https://d1i2jq400lm6rf.cloudfront.net/user/login',
      {
        // const response = await fetch('http://127.0.0.1:8000/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      dispatch(setUser(data?.data));

      localStorage.setItem(
        '_uA',
        JSON.stringify({
          access: data?.data?.access,
          refresh: data?.data?.refresh,
          // exp: data?.data?.expiry,
        })
      );
      // localStorage.setItem('exp', JSON.stringify(data?.data?.expiry));
      return data;
    } else if (response.status < 500) {
      const data = await response.json();

      return data;
    }
  };

export const logout =
  ({ token }) =>
  async (dispatch) => {
    // const response = await fetch('http://3.237.199.50:8000/user/logout',
    const response = await fetch(
      'https://d1i2jq400lm6rf.cloudfront.net/user/logout',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.ok) {
      dispatch(removeUser());
      localStorage.removeItem('_uA');
      return;
    } else {
      dispatch(removeUser());
      localStorage.removeItem('_uA');
      return;
    }
  };

export const signUp = (username, email, password) => async (dispatch) => {
  // const response = await fetch('http://127.0.0.1:8000/user/signup/', {
  // const response = await fetch(
  //   'https://d1i2jq400lm6rf.cloudfront.net/user/signup',
  //   {
  const response = await fetch(
    'https://d1i2jq400lm6rf.cloudfront.net/user/signup',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: username,
        email: email,
        password: password,
      }),
    }
  );

  if (response.ok) {
    const res = await fetch(
      'https://d1i2jq400lm6rf.cloudfront.net/user/login',
      {
        // const res = await fetch('http://127.0.0.1:8000/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      }
    );
    if (res.ok) {
      const data = await res.json();
      dispatch(setUser(data.data));
      localStorage.setItem(
        '_uA',
        JSON.stringify({
          access: data.data.access,
          refresh: data.data.refresh,
        })
      );

      return data;
    } else if (res.status < 500) {
      const data = await res.json();
      if (data.errors) {
        return data.errors;
      }
    } else {
      return ['An error occurred. Please try again.'];
    }

    // dispatch(setUser(data));
    return null;
  } else if (response.status < 500) {
    const data = await response.json();
    if (data.errors) {
      return data.errors;
    }
  } else {
    return ['An error occurred. Please try again.'];
  }
};

export const addClientInterest = (token, id) => async (dispatch) => {
  const res = await fetch(
    `https://d1i2jq400lm6rf.cloudfront.net/user/toggle-interest?card=${id}`,
    // `http://3.237.199.50:8000/card/document?doc_id=${id}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (res.ok) {
    const data = await res.json();
    dispatch(interest(data?.user_interest));
  }
};

export const putClientInterest = (token, id) => async (dispatch) => {
  const res = await fetch(
    `https://d1i2jq400lm6rf.cloudfront.net/user/toggle-interest?card=${id}`,
    // `http://3.237.199.50:8000/card/document?doc_id=${id}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (res.ok) {
    const data = await res.json();
    dispatch(interest(data?.user_interest));
  }
};

const initialState = {
  // access_token: JSON.parse(localStorage.getItem('_uA')) || null,
};

export default function sessionReducer(state = initialState, action) {
  let newState = {};
  switch (action?.type) {
    case SET_USER:
      newState = {
        ...state,
        user: action?.payload,
      };
      return newState;
    case GET_USER:
      newState = {
        ...state,
        user: action?.payload.data,
      };
      return newState;
    case REMOVE_USER:
      return { user: null };
    case INTEREST:
      let arr = [];
      action.payload?.forEach((el, i) => {
        arr.push(el);
      });
      newState = {
        ...state,
        interest: arr,
      };
      return newState;
    // case REFRESH:
    // Currently no need to use this action because we have tokens in persistUser function, may change in future
    default:
      return state;
  }
}
